import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { NavLink, Route, Switch, useLocation } from 'react-router-dom';
import Chat from './chat/chat';
import Profile from './profile/profile';
import Statistics from './statistics/statistics';
// import SearchIcon from "@material-ui/icons/Search";
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { API_VERSION, api } from '../api/api';
import Logo from '../components/images/logoNew.svg';
import userIcon from '../components/images/user_1.png';
import { setToken } from '../store/main/mainActions';
import EduonCourse from './eduoncourses/eduoncourse';
import EduonCourses from './eduoncourses/eduoncourses';
import NewCourseForm from './eduoncourses/newCourseForm';
import './main.css';
import Test from './test/Test';
import Page1 from './test/page1';

import { MdGroups3 } from 'react-icons/md';
import MyGroups from '../pages/groups/MyGroups';
import TestResult from '../pages/result';
import UpdateGroupStudentsLevel from './updateGroupStudentsLevel';
// import UpdateGroupStudentsLevel from "./updateGroupStudentsLevel";

Modal.setAppElement('#root');

function MainPage() {
	const history = useHistory();
	const cookies = new Cookies();
	const dispatch = useDispatch();
	const speakerData = cookies.get('speaker');
	const [] = useState(false);
	const location = useLocation();
	const user = useSelector(state => state.main.user);
	const token = useSelector(state => state.main.token);
	const [category, setCategory] = useState('');
	const [speakerDate, setSpeakerDate] = useState([]);
	const [loading, setLoading] = useState(true);
	const [speakerCourses, setSpeakerCourses] = useState([]);
	const [isOpen, setisOpen] = useState(false);
	const [courseId, setCourseId] = useState('');
	const [data, setData] = useState({
		name: '',
		category: '',
		image: null,
		turi: '',
		price: '',
		description: '',
	});
	const copyValue = `https://eduon.uz/auth/sign-up/${cookies.get('speaker')?.other?.own_ref_code}`;
	const CheckProfileInfo =
		speakerData?.speaker?.first_name !== null &&
		speakerData?.speaker?.last_name !== null &&
		speakerData?.speaker?.email !== null &&
		speakerData?.other?.both_date !== null &&
		speakerData?.other?.gender !== null &&
		speakerData?.other?.image !== null &&
		speakerData?.other?.kasbi !== null &&
		speakerData?.other?.phone !== null;
	const checkCardInfo = speakerDate?.other?.card_number !== null && speakerDate?.other?.card_name !== null && speakerDate?.other?.card_date !== null;
	const openEditHandler = useCallback(id => {
		setisOpen(true);
		setCourseId(id);
		api.get(`${API_VERSION}courses/${id}/`).then(res =>
			setData({
				name: res?.data.course_obj.course_obj.name,
				category:
					res.data.course_obj.course_obj.category_id === 1
						? 'Biznes'
						: res.data.course_obj.course_obj.category_id === 2
						? "Ta'lim"
						: res.data.course_obj.course_obj.category_id === 3
						? 'IT va Dasturlash'
						: res.data.course_obj.course_obj.category_id === 4
						? "Din va Ma'rifat"
						: 'Boshqalar',
				image: res.data.course_obj.course_obj.image,
				turi: res.data.course_obj.course_obj.turi,
				price: res.data.course_obj.course_obj.price,
				description: res.data.course_obj.course_obj.description,
			})
		);
	}, []);
	const logout = () => {
		dispatch(setToken(null));
		cookies.remove('access_token', { path: '/' });
		cookies.remove('refreshToken', { path: '/' });
		cookies.remove('speaker', { path: '/' });
		cookies.remove('user', { path: '/' });
		window.localStorage.clear();
		history.replace('/login');
	};
	useEffect(() => {
		let mounted = true;
		axios.get('https://backend.eduon.uz/api-web/get-category/').then(res => {
			if (mounted) {
				setCategory(res.data.data);
			}
		});
		return () => {
			mounted = false;
		};
	}, []);
	useEffect(() => {
		let mounted = true;
		setLoading(true);
		api.get(`${API_VERSION}get-speaker-data/`).then(res => {
			if (mounted) {
				setSpeakerCourses(res);
				setLoading(false);
			}
		});
		return () => {
			mounted = false;
		};
	}, []);
	useEffect(() => {
		if (cookies.get('access_token') === undefined || token === undefined || cookies.get('refreshToken') === undefined) {
			logout();
		}
	}, [token, cookies]);
	useEffect(() => {
		api.get('speaker').then(res => {
			setSpeakerDate(res.speaker_data);
			cookies.set('speaker', res.speaker_data, { path: '/' });
			if (res.speaker_data.other.wallet_number === null) {
				api.get('api/uniredpay/create-online-wallet-for-speaker').then(res => {
					return res;
				});
			}
		});
	}, []);
	useEffect(() => {
		window.localStorage.removeItem('page');
	}, []);

	const resetCourseForm = useCallback(() => {
		window.localStorage.removeItem('page');
	}, []);

	return (
		<React.Fragment>
			<div className='navbar-main-menu'>
				<div className='left-main-navbar-menu'>
					<img src={Logo} alt='' />
				</div>

				<div className='middle-main-navbar-menu'>
					<a href='#'>Bosh sahifa</a>
					<a href='#'>Kurslar</a>
					<a href='#'>FAQ</a>
					<a href='#'>Biz haqimizda</a>
				</div>

				<div className='right-main-navbar-menu'>
					<div className='profil-main-navbar'>
						<span className='image-navbar'>
							{speakerDate?.other?.image ? <img src={`https://inpuls-backend.backoffice.uz${speakerDate?.other?.image}`} alt='' /> : <img src={userIcon} />}
						</span>
						<span id='profile-title-main-navbar'>
							{speakerDate?.speaker?.first_name ? speakerDate?.speaker?.first_name : speakerData?.first_name}{' '}
							{speakerDate?.speaker?.last_name ? speakerDate?.speaker?.last_name?.substr(0, 1) : speakerData?.last_name?.substr(0, 1)}
						</span>
						<div className='login_popup'>
							<div className='login_popu__body'>
								<div className='login_popup__item balance-nav' onClick={logout}>
									Chiqish
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='main-component'>
				<div className='main-content-for-teachers'>
					<div className='main-menu-to-sections'>
						<div className='top-main-menu-to-sections'>
							<div className='main-menu-section-links main-menu-section-links1' onClick={resetCourseForm}>
								<NavLink exact to='/main/statistics' activeClassName='selected'>
									<div className='material-ui-icon'>
										<AssessmentRoundedIcon color='disabled' />
									</div>
									<div className='section-links-title'>Statistika</div>
								</NavLink>
							</div>

							<div className='main-menu-section-links main-menu-section-links2' onClick={resetCourseForm}>
								<NavLink activeClassName='selected' to='/main/my-groups'>
									<div className='material-ui-icon'>
										<AccountBalanceWalletRoundedIcon color='disabled' />
									</div>
									<div className='section-links-title'>Mening guruhlarim</div>
								</NavLink>
							</div>

							<div className='main-menu-section-links main-menu-section-links3' onClick={resetCourseForm}>
								<NavLink activeClassName='selected' to='/main/courses/barchasi'>
									<div className='material-ui-icon'>
										<FolderOpenRoundedIcon color='disabled' />
									</div>
									<div className='section-links-title'>Kurslarim</div>
								</NavLink>
							</div>

							<div className='main-menu-section-links main-menu-section-links4' onClick={resetCourseForm}>
								<NavLink activeClassName='selected' to='/main/profile'>
									<div className='material-ui-icon'>
										<PersonRoundedIcon color='disabled' />
									</div>
									<div className='section-links-title'>Profil</div>
								</NavLink>
							</div>

							<div className='main-menu-section-links main-menu-section-links4' onClick={resetCourseForm}>
								<NavLink activeClassName='selected' to='/main/test'>
									<div className='material-ui-icon'>
										<PersonRoundedIcon color='disabled' />
									</div>
									<div className='section-links-title'>Test natijalari</div>
								</NavLink>
							</div>

							<div className='main-menu-section-links main-menu-section-links4' onClick={resetCourseForm}>
								<NavLink activeClassName='selected' to='/main/groups'>
									<div className='material-ui-icon'>
										<MdGroups3 color='#bdbdbd' />
									</div>
									<div className='section-links-title'>Guruhlar</div>
								</NavLink>
							</div>

							<div className='main-menu-section-links main-menu-section-links1'>
								<NavLink className='bottom-main-menu-to-sections' to={'/main/add-to-course'} activeClassName={'selected'}>
									<div className='material-ui-icon'>
										<AddBoxRoundedIcon color='disabled' />
									</div>
									<div className='section-links-title add-course-btn'>Kurs yuklash</div>
								</NavLink>
							</div>
						</div>
					</div>

					<div className='section-content-for-teachers'>
						<Switch>
							<Route exact path='/main/statistics' component={Statistics} />
							<Route path='/main/groups' component={UpdateGroupStudentsLevel} />

							<Route path='/main/my-groups'>
								<MyGroups />
							</Route>

							<Route path='/main/courses/barchasi/:id'>
								<EduonCourse speakerCourses={speakerCourses} loading={loading} setSpeakerCourses={setSpeakerCourses} open={openEditHandler} setCourseId={setCourseId} />
							</Route>
							<Route path='/main/courses/barchasi'>
								<EduonCourses
									speakerCourses={speakerCourses}
									loading={loading}
									setSpeakerCourses={setSpeakerCourses}
									open={openEditHandler}
									setCourseId={setCourseId}
									setisOpen={setisOpen}
									checkProfileInfo={CheckProfileInfo}
								/>
							</Route>
							<Route path='/main/profile'>
								<Profile speakerData={speakerDate} cookies={cookies} checkProfileInfo={CheckProfileInfo} checkCardInfo={checkCardInfo} />
							</Route>
							<Route path='/main/chat' component={Chat} />
							<Route path='/main/add-to-course'>
								<NewCourseForm courseId={courseId} setCourseId={setCourseId} />
							</Route>
							<Route path='/main/course' component={Page1} />
							<Route path='/main/test/result/:id' component={TestResult} />
							<Route path='/main/test' component={Test} />
						</Switch>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default MainPage;

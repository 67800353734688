import React, {useEffect, useState} from "react";
import {api} from "../../api/api";
import {toast} from "react-toastify";


function ProfCard({checkCardInfo}) {

  const [card_number, setCard_number] = useState('');
  const [card_data, setCard_data] = useState('');
  const [card_name, setCard_name] = useState('');
  const [speakerDate, setSpeakerDate] = useState([]);

  const addProduct = (e) =>{
    e.preventDefault();
    const formData = new FormData();
    formData.append('card_number', card_number);
    formData.append('card_date', card_data);
    formData.append('card_name', card_name);

    api.post('update-speaker-card', formData).then(res =>{
      if(res.success === true) {
        toast.success("Karta malumotlari yangilandi", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
  };
  useEffect(() => {
    api.get("speaker").then(res => {
      setSpeakerDate(res.speaker_data);
    })
  }, [setSpeakerDate]);

  useEffect(() => {
    if(!checkCardInfo) {
      // toast.warning("Eslatma !!! Kurs yuklash uchun , karta malumotlari kiritilishi shart!", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
    }
  }, [checkCardInfo]);

  return (
      <React.Fragment>
        <div className="main-content-for-profcard-wrapper" onSubmit={addProduct}>
          <div className="top-div-of-profcard-for-inputs">
            <div className="info-divs-inside-inputs-and-titles info-divs-inside-inputs-and-titles-profcard-div-input">
              <p>Karta egasi</p>
              <input type="text" onChange={(e) => setCard_name(e.target.value)} defaultValue={speakerDate?.other?.card_name}/>
            </div>
            <div className="info-divs-inside-inputs-and-titles info-divs-inside-inputs-and-titles-profcard-div-input">
              <p>Karta nomeri</p>
              <input type="text" defaultValue={speakerDate?.other?.card_number} onChange={(e) => setCard_number(e.target.value)}/>
            </div>
            <div className="info-divs-inside-inputs-and-titles info-divs-inside-inputs-and-titles-profcard-div-input">
              <p>Amal qilish muddati</p>
              <input
                  type="text"
                  name="trip-start"
                  defaultValue={speakerDate?.other?.card_date}
                  onChange={(e) => setCard_data(e.target.value)}
              />
            </div>
          </div>
          <div className="bottom-div-of-profcard-for-button info-divs-inside-inputs-and-titles-profcard-div-button">
            <div className="btn-profcard-middle-main">
              <button onClick={addProduct}>Saqlash</button>
            </div>
          </div>
        </div>
      </React.Fragment>
  );
}

export default ProfCard;

import React from 'react';
import addTextIcon from '../../images/add-text-icon.svg';
import './page7.css';
const Page7 = ({ data, setPage, setEditData }) => {
	return (
		<>
			<div className='question-part'>
				<div className='question-part-conatiner'>
					{data.map((x, index) => (
						<div className='question-part-cell'>
							<h3 className='question-part-cell__title'>
								{index + 1}/{window.localStorage.getItem('totalQuestion')}
							</h3>
							<div className='question-box'>
								<div className='question-box-item'>
									<p>{x.title}</p>
								</div>
								<img
									src={addTextIcon}
									alt=''
									className='add-text-icon'
									onClick={() => {
										setPage(5);
										setEditData(x);
									}}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Page7;

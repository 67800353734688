import React, {useEffect, useState} from 'react';
import NavBar from '../../../Navbar';
import "./photo4.css";
import plus from "../../../../images/plus.svg";
import {api} from "../../../../../api/api";
import leftArrow from "../../../../../images/left-arrow.svg";
import {toast} from "react-toastify";

const Photo4 = ({setPage, quizType}) => {
    const [imageArray, setImageArray] = useState([]);
    const [image, setImage] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [file3, setFile3] = useState();
    const [title, setTitle] = useState("");

    const getImage = (images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            if (res) {
                setImage(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
                setImageArray([...imageArray, `https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`])
            }
        })
    };
    const getImage1 = (images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setImage1(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
            setImageArray([...imageArray, `https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`])
        })
    };
    const getImage2 = (images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setImage2(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
            setImageArray([...imageArray, `https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`])
        })
    };
    const getImage3 = (images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setImage3(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
            setImageArray([...imageArray, `https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`])
        })
    };
    const createAnswers = () => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("is_active", true);
        formData.append("structure", quizType);
        {file && formData.append("img1", file)}
        {file1 && formData.append("img2", file1)}
        {file2 && formData.append("img3", file2)}
        {file3 && formData.append("img4", file3)}
        formData.append("quiz", [window.location.pathname.split("/").at(-1)]);
        api.post("quizzes/question/create/", formData).then(res => {
            if (res) {
                setPage(4);
                toast.success("Savol yuklandi!");
                window.localStorage.setItem("question", res.id);
            }
        }).catch(() => {
            toast.error("Xatolik!")
        })
    };
    return (
        <>
            <img src={leftArrow} alt="" onClick={() => setPage(prev => prev - 1)} height={50} width={50} style={{ marginLeft: "20px" }}/>
            <div className="photo-four">
                <div className="photo-wrapper-four">
                    <div className="photo-items-four">
                        <div className="photo-items-four__imgs">
                            <div className="file-4">
                                {image ?
                                    <img src={image} alt="" className="image-main-full"/> : <>
                                        <div className="question-add">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                accept=".jpg , .png"
                                                onChange={(e) => {
                                                    getImage(e.target.files[0]);
                                                    setFile(e.target.files[0])
                                                }
                                                }
                                            />
                                            <label htmlFor="image">
                                                <img src={plus} alt=""/>
                                            </label>
                                        </div>
                                        <span>Rasmni yuklang</span> </>}
                            </div>
                            <div className="file-4">
                                {image1 ? <img src={image1} alt=""
                                               className="image-main-full"/> : <>
                                    <div className="question-add">
                                        <input
                                            type="file"
                                            name="imagee"
                                            id="imagee"
                                            style={{display: "none"}}
                                            accept=".jpg , .png"
                                            onChange={(e) => {
                                                getImage1(e.target.files[0]);
                                                setFile1(e.target.files[0])
                                            }
                                            }
                                        />
                                        <label htmlFor="imagee">
                                            <img src={plus} alt=""/>
                                        </label>
                                    </div>
                                    <span>Rasmni yuklang</span>
                                </>}
                            </div>
                            <div className="file-4">
                                {image2 ? <img src={image2} alt=""
                                               className="image-main-full"/> : <>
                                    <div className="question-add">
                                        <input
                                            type="file"
                                            name="imageee"
                                            id="imageee"
                                            style={{display: "none"}}
                                            accept=".jpg , .png"
                                            onChange={(e) => {
                                                getImage2(e.target.files[0]);
                                                setFile2(e.target.files[0])
                                            }
                                            }
                                        />
                                        <label htmlFor="imageee">
                                            <img src={plus} alt=""/>
                                        </label>
                                    </div>
                                    <span>Rasmni yuklang</span>
                                </>}
                            </div>
                            <div className="file-4">
                                {image3 ? <img src={image3} alt=""
                                               className="image-main-full"/> : <>
                                    <div className="question-add">
                                        <input
                                            type="file"
                                            name="next"
                                            id="next"
                                            style={{display: "none"}}
                                            accept=".jpg , .png"
                                            onChange={(e) => {
                                                getImage3(e.target.files[0]);
                                                setFile3(e.target.files[0])
                                            }
                                            }
                                        />
                                        <label htmlFor="next">
                                            <img src={plus} alt=""/>
                                        </label>
                                    </div>
                                    <span>Rasmni yuklang</span>
                                </>}
                            </div>
                        </div>
                        <textarea name="" id="" cols="30" onChange={(e) => setTitle(e.target.value)} rows="10"
                                  placeholder="Savolni kiriting"
                                  className="text-area-4"/>
                        <div className="div-btn">
                            <button className="bnt-answers" onClick={createAnswers}>Javoblar qo'shish</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Photo4;

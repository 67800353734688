import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Modal from "@mui/material/Modal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { api, API_VERSION } from "../../api/api";
import Loader from "../ui/Loader";
import "./assets/addToCourse.css";
import arrowBot from "./assets/Arrow-bot.png";
import Arrow from "./assets/Arrow.svg";
import Arrow_left from "./assets/Back_Arrow.svg";
import checkIcon from "./assets/check.png";
import circle1 from "./assets/circle1.png";
import circle2 from "./assets/circle2.png";
import Info from "./assets/Info.svg";
import mfactorLogo from "./assets/Mfaktor.png";
import Photo from "./assets/placeholder.png";
import Plus from "./assets/Plus.png";
import QrCode from "./assets/QrCode";
import whiteLogo from "./assets/whiteLogo.png";
import "./eduoncourses.css";

export default function NewCourseForm({ setCourseId }) {
  const location = useLocation();
  const [id, setId] = useState(location.state?.id);
  const [countInput1, setCountInput1] = useState(2);
  const [category, setCategory] = useState("");
  const [countInput2, setCountInput2] = useState(2);
  const [countInput3, setCountInput3] = useState(2);
  const [active, setActive] = useState(1);
  const [isSertificate, setIsSertificate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadVideo, setLoadVideo] = useState("");
  const [currentSubCategory, setCurrentSubCategory] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [isPictureinfo, setPictureinfo] = useState(false);
  const [isTrellerinfo, setTrellerinfo] = useState(false);
  const [isHashtaginfo, setHashtaginfo] = useState(false);
  const [isNimaOinfo, setNimaOinfo] = useState(false);
  const [isKimlarUinfo, setKimlarUinfo] = useState(false);
  const [isTalabinfo, setTalabinfo] = useState(false);
  const [isNarxinfo, setNarxinfo] = useState(false);
  const [isSertifikatinfo, setSertifikatinfo] = useState(false);
  const [faculty, setFaculty] = useState([]);
  const [facultyId, setFacultyId] = useState("");
  const [data, setData] = useState([]);
  const [video, setVideo] = useState(true);
  const [hasSubCategory, setHasSubCategory] = useState(false);
  const [courseTags, setCourseTags] = useState([]);
  const [coursesId, setCoursesId] = useState("");
  const [coursesSelect, setCoursesSelect] = useState([]);
  const [groupsSelect, setGroupsSelect] = useState([]);
  const [groupsId, setGroupsId] = useState([]);
  const cookies = new Cookies();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    api.get(`${API_VERSION}courses/${id}`).then((res) => {
      res?.data?.course_obj?.course_tags?.forEach((option) => {
        setCourseTags((prev) => [...prev, option.title]);
      });
    });
    setCourseId(location.state?.id);

    if (window.localStorage.getItem("page") !== null) {
      setActive(JSON.parse(window.localStorage.getItem("page")));
    } else if (window.localStorage.getItem("page") === null) {
      window.localStorage.setItem("page", JSON.stringify(1));
    }
  }, []);

  const goBack = useCallback(() => {
    if (active > 1 && active < 5) {
      const page = JSON.parse(window.localStorage.getItem("page"));
      setActive(active - 1);
      window.localStorage.setItem("page", JSON.stringify(page - 1));
      window.scrollTo({ top: 0 });
    }
  }, [active, window.localStorage]);

  useEffect(() => {
    let mounted = true;
    if (id) {
      api.get(`${API_VERSION}courses/${id}`).then((res) => {
        if (mounted) {
          setData(res.data.course_obj);
          res.data.course_obj?.categories?.length > 0 &&
            setHasSubCategory(
              res.data.course_obj?.categories[
                res.data.course_obj?.categories?.length - 1
              ]?.parent !== null
            );
          setVideo(res.data.course_obj.upload_or_youtube !== "Youtube");
        }
      });
    }
    api.get("faculty/").then((res) => {
      if (mounted) {
        setCategory(res);
        setFaculty(res);
      }
    });
    return () => {
      mounted = false;
    };
  }, [id]);

  useEffect(() => {
    setData({
      ...data,
      trailer: {
        id: data?.trailer?.id,
        is_file: video,
        title: video ? data.trailer?.video : data.trailer?.url,
        url: video ? "" : data.trailer?.url,
        video: data?.trailer?.video ? data?.trailer?.video : undefined,
      },
      upload_or_youtube: video ? "Video" : "Youtube",
    });
  }, [video]);
  // useEffect(() => {
  //     category &&
  //     category?.forEach((elem) =>
  //         elem?.university(
  //             (item) =>
  //                 item.name == data?.subCategory && setCurrentSubCategory(item)
  //         )
  //     );
  // }, [data.subCategory]);
  const handleChange = (name, value) => {
    setData({
      ...data,
      subCategory: name === "category" ? null : data?.subCategory,
      [name]: value,
    });
  };

  const createNewCourse = useCallback(() => {
    setLoading(true);
    let newArr = data?.course_tag;
    const courseTags = [];
    for (let i = 0; i < newArr?.length; i++) {
      courseTags.push({ title: newArr[i] });
    }
    const whatYouLearn = [
      data?.what_learn1 && {
        title: data.what_learn1,
      },
      data?.what_learn2 && {
        title: data.what_learn2,
      },
      data?.what_learn3 && {
        title: data.what_learn3,
      },
      data?.what_learn4 && {
        title: data.what_learn4,
      },
    ];
    const coursereq = [
      data?.requirement1 && {
        title: data?.requirement1,
      },
      data?.requirement2 && {
        title: data?.requirement2,
      },
      data?.requirement3 && {
        title: data?.requirement3,
      },
    ];
    const forwho = [
      data?.to_who1 && {
        title: data?.to_who1,
      },
      data?.to_who2 && {
        title: data?.to_who2,
      },
      data?.to_who3 && {
        title: data?.to_who3,
      },
    ];
    const formData = new FormData();
    formData.append("file", data?.image1);
    formData.append("name", "image");
    const formData1 = new FormData();
    formData1.append("file", data?.trailer.title);
    formData1.append("name", "course_trailer");

    api.post("api-web/upload-file/", formData).then((res) => {
      if (res.success === true && video) {
        setIsLoad(true);
        // api
        //   .post("api-web/upload-file/", formData1, {
        //     onUploadProgress: (progressEvent) => {
        //       let percentCompleted = Math.round(
        //         (progressEvent.loaded * 100) / progressEvent.total
        //       );
        //       setLoadVideo(percentCompleted);
        //     },
        //   })
        // .then((res1) => {
        // if (res1.success === true) {
        api
          .post("/api-web/create-course/", {
            author: {
              id: cookies.get("speaker").other.id,
            },
            image: `${res.path.split("/")[6]}/${res.path.split("/")[7]}`,
            name: data?.name,
            description: data?.description,
            language: {
              name: data?.language1,
            },
            // level: data?.level,
            // categories: [
            //     {
            //         // name: data?.subCategory ? data?.subCategory : "Boshqalar",
            //         name: data?.category
            //     },
            // ],
            trailer: {
              title: data?.trailer?.title,
              is_file: true,
              url: "",
              video: `File/${data.trailer.video.name}`,
            },
            course_university: JSON.parse(coursesId),
            course_tags: courseTags,
            has_certificate: isSertificate,
            whatyoulearn: whatYouLearn.filter(Boolean),
            courserequirements: coursereq.filter(Boolean),
            forwhom: forwho.filter(Boolean),
            price: data?.price ? data?.price : 0,
          })
          .then((res) => {
            if (res.id) {
              setLoading(false);
              setIsLoad(false);
              // toast.success('Kurs muvaffaqiyatli yuklandi', {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }
          })
          .then(() => {
            setOpen(true);
            // history.push('/main/courses/barchasi')
          })
          // .then(() => window.location.reload())
          .catch(() => {
            setLoading(false);
            setIsLoad(false);
            toast.error("Kurs yuklashda xatolik", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .finally(() => setLoading(false));
        // }
        // });
      } else if (res.success === true && !video) {
        api
          .post("/api-web/create-course/", {
            author: {
              id: cookies.get("speaker").other.id,
            },
            image: `${res.path.split("/")[6]}/${res.path.split("/")[7]}`,
            name: data?.name,
            description: data?.description,
            language: {
              name: data?.language1,
            },
            // level: data?.level,
            // categories: [
            //     {
            //         // name: data?.subCategory ? data?.subCategory : "Boshqalar",
            //         name: data?.category
            //     },
            // ],
            course_university: JSON.parse(coursesId),
            trailer: {
              title: data?.trailer.title,
              is_file: false,
              url: data.trailer.title
                ? data?.trailer.title.substr(0, 5) === "https"
                  ? data?.trailer.title
                  : `https://${data?.trailer.title}`
                : "",
            },
            course_tags: courseTags,
            has_certificate: isSertificate,
            whatyoulearn: whatYouLearn.filter(Boolean),
            courserequirements: coursereq.filter(Boolean),
            forwhom: forwho.filter(Boolean),
            price: data?.price ? data?.price : 0,
          })
          .then((res) => {
            if (res) {
              setLoading(false);
              // toast.success('Kurs muvaffaqiyatli yuklandi', {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }
          })
          .then(() => {
            setOpen(true);
            // history.push('/main/courses/barchasi')
          })
          // .then(() => window.location.reload())
          .catch(() => {
            setLoading(false);
            toast.error("Kurs yuklashda xatolik", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .finally(() => setLoading(false));
      }
    });

    // .catch(() => {
    //     setLoading(false);
    //     toast.error("Kurs yuklashda xatolik", {
    //         position: toast.POSITION.TOP_RIGHT,
    //     });
    // });
  }, [data, video, isSertificate, cookies]);

  const editCourse = useCallback(() => {
    let newArr = data?.course_tag;
    const courseTags = [];
    for (let i = 0; i < newArr?.length; i++) {
      courseTags.push({ title: newArr[i] });
    }
    const whatYouLearn = [
      data?.what_learn1 && {
        title: data.what_learn1,
      },
      data?.what_learn2 && {
        title: data.what_learn2,
      },
      data?.what_learn3 && {
        title: data.what_learn3,
      },
      data?.what_learn4 && {
        title: data.what_learn4,
      },
    ];
    const coursereq = [
      data?.requirement1 && {
        title: data?.requirement1,
      },
      data?.requirement2 && {
        title: data?.requirement2,
      },
      data?.requirement3 && {
        title: data?.requirement3,
      },
    ];
    const forwho = [
      data?.to_who1 && {
        title: data?.to_who1,
      },
      data?.to_who2 && {
        title: data?.to_who2,
      },
      data?.to_who3 && {
        title: data?.to_who3,
      },
    ];
    const formData = new FormData();
    formData.append("file", data?.image1);
    formData.append("name", "image");
    const formData1 = new FormData();
    formData1.append("file", data?.trailer.title);
    formData1.append("name", "course_trailer");

    if (!!data?.image1) {
      setLoading(true);
      api.post("api-web/upload-file/", formData).then((res) => {
        if (res.success === true && video && data?.trailer.video) {
          setIsLoad(true);
          // api
          //   .post("api-web/upload-file/", formData1, {
          //     onUploadProgress: (progressEvent) => {
          //       let percentCompleted = Math.round(
          //         (progressEvent.loaded * 100) / progressEvent.total
          //       );
          //       setLoadVideo(percentCompleted);
          //     },
          //   })
          // .then((res1) => {
          // if (res1.success === true) {
          api
            .patch(`api-web/update-course/${id}`, {
              author: {
                id: cookies.get("speaker").other.id,
              },
              image: `${res.path.split("/")[6]}/${res.path.split("/")[7]}`,
              name: data?.name,
              description: data?.description,
              language: data?.language1
                ? {
                    name: data?.language1,
                  }
                : data?.language,
              level: data?.level,
              categories:
                data?.categories?.length > 0
                  ? [
                      {
                        id:
                          data?.category && !hasSubCategory
                            ? category.filter(
                                (elem) => elem.id == data?.category
                              )[0]?.id
                            : data?.subCategory && hasSubCategory
                            ? currentSubCategory?.id
                            : // : data?.category && !hasSubCategory?data?.category
                              data?.categories[data?.categories.length - 1]?.id,
                        name:
                          data?.category && !hasSubCategory
                            ? category.filter(
                                (elem) => elem.id == data?.category
                              )[0].name
                            : data?.subCategory
                            ? data?.subCategory
                            : data?.categories?.length > 0
                            ? data?.categories[data?.categories.length - 1]
                                ?.name
                            : "Boshqalar",
                        parent:
                          data?.category && hasSubCategory
                            ? data?.category
                            : data?.category
                            ? undefined
                            : data?.categories[data?.categories.length - 1]
                                ?.parent,
                      },
                    ]
                  : [
                      {
                        name: data?.subCategory
                          ? data?.subCategory
                          : "Boshqalar",
                      },
                    ],
              trailer: {
                id: data?.trailer?.id,
                title: data?.trailer?.title,
                is_file: true,
                url: "",
                video: `File/${
                  data?.trailer?.video.name
                    ? data?.trailer?.video.name
                    : data?.trailer?.title
                }`,
              },
              course_tags: courseTags ? courseTags : data?.course_tags,
              has_certificate: false,
              whatyoulearn:
                data?.whatyoulearns?.length > 0
                  ? [
                      {
                        id:
                          data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[0]?.id,
                        title: data?.what_learn1
                          ? data?.what_learn1
                          : data?.whatyoulearns?.length > 0 &&
                            data?.whatyoulearns[0]?.title,
                      },
                      {
                        id:
                          data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[1]?.id,
                        title: data?.what_learn2
                          ? data?.what_learn2
                          : data?.whatyoulearns?.length > 0 &&
                            data?.whatyoulearns[1]?.title,
                      },
                    ]
                  : whatYouLearn.filter(Boolean),
              courserequirements:
                data?.requirementscourse?.length > 0
                  ? [
                      {
                        id:
                          data?.requirementscourse?.length > 0 &&
                          data?.requirementscourse[0]?.id,
                        title: data?.requirement1
                          ? data?.requirement1
                          : data?.requirementscourse?.length > 0 &&
                            data?.requirementscourse[0]?.title,
                      },
                    ]
                  : coursereq.filter(Boolean),
              forwhom:
                data?.forwhoms?.length > 0
                  ? [
                      {
                        id: data?.forwhoms?.length > 0 && data?.forwhoms[0]?.id,
                        title: data?.to_who1
                          ? data?.to_who1
                          : data?.forwhoms?.length > 0 &&
                            data?.forwhoms[0]?.title,
                      },
                    ]
                  : forwho.filter(Boolean),
              price: data?.price,
            })
            .then((res) => {
              if (res.id) {
                setIsLoad(false);
                setOpen(true);
                // history.push('/main/courses/barchasi')
                setCourseId("");
                setId("");
                localStorage.setItem("editVideo", null);
              }
            })
            // .then(() => window.location.reload())
            .catch(() => {
              // setIsLoad(false);
              // toast.error("Kurs tahrirlashda xatolik", {
              //     position: toast.POSITION.TOP_RIGHT,
              // });
              setIsLoad(false);
              setOpen(true);
              // history.push('/main/courses/barchasi')
              setCourseId("");
              setId("");
            });
          // }
          // });
        } else if (res.success === true && video && !data?.trailer.video) {
          api
            .patch(`api-web/update-course/${id}`, {
              author: {
                id: cookies.get("speaker").other.id,
              },
              image: `${res.path.split("/")[6]}/${res.path.split("/")[7]}`,
              name: data?.name,
              description: data?.description,
              language: data?.language1
                ? {
                    name: data?.language1,
                  }
                : data?.language,

              level: data?.level,
              categories:
                data?.categories?.length > 0
                  ? [
                      {
                        id:
                          data?.category && !hasSubCategory
                            ? category.filter(
                                (elem) => elem.id == data?.category
                              )[0]?.id
                            : data?.subCategory && hasSubCategory
                            ? currentSubCategory?.id
                            : // : data?.category && !hasSubCategory?data?.category
                              data?.categories[data?.categories.length - 1]?.id,
                        name:
                          data?.category && !hasSubCategory
                            ? category.filter(
                                (elem) => elem.id == data?.category
                              )[0].name
                            : data?.subCategory
                            ? data?.subCategory
                            : data?.categories?.length > 0
                            ? data?.categories[data?.categories.length - 1]
                                ?.name
                            : "Boshqalar",
                        parent:
                          data?.category && hasSubCategory
                            ? data?.category
                            : data?.category
                            ? undefined
                            : data?.categories[data?.categories.length - 1]
                                ?.parent,
                      },
                    ]
                  : [
                      {
                        name: data?.subCategory
                          ? data?.subCategory
                          : "Boshqalar",
                      },
                    ],
              trailer: data?.trailer,
              course_tags: courseTags ? courseTags : data?.course_tags,
              has_certificate: false,
              whatyoulearn:
                data?.whatyoulearns?.length > 0
                  ? [
                      {
                        id:
                          data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[0]?.id,
                        title: data?.what_learn1
                          ? data?.what_learn1
                          : data?.whatyoulearns?.length > 0 &&
                            data?.whatyoulearns[0]?.title,
                      },
                      {
                        id:
                          data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[1]?.id,
                        title: data?.what_learn2
                          ? data?.what_learn2
                          : data?.whatyoulearns?.length > 0 &&
                            data?.whatyoulearns[1]?.title,
                      },
                    ]
                  : whatYouLearn.filter(Boolean),
              courserequirements:
                data?.requirementscourse?.length > 0
                  ? [
                      {
                        id:
                          data?.requirementscourse?.length > 0 &&
                          data?.requirementscourse[0]?.id,
                        title: data?.requirement1
                          ? data?.requirement1
                          : data?.requirementscourse?.length > 0 &&
                            data?.requirementscourse[0]?.title,
                      },
                    ]
                  : coursereq.filter(Boolean),
              forwhom:
                data?.forwhoms?.length > 0
                  ? [
                      {
                        id: data?.forwhoms?.length > 0 && data?.forwhoms[0]?.id,
                        title: data?.to_who1
                          ? data?.to_who1
                          : data?.forwhoms?.length > 0 &&
                            data?.forwhoms[0]?.title,
                      },
                    ]
                  : forwho.filter(Boolean),
              price: data?.price,
            })
            .then((res) => {
              if (res.id) {
                setOpen(true);
                // history.replace('/main/courses/barchasi');
                setCourseId("");
                setId("");
                setLoading(false);
                localStorage.setItem("editVideo", null);
              }
            })
            // .then(() => window.location.reload())
            .catch(() => {
              // toast.error("Kurs tahrirlashda xatolik", {
              //     position: toast.POSITION.TOP_RIGHT,
              // });
              // setLoading(false);
              setIsLoad(false);
              setOpen(true);
              // history.push('/main/courses/barchasi')
              setCourseId("");
              setId("");
            });
        } else if (res.success === true && !video && !data?.trailer.video) {
          api
            .patch(`api-web/update-course/${id}`, {
              author: {
                id: cookies.get("speaker").other.id,
              },
              image: `${res.path.split("/")[6]}/${res.path.split("/")[7]}`,
              name: data?.name,
              description: data?.description,
              language: data?.language1
                ? {
                    name: data?.language1,
                  }
                : data?.language,
              level: data?.level,
              categories:
                data?.categories?.length > 0
                  ? [
                      {
                        id:
                          data?.category && !hasSubCategory
                            ? category.filter(
                                (elem) => elem.id == data?.category
                              )[0]?.id
                            : data?.subCategory && hasSubCategory
                            ? currentSubCategory?.id
                            : // : data?.category && !hasSubCategory?data?.category
                              data?.categories[data?.categories.length - 1]?.id,
                        name:
                          data?.category && !hasSubCategory
                            ? category.filter(
                                (elem) => elem.id == data?.category
                              )[0].name
                            : data?.subCategory
                            ? data?.subCategory
                            : data?.categories?.length > 0
                            ? data?.categories[data?.categories.length - 1]
                                ?.name
                            : "Boshqalar",
                        parent:
                          data?.category && hasSubCategory
                            ? data?.category
                            : data?.category
                            ? undefined
                            : data?.categories[data?.categories.length - 1]
                                ?.parent,
                      },
                    ]
                  : [
                      {
                        name: data?.subCategory
                          ? data?.subCategory
                          : "Boshqalar",
                      },
                    ],
              trailer: data?.trailer.url
                ? {
                    id: data?.trailer?.id,
                    title: data?.trailer.title,
                    is_file: false,
                    url: data?.trailer.url
                      ? data?.trailer.url.substr(0, 5) === "https"
                        ? data?.trailer.url
                        : `https://${data?.trailer.url}`
                      : "",
                  }
                : data?.trailer,
              course_tags: courseTags ? courseTags : data?.course_tags,
              has_certificate: false,
              whatyoulearn:
                data?.whatyoulearns?.length > 0
                  ? [
                      {
                        id:
                          data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[0]?.id,
                        title: data?.what_learn1
                          ? data?.what_learn1
                          : data?.whatyoulearns?.length > 0 &&
                            data?.whatyoulearns[0]?.title,
                      },
                      {
                        id:
                          data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[1]?.id,
                        title: data?.what_learn2
                          ? data?.what_learn2
                          : data?.whatyoulearns?.length > 0 &&
                            data?.whatyoulearns[1]?.title,
                      },
                    ]
                  : whatYouLearn.filter(Boolean),
              courserequirements:
                data?.requirementscourse?.length > 0
                  ? [
                      {
                        id:
                          data?.requirementscourse?.length > 0 &&
                          data?.requirementscourse[0]?.id,
                        title: data?.requirement1
                          ? data?.requirement1
                          : data?.requirementscourse?.length > 0 &&
                            data?.requirementscourse[0]?.title,
                      },
                    ]
                  : coursereq.filter(Boolean),
              forwhom:
                data?.forwhoms?.length > 0
                  ? [
                      {
                        id: data?.forwhoms?.length > 0 && data?.forwhoms[0]?.id,
                        title: data?.to_who1
                          ? data?.to_who1
                          : data?.forwhoms?.length > 0 &&
                            data?.forwhoms[0]?.title,
                      },
                    ]
                  : forwho.filter(Boolean),
              price: data?.price,
            })
            .then((res) => {
              if (res.id) {
                setOpen(true);
                // history.replace('/main/courses/barchasi');
                setCourseId("");
                setId("");
                setLoading(false);
                localStorage.setItem("editVideo", null);
              }
            })
            // .then(() => window.location.reload())
            .catch(() => {
              // toast.error("Kurs tahrirlashda xatolik", {
              //     position: toast.POSITION.TOP_RIGHT,
              // });
              // setLoading(false);
              setIsLoad(false);
              setOpen(true);
              // history.push('/main/courses/barchasi')
              setCourseId("");
              setId("");
            });
        }
      });
    } else if (!data?.image1) {
      setLoading(true);
      if (video && data?.trailer?.video.length > 0) {
        setIsLoad(true);
        // api
        //   .post("api-web/upload-file/", formData1, {
        //     onUploadProgress: (progressEvent) => {
        //       let percentCompleted = Math.round(
        //         (progressEvent.loaded * 100) / progressEvent.total
        //       );
        //       setLoadVideo(percentCompleted);
        //     },
        //   })
        // .then((res1) => {
        // if (res1.success === true) {
        api
          .patch(`api-web/update-course/${id}`, {
            author: {
              id: cookies.get("speaker").other.id,
            },
            image: `${data?.image.split("/")[2]}/${data?.image.split("/")[3]}`,
            name: data?.name,
            description: data?.description,
            language: data?.language1
              ? {
                  name: data?.language1,
                }
              : data?.language,
            level: data?.level,
            categories:
              data?.categories?.length > 0
                ? [
                    {
                      id:
                        data?.category && !hasSubCategory
                          ? category.filter(
                              (elem) => elem.id == data?.category
                            )[0]?.id
                          : data?.subCategory && hasSubCategory
                          ? currentSubCategory?.id
                          : // : data?.category && !hasSubCategory?data?.category
                            data?.categories[data?.categories.length - 1]?.id,
                      name:
                        data?.category && !hasSubCategory
                          ? category.filter(
                              (elem) => elem.id == data?.category
                            )[0].name
                          : data?.subCategory
                          ? data?.subCategory
                          : data?.categories?.length > 0
                          ? data?.categories[data?.categories.length - 1]?.name
                          : "Boshqalar",
                      parent:
                        data?.category && hasSubCategory
                          ? data?.category
                          : data?.category
                          ? undefined
                          : data?.categories[data?.categories.length - 1]
                              ?.parent,
                    },
                  ]
                : [
                    {
                      name: data?.subCategory ? data?.subCategory : "Boshqalar",
                    },
                  ],
            trailer: {
              id: data?.trailer?.id,
              title: data?.trailer?.title,
              is_file: true,
              url: "",
              video: `File/${data.trailer.video.name}`,
            },
            course_tags: courseTags ? courseTags : data?.course_tags,
            has_certificate: false,
            whatyoulearn:
              data?.whatyoulearns?.length > 0
                ? [
                    {
                      id:
                        data?.whatyoulearns?.length > 0 &&
                        data?.whatyoulearns[0]?.id,
                      title: data?.what_learn1
                        ? data?.what_learn1
                        : data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[0]?.title,
                    },
                    {
                      id:
                        data?.whatyoulearns?.length > 0 &&
                        data?.whatyoulearns[1]?.id,
                      title: data?.what_learn2
                        ? data?.what_learn2
                        : data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[1]?.title,
                    },
                  ]
                : whatYouLearn.filter(Boolean),
            courserequirements:
              data?.requirementscourse?.length > 0
                ? [
                    {
                      id:
                        data?.requirementscourse?.length > 0 &&
                        data?.requirementscourse[0]?.id,
                      title: data?.requirement1
                        ? data?.requirement1
                        : data?.requirementscourse?.length > 0 &&
                          data?.requirementscourse[0]?.title,
                    },
                  ]
                : coursereq.filter(Boolean),
            forwhom:
              data?.forwhoms?.length > 0
                ? [
                    {
                      id: data?.forwhoms?.length > 0 && data?.forwhoms[0]?.id,
                      title: data?.to_who1
                        ? data?.to_who1
                        : data?.forwhoms?.length > 0 &&
                          data?.forwhoms[0]?.title,
                    },
                  ]
                : forwho.filter(Boolean),
            price: data?.price,
          })
          .then((res) => {
            if (res.id) {
              setIsLoad(false);
              setOpen(true);
              // history.replace('/main/courses/barchasi');
              setCourseId("");
              setId("");
              setLoading(false);
              localStorage.setItem("editVideo", null);
            }
          })
          // .then(() => window.location.reload())
          .catch(() => {
            // toast.error("Kurs tahrirlashda xatolik", {
            //     position: toast.POSITION.TOP_RIGHT,
            // });
            // setLoading(false);
            setIsLoad(false);
            setOpen(true);
            // history.push('/main/courses/barchasi')
            setCourseId("");
            setId("");
          });
        // }
        // });
      } else if (!video && data?.trailer?.video?.length > 0) {
        api
          .patch(`api-web/update-course/${id}`, {
            author: {
              id: cookies.get("speaker").other.id,
            },
            image: `${data?.image.split("/")[2]}/${data?.image.split("/")[3]}`,
            name: data?.name,
            description: data?.description,
            language: data?.language1
              ? {
                  name: data?.language1,
                }
              : data?.language,
            level: data?.level,
            categories:
              data?.categories?.length > 0
                ? [
                    {
                      id:
                        data?.category && !hasSubCategory
                          ? category.filter(
                              (elem) => elem.id == data?.category
                            )[0]?.id
                          : data?.subCategory && hasSubCategory
                          ? currentSubCategory?.id
                          : // : data?.category && !hasSubCategory?data?.category
                            data?.categories[data?.categories.length - 1]?.id,
                      name:
                        data?.category && !hasSubCategory
                          ? category.filter(
                              (elem) => elem.id == data?.category
                            )[0].name
                          : data?.subCategory
                          ? data?.subCategory
                          : data?.categories?.length > 0
                          ? data?.categories[data?.categories.length - 1]?.name
                          : "Boshqalar",
                      parent:
                        data?.category && hasSubCategory
                          ? data?.category
                          : data?.category
                          ? undefined
                          : data?.categories[data?.categories.length - 1]
                              ?.parent,
                    },
                  ]
                : [
                    {
                      name: data?.subCategory ? data?.subCategory : "Boshqalar",
                    },
                  ],
            trailer: data?.trailer,
            course_tags: courseTags ? courseTags : data?.course_tags,
            has_certificate: false,
            whatyoulearn:
              data?.whatyoulearns?.length > 0
                ? [
                    {
                      id:
                        data?.whatyoulearns?.length > 0 &&
                        data?.whatyoulearns[0]?.id,
                      title: data?.what_learn1
                        ? data?.what_learn1
                        : data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[0]?.title,
                    },
                    {
                      id:
                        data?.whatyoulearns?.length > 0 &&
                        data?.whatyoulearns[1]?.id,
                      title: data?.what_learn2
                        ? data?.what_learn2
                        : data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[1]?.title,
                    },
                  ]
                : whatYouLearn.filter(Boolean),
            courserequirements:
              data?.requirementscourse?.length > 0
                ? [
                    {
                      id:
                        data?.requirementscourse?.length > 0 &&
                        data?.requirementscourse[0]?.id,
                      title: data?.requirement1
                        ? data?.requirement1
                        : data?.requirementscourse?.length > 0 &&
                          data?.requirementscourse[0]?.title,
                    },
                  ]
                : coursereq.filter(Boolean),
            forwhom:
              data?.forwhoms?.length > 0
                ? [
                    {
                      id: data?.forwhoms?.length > 0 && data?.forwhoms[0]?.id,
                      title: data?.to_who1
                        ? data?.to_who1
                        : data?.forwhoms?.length > 0 &&
                          data?.forwhoms[0]?.title,
                    },
                  ]
                : forwho.filter(Boolean),
            price: data?.price,
          })
          .then((res) => {
            if (res.id) {
              setOpen(true);
              // history.replace('/main/courses/barchasi');
              setCourseId("");
              setId("");
              setLoading(false);
              localStorage.setItem("editVideo", null);
            }
          })
          // .then(() => window.location.reload())
          .catch((err) => {
            // toast.error("Kurs tahrirlashda xatolik", {
            //     position: toast.POSITION.TOP_RIGHT,
            // });
            // setLoading(false);
            setIsLoad(false);
            setOpen(true);
            // history.push('/main/courses/barchasi')
            setCourseId("");
            setId("");
          });
      } else if (!video && !data?.trailer?.video?.length > 0) {
        api
          .patch(`api-web/update-course/${id}`, {
            author: {
              id: cookies.get("speaker").other.id,
            },
            image: `${data?.image.split("/")[2]}/${data?.image.split("/")[3]}`,
            name: data?.name,
            description: data?.description,
            language: data?.language1
              ? {
                  name: data?.language1,
                }
              : data?.language,
            level: data?.level,
            categories:
              data?.categories?.length > 0
                ? [
                    {
                      id:
                        data?.category && !hasSubCategory
                          ? category.filter(
                              (elem) => elem.id == data?.category
                            )[0]?.id
                          : data?.subCategory && hasSubCategory
                          ? currentSubCategory?.id?.id
                          : // : data?.category && !hasSubCategory?data?.category
                            data?.categories[data?.categories.length - 1]?.id,
                      name:
                        data?.category && !hasSubCategory
                          ? category.filter(
                              (elem) => elem.id == data?.category
                            )[0].name
                          : data?.subCategory
                          ? data?.subCategory
                          : data?.categories?.length > 0
                          ? data?.categories[data?.categories.length - 1]?.name
                          : "Boshqalar",
                      parent:
                        data?.category && hasSubCategory
                          ? data?.category
                          : data?.category
                          ? undefined
                          : data?.categories[data?.categories.length - 1]
                              ?.parent,
                    },
                  ]
                : [
                    {
                      name: data?.subCategory ? data?.subCategory : "Boshqalar",
                    },
                  ],
            trailer: data?.trailer.url
              ? {
                  id: data?.trailer?.id,
                  title: data?.trailer.url,
                  is_file: false,
                  url: data?.trailer.url
                    ? data?.trailer.url.substr(0, 5) === "https"
                      ? data?.trailer.url
                      : `https://${data?.trailer.url}`
                    : "",
                }
              : data?.trailer,
            course_tags: courseTags ? courseTags : data?.course_tags,
            has_certificate: false,
            whatyoulearn:
              data?.whatyoulearns?.length > 0
                ? [
                    {
                      id:
                        data?.whatyoulearns?.length > 0 &&
                        data?.whatyoulearns[0]?.id,
                      title: data?.what_learn1
                        ? data?.what_learn1
                        : data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[0]?.title,
                    },
                    {
                      id:
                        data?.whatyoulearns?.length > 0 &&
                        data?.whatyoulearns[1]?.id,
                      title: data?.what_learn2
                        ? data?.what_learn2
                        : data?.whatyoulearns?.length > 0 &&
                          data?.whatyoulearns[1]?.title,
                    },
                  ]
                : whatYouLearn.filter(Boolean),
            courserequirements:
              data?.requirementscourse?.length > 0
                ? [
                    {
                      id:
                        data?.requirementscourse?.length > 0 &&
                        data?.requirementscourse[0]?.id,
                      title: data?.requirement1
                        ? data?.requirement1
                        : data?.requirementscourse?.length > 0 &&
                          data?.requirementscourse[0]?.title,
                    },
                  ]
                : coursereq.filter(Boolean),
            forwhom:
              data?.forwhoms?.length > 0
                ? [
                    {
                      id: data?.forwhoms?.length > 0 && data?.forwhoms[0]?.id,
                      title: data?.to_who1
                        ? data?.to_who1
                        : data?.forwhoms?.length > 0 &&
                          data?.forwhoms[0]?.title,
                    },
                  ]
                : forwho.filter(Boolean),
            price: data?.price,
          })
          .then((res) => {
            if (res.id) {
              setOpen(true);
              // history.replace("/main/courses/barchasi");
              setCourseId("");
              setId("");
              setLoading(false);
              localStorage.setItem("editVideo", null);
            }
          })
          // .then(() => window.location.reload())
          .catch((err) => {
            // toast.error("Kurs tahrirlashda xatolik", {
            //     position: toast.POSITION.TOP_RIGHT,
            // });
            // setLoading(false);
            setIsLoad(false);
            setOpen(true);
            // history.push('/main/courses/barchasi')
            setCourseId("");
            setId("");
          });
      } else {
        setLoading(false);
      }
    }
  }, [id, cookies, data, isSertificate, video]);

  const subCategory = useMemo(
    () =>
      category &&
      category?.filter((x) =>
        data?.category
          ? x.id == data?.category
          : data?.categories?.length &&
            x.id == data?.categories[data?.categories.length - 1]?.parent
      ),
    [category, data]
  );

  const goNext = useCallback(() => {
    if (active > 0 && active < 4) {
      const page = JSON.parse(window.localStorage.getItem("page"));
      setActive(active + 1);
      window.localStorage.setItem("page", JSON.stringify(page + 1));
      window.scrollTo({ top: 0 });
    }
  }, [active, window.localStorage]);

  const checkSubCategory = (id) => {
    // handleChange("category", JSON.parse(id));
    category.forEach((item) => {
      if (item?.id == id) {
        setHasSubCategory(!!item?.children?.length);
      }
    });
  };
  const [editVideo, setEditVideo] = useState(localStorage.getItem("editVideo"));

  useEffect(() => {
    setEditVideo(localStorage.getItem("editVideo"));
  }, []);

  const validateGoNext = useCallback(() => {
    const page1 =
      data?.name &&
      (data?.language?.id || data?.language1) &&
      data?.course_university &&
      (data?.image || data?.image1) &&
      (data?.course_tags || data?.course_tag);
    if (active === 1) {
      if (page1) {
        goNext();
      } else {
        toast.error("Barcha maydonlar toldirilish shart", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    if (active === 2) {
      if (data?.what_learn1 && data?.to_who1 && data?.requirement1) {
        goNext();
      } else if (id) {
        goNext();
      } else {
        toast.error("Barcha maydonlar toldirilish shart", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }, [data, toast, id]);

  

  useEffect(() => {
    if (facultyId.length > 0) {
      api.get(`university/course/?q=${facultyId}`).then((res) => {
        setCoursesSelect(res);
      });
    }
  }, [facultyId]);

  useEffect(() => {
    if (coursesId) {
      api.get(`group/?q=${coursesId}`).then((res) => {
        setGroupsSelect(res);
      });
    }
  }, [coursesId]);

  return (
    <>
      <div className="add-course-container">
        <div className="course-header-links">
          <div
            className={`header-links ${active > 0 && "active-header-steps"}`}
          >
            <div className="number-links">
              <span>1</span>
            </div>
            <div className="name-link">Kurs malumoti</div>
            <input
              type="checkbox"
              checked={true}
              className={`${active < 2 && "opacity-none"}`}
            />
          </div>
          <div className={`success-icon ${active > 1 && "active-circle"}`} />
          <div
            className={`header-links ${active > 1 && "active-header-steps"}`}
          >
            <div className="number-links">
              <span>2</span>
            </div>
            <div className="name-link">Kurs strukturasi</div>
            <input
              type="checkbox"
              checked={true}
              className={`${active < 3 && "opacity-none"}`}
            />
          </div>
          <div className={`success-icon ${active > 2 && "active-circle"}`} />
          <div
            className={`header-links ${active > 2 && "active-header-steps"}`}
          >
            <div className="number-links">
              <span>3</span>
            </div>
            <div className="name-link">Narxlash va Sertifikat</div>
            <input
              type="checkbox"
              checked={true}
              className={`${active < 4 && "opacity-none"}`}
            />
          </div>
          <div className={`success-icon ${active > 3 && "active-circle"}`} />
          <div
            className={`header-links ${active > 3 && "active-header-steps"}`}
          >
            <div className="number-links">
              <span>4</span>
            </div>
            <div className="name-link">Kurs targ'iboti</div>
            <input
              type="checkbox"
              checked={true}
              className={`${active < 5 && "opacity-none"}`}
            />
          </div>
        </div>

        {/* first*/}
        {active <= 1 && (
          <div className="add-course-content">
            <p className="course-main-title">Kurs malumotlari</p>
            <p className="course-main-title2">
              Bu sahifada siz kursni ma’lumotlarini to’ldirishingiz kerak{" "}
            </p>
            <div className="firstBlock">
              <div className="first">
                <div className="name-course-block">
                  <p>Kurs nomi</p>
                  <div>
                    <input
                      placeholder="Kurs nomini kiriting"
                      value={data?.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                      maxLength={55}
                      required
                    />
                    <p>{data?.name?.length ? 55 - data?.name?.length : 55}</p>
                  </div>
                </div>
                <div className="name-course-block">
                  <p>Kurs ta'rifi</p>
                  <div>
                    {" "}
                    <textarea
                      placeholder="Misol: To'liq ma'lumotlar fanlari bo'yicha trening: matematika, statistika, Python, Pythonda ilg'or statistika, mashina va chuqur o'rganish"
                      className="input-desc"
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                      maxLength={120}
                      required
                      value={data?.description}
                    />
                    <p>
                      {data?.description?.length
                        ? 120 - data?.description?.length
                        : 120}
                    </p>
                  </div>
                </div>
              </div>
              <div className="block-next-selects">
                <div className="selects-block">
                  <p>Kurs tili</p>
                  <select
                    name=""
                    id=""
                    defaultValue={data?.language1}
                    onChange={(e) => handleChange("language1", e.target.value)}
                  >
                    <option selected hidden>
                      tilni tanlang
                    </option>
                    <option
                      value="Inglizcha"
                      selected={data?.language?.name === "Inglizcha"}
                    >
                      Ingliz tili
                    </option>
                    <option
                      value="Ruscha"
                      selected={data?.language?.name === "Ruscha"}
                    >
                      Russ tili
                    </option>
                    <option
                      value="O'zbekcha"
                      selected={data?.language?.name === "O'zbekcha"}
                    >
                      Uzbek tili
                    </option>
                  </select>
                </div>
                <div className="selects-block">
                  <p>Fakultet</p>
                  <select
                    name=""
                    id=""
                    defaultValue={data?.course_university}
                    onChange={(e) => {
                      setFacultyId(e.target.value);
                      handleChange(
                        "course_university",
                        JSON.parse(e.target.value)
                      );
                    }}
                  >
                    <option hidden selected>
                      fakultetni tanlang
                    </option>
                    {faculty &&
                      faculty?.map((x, idx) => (
                        <option
                          key={idx}
                          value={x.id}
                          selected={data?.course_university === x.id}
                        >
                          {x.name}
                        </option>
                      ))}
                  </select>
                </div>
                {facultyId && coursesSelect && (
                  <div className="selects-block">
                    <p>Kurs</p>
                    <select
                      name=""
                      id=""
                      defaultValue={data?.category}
                      onChange={(e) => {
                        // checkSubCategory(e.target.value);
                        setCoursesId(e.target.value);
                      }}
                    >
                      <option hidden selected>
                        Kursni tanlang
                      </option>
                      {coursesSelect &&
                        coursesSelect?.map((x, idx) => (
                          <option
                            key={idx}
                            value={x.id}
                            // selected={
                            //     data?.categories?.length > 0 &&
                            //     data?.categories[data?.categories.length - 1]
                            //         ?.parent !== null
                            //         ? data?.categories[data?.categories.length - 1]
                            //         ?.parent === x.id
                            //         : data?.categories?.length > 0 &&
                            //         data?.categories[data?.categories.length - 1]
                            //             ?.id === x.id
                            // }
                          >
                            {x.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                {facultyId && coursesSelect && groupsSelect.length > 0 && (
                  <div className="selects-block">
                    <p>Guruh</p>
                    <select
                      name=""
                      id=""
                      defaultValue={data?.category}
                      onChange={(e) => {
                        // checkSubCategory(e.target.value);
                        setGroupsId(e.target.value);
                      }}
                    >
                      <option hidden selected>
                        Guruhni tanlang
                      </option>
                      {groupsSelect &&
                        groupsSelect?.map((x, idx) => (
                          <option
                            key={idx}
                            value={x.id}
                            // selected={
                            //     data?.categories?.length > 0 &&
                            //     data?.categories[data?.categories.length - 1]
                            //         ?.parent !== null
                            //         ? data?.categories[data?.categories.length - 1]
                            //         ?.parent === x.id
                            //         : data?.categories?.length > 0 &&
                            //         data?.categories[data?.categories.length - 1]
                            //             ?.id === x.id
                            // }
                          >
                            {x.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                {/*{(hasSubCategory && !!data?.category?.length) ||*/}
                {/*(hasSubCategory && !!data?.categories?.length) ? (*/}
                {/*    <div className="selects-block">*/}
                {/*        <p>Kurs sub-kategoriyasi</p>*/}
                {/*        <select*/}
                {/*            name=""*/}
                {/*            id=""*/}
                {/*            defaultValue={data?.subCategory}*/}
                {/*            onChange={(e) =>*/}
                {/*                handleChange("subCategory", e.target.value)*/}
                {/*            }*/}
                {/*        >*/}
                {/*            {(data.category || data?.categories?.length) &&*/}
                {/*            subCategory ? (*/}
                {/*                subCategory?.map((x) => (*/}
                {/*                    <Options data={x} data1={data}/>*/}
                {/*                ))*/}
                {/*            ) : !data?.categories?.length ? (*/}
                {/*                <option selected hidden disabled>*/}
                {/*                    Avval kategoriyani tanlang*/}
                {/*                </option>*/}
                {/*            ) : (*/}
                {/*                <option selected hidden disabled>*/}
                {/*                    Avval kategoriyani tanlang*/}
                {/*                </option>*/}
                {/*            )}*/}
                {/*            /!* {(data.category || data?.categories?.length) && subCategory && subCategory?.map((x) => (*/}
                {/*      <Options data={x} data1={data}/>*/}
                {/*  ))} *!/*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*) : null}*/}
              </div>
            </div>
            <div className="video-photo-block">
              <div className="photo-block">
                <div className="image-block">
                  <p>
                    Kurs rasmi{" "}
                    <span
                      onClick={() => setPictureinfo(!isPictureinfo)}
                      className="infoAbout"
                    >
                      <img src={Info} alt="#" />
                    </span>
                  </p>
                  <img src={Photo} alt="$" />
                </div>
                <div className="text-block">
                  <p className={isPictureinfo == true ? "d-smoothy" : "d-none"}>
                    Kurs rasmini bu yerga yuklang. Muhim ko'rsatmalar: 750x422
                    piksel bo'lishi va formati : .jpg, .jpeg, yoki .png bo'lishi
                    lozim.
                    <br />
                    Diqqat! rasmda 3 tadan ko'p so'z bo'lmasligi kerak
                  </p>
                  <div className="input-photo">
                    <input
                      type="text"
                      placeholder="Fayl tanlanmagan"
                      disabled={true}
                      value={
                        id ? data?.image : data?.image1 && data?.image1.name
                      }
                    />
                    <input
                      type="file"
                      name="image"
                      id="image"
                      accept=".jpg , .png"
                      onChange={(e) =>
                        handleChange("image1", e.target.files[0])
                      }
                    />
                    <div className="video-cover-div-course">
                      <label htmlFor="image">Rasm yuklash</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="photo-block">
                <div className="image-block">
                  <p>
                    Kurs Treyleri{" "}
                    <span
                      onClick={() => setTrellerinfo(!isTrellerinfo)}
                      className="infoAbout"
                    >
                      <img src={Info} alt="#" />
                    </span>
                  </p>
                  <img src={Photo} alt="" />
                </div>
                <div className="text-block">
                  <p className={isTrellerinfo == true ? "d-smoothy" : "d-none"}>
                    Yaxshi tayyorlangan promo-videoni tomosha qilgan talabalar
                    sizning kursingizga kirish ehtimoli 5 baravar yuqori. Biz
                    juda ajoyib videolar uchun statistika 10X gacha
                    ko'tarilganini ko'rdik
                  </p>

                  {video ? (
                    <div className="input-photo">
                      <input
                        type="text"
                        placeholder="Treyler video yuklanmagan"
                        disabled={true}
                        value={
                          (data?.trailer && data?.trailer?.title) ||
                          data?.trailer?.video
                        }
                      />
                      <input
                        type="file"
                        name="video"
                        id="video"
                        onChange={(e) => {
                          handleChange("trailer", {
                            id: data?.trailer?.id,
                            is_file: true,
                            title: e.target.files[0].name,
                            url: "",
                            video: e.target.files[0],
                          });
                        }}
                      />
                      <div className="video-cover-div-course">
                        <label htmlFor="video">Video yuklash</label>
                      </div>
                    </div>
                  ) : (
                    <div className="link-input">
                      <input
                        type="text"
                        placeholder="video link kiriting"
                        value={data?.trailer?.url}
                        onChange={(e) =>
                          handleChange("trailer", {
                            id: data?.trailer?.id,
                            is_file: false,
                            title: e.target.value,
                            url: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                  <div className="choose-type-of-video">
                    <div>
                      <span>Video yuklash</span>
                      <input
                        type="radio"
                        id="radio"
                        name="radio"
                        onClick={() => setVideo(true)}
                        checked={video}
                        defaultChecked={video}
                        value="video"
                      />
                    </div>
                    <div>
                      <span>Videoga link berish</span>
                      <input
                        type="radio"
                        id="radio"
                        name="radio"
                        checked={!video}
                        onClick={() => setVideo(false)}
                        defaultChecked={!video}
                        value="link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="last-block">
              <p>
                Kurs kalit so’zlari{" "}
                <span
                  onClick={() => setHashtaginfo(!isHashtaginfo)}
                  className="infoAbout"
                >
                  <img src={Info} alt="#" />
                </span>
              </p>
              <span className={isHashtaginfo == true ? "d-smoothy" : "d-none"}>
                Kalit so’zi sizning kursingiz qiziq bo’lishi mumkin bo’lgan
                auditoriyaga tavsiya sifatida ko’rsatish uchun uchun kerak
                bo’ladi.
              </span>
              <div>
                <Autocomplete
                  onBlur={(e) => {
                    if (e.target.value.trim() !== "") {
                      setCourseTags((prev) => [...prev, e.target.value]);
                      handleChange("course_tag", [
                        ...courseTags,
                        e.target.value,
                      ]);
                    }
                  }}
                  multiple
                  limitTags={1}
                  id="tags-filled"
                  options={
                    data?.course_tags?.length
                      ? data?.course_tags?.map((option) => option.title)
                      : []
                  }
                  value={courseTags}
                  freeSolo={courseTags.length > 49 ? false : true}
                  onChange={(e, value) => {
                    setCourseTags([...value]);
                    handleChange("course_tag", value);
                  }}
                  renderTags={(value, getTagProps) =>
                    courseTags &&
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      placeholder="Favorites"
                    />
                  )}
                />
                {/* <p>
                  {data?.course_tag?.length
                    ? 50 - data?.course_tag?.length
                    : 50}
                </p> */}
                {/* <textarea
                  placeholder="Misol: Python, web dasturlash, biznes"
                  value={
                    data?.course_tag
                      ? data?.course_tag
                      : data?.course_tags?.length > 0
                      ? data?.course_tags?.map((x) => x.title + ",")
                      : ""
                  }
                  onChange={(e) => handleChange("course_tag", e.target.value)}
                  maxLength={50}
                />
                <p>
                  {data?.course_tags?.length
                    ? 50 - data?.course_tags?.length
                    : 50}
                </p> */}
              </div>
            </div>
          </div>
        )}

        {active === 2 && (
          <div className="second-form">
            <p className="header-title">Kurs strukturasi</p>
            <p className="header-title2">
              Bu sahifada siz kursingiz kontentiga oid ma’lumotlarni yuklaysiz
            </p>
            <div className="second-form-first-block">
              <p className="titleOfBlock">
                Kursingizda bilim oluvchilar nimani o'rganadilar?{" "}
                <span
                  onClick={() => setNimaOinfo(!isNimaOinfo)}
                  className="infoAbout"
                >
                  <img src={Info} alt="#" />
                </span>
              </p>
              <span className={isNimaOinfo == true ? "d-smoothy" : "d-none"}>
                Kursni tugatgandan so'ng o'quvchilar erishishi mumkin bo'lgan
                kamida 2 ta o'quv maqsadi yoki natijalarini kiritishingiz kerak.
              </span>
              <div className="input-second-block">
                <input
                  type="text"
                  placeholder="Misol: Loyiha menejerining roli va mas'uliyatini aniqlash"
                  onChange={(e) => handleChange("what_learn1", e.target.value)}
                  maxLength={120}
                  defaultValue={
                    data?.whatyoulearns && data?.whatyoulearns[0]?.title
                  }
                  value={
                    data?.what_learn1
                    // ? data?.what_learn1
                    // : data?.whatyoulearns?.length > 0
                    // ? data?.whatyoulearns[0]?.title
                    // : ""
                  }
                />
                <p>
                  {data?.what_learn1?.length
                    ? 120 - data?.what_learn1.length
                    : data?.whatyoulearns?.length > 0
                    ? 120 - data?.whatyoulearns[0]?.title.length
                    : 120}
                </p>
              </div>
              {countInput1 >= 3 && (
                <div className="input-second-block">
                  <input
                    type="text"
                    placeholder="Misol: loyiha risklarini aniqlash va boshqarish"
                    onChange={(e) =>
                      handleChange("what_learn2", e.target.value)
                    }
                    maxLength={120}
                    defaultValue={
                      data?.whatyoulearns && data?.whatyoulearns[1]?.title
                    }
                    value={
                      data?.what_learn2
                      // ? data?.what_learn2
                      // : data?.whatyoulearns?.length > 0
                      // ? data?.whatyoulearns[1]?.title
                      // : ""
                    }
                  />
                  <p>
                    {data?.what_learn2?.length
                      ? 120 - data?.what_learn2.length
                      : data?.whatyoulearns?.length > 0
                      ? 120 - data?.whatyoulearns[1]?.title.length
                      : 120}
                  </p>
                </div>
              )}
              {countInput1 >= 4 && (
                <div className="input-second-block">
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChange("what_learn3", e.target.value)
                    }
                    maxLength={120}
                    defaultValue={
                      data?.whatyoulearns && data?.whatyoulearns[2]?.title
                    }
                    value={data?.what_learn3}
                  />
                  <p>
                    {data?.what_learn3?.length
                      ? 120 - data?.what_learn3.length
                      : 120}
                  </p>
                </div>
              )}
              {countInput1 >= 5 && (
                <div className="input-second-block">
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChange("what_learn4", e.target.value)
                    }
                    maxLength={120}
                    defaultValue={data?.what_learn4}
                    value={data?.what_learn4}
                  />
                  <p>
                    {data?.what_learn4?.length
                      ? 120 - data?.what_learn4.length
                      : 120}
                  </p>
                </div>
              )}
              {countInput1 <= 3 && (
                <div
                  className="add-input"
                  onClick={() => setCountInput1(countInput1 + 1)}
                >
                  <div>
                    <img src={Plus} alt="" />
                  </div>
                  <p>Yana qo'shish</p>
                </div>
              )}
            </div>
            <div className="second-form-first-block">
              <p className="titleOfBlock">
                Kursga kirish uchun qanday talablar yoki old shartlar mavjud?{" "}
                <span
                  onClick={() => setTalabinfo(!isTalabinfo)}
                  className="infoAbout"
                >
                  <img src={Info} alt="#" />
                </span>
              </p>
              <span className={isTalabinfo == true ? "d-smoothy" : "d-none"}>
                Kursga kirishdan oldin o'quvchilar ega bo'lishi kerak bo'lgan
                kerakli ko'nikmalar, tajriba, asboblar yoki jihozlarni sanab
                o'ting. Hech qanday talablar bo'lmasa, bu joyni bo’sh
                qoldiring...
              </span>
              <div className="input-second-block-2">
                <input
                  type="text"
                  placeholder="Misol: Word va Exel dasturlarini ishlatishni bilishi kerak"
                  onChange={(e) => handleChange("requirement1", e.target.value)}
                  defaultValue={
                    data?.requirementscourse &&
                    data?.requirementscourse[0]?.title
                  }
                  value={
                    data?.requirement1
                    // ? data?.requirement1
                    // : data?.requirementscourse?.length > 0
                    // ? data?.requirementscourse[0]?.title
                    // : ""
                  }
                />
                <p>
                  {data?.requirement1?.length
                    ? 120 - data?.requirement1?.length
                    : data?.requirementscourse?.length > 0
                    ? 120 - data?.requirementscourse[0]?.title?.length
                    : 120}
                </p>
              </div>
              {countInput2 >= 3 && (
                <div className="input-second-block-2">
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChange("requirement2", e.target.value)
                    }
                    value={data?.requirement2}
                  />
                  <p>
                    {data?.requirement2?.length
                      ? 120 - data?.requirement2?.length
                      : 120}
                  </p>
                </div>
              )}
              {countInput2 >= 4 && (
                <div className="input-second-block-2">
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChange("requirement3", e.target.value)
                    }
                    value={data?.requirement3}
                  />
                  <p>
                    {data?.requirement3?.length
                      ? 120 - data?.requirement3?.length
                      : 120}
                  </p>
                </div>
              )}
              {countInput2 <= 3 && (
                <div
                  className="add-input"
                  onClick={() => setCountInput2(countInput2 + 1)}
                >
                  <div>
                    <img src={Plus} alt="" />
                  </div>
                  <p>Yana qo'shish</p>
                </div>
              )}
            </div>
            <div className="second-form-first-block">
              <p className="titleOfBlock">
                Bu kurs kim uchun?{" "}
                <span
                  onClick={() => setKimlarUinfo(!isKimlarUinfo)}
                  className="infoAbout"
                >
                  <img src={Info} alt="#" />
                </span>
              </p>
              <span className={isKimlarUinfo == true ? "d-smoothy" : "d-none"}>
                Kursingiz mazmunini qimmatli deb topadigan kursingiz uchun
                mo'ljallangan o'quvchilarning aniq tavsifini yozing. Bu sizga
                kerakli o'quvchilarni kursingizga jalb qilishga yordam beradi.
              </span>
              <div className="input-second-block-3">
                <input
                  type="text"
                  placeholder="Misol: boshlang'ich Python dasturchilariga qiziqadiganlar uchun"
                  onChange={(e) => handleChange("to_who1", e.target.value)}
                  defaultValue={data?.forwhoms && data?.forwhoms[0]?.title}
                  value={
                    data?.to_who1
                    // ? data?.to_who1
                    // : data?.forwhoms?.length > 0
                    // ? data?.forwhoms[0]?.title
                    // : ""
                  }
                />
                <p>
                  {data?.to_who1?.length
                    ? 120 - data?.to_who1?.length
                    : data?.forwhoms?.length > 0
                    ? 120 - data?.forwhoms[0]?.title.length
                    : 120}
                </p>
              </div>
              {countInput3 >= 3 && (
                <div className="input-second-block-3">
                  <input
                    type="text"
                    onChange={(e) => handleChange("to_who2", e.target.value)}
                    value={data?.to_who2}
                  />
                  <p>
                    {data?.to_who2?.length ? 120 - data?.to_who2?.length : 120}
                  </p>
                </div>
              )}
              {countInput3 >= 4 && (
                <div className="input-second-block-3">
                  <input
                    type="text"
                    onChange={(e) => handleChange("to_who3", e.target.value)}
                    value={data?.to_who3}
                  />
                  <p>
                    {data?.to_who3?.length ? 120 - data?.to_who3?.length : 120}
                  </p>
                </div>
              )}
              {countInput3 <= 3 && (
                <div
                  className="add-input"
                  onClick={() => setCountInput3(countInput3 + 1)}
                >
                  <div>
                    <img src={Plus} alt="" />
                  </div>
                  <p>Yana qo'shish</p>
                </div>
              )}
            </div>
          </div>
        )}

        {active === 3 && (
          <div className="third-block-course">
            <p className="main-title-3block">Narxlash Va Sertifikat</p>
            <p className="main-title-3block2">
              Bu sahifada siz kursingizgizni narxaysiz va sertifikat tizimini
              qo’shasiz
            </p>
            <div className="block3-1div">
              <p className="titleOfBlock">
                Narxlash{" "}
                <span
                  onClick={() => setNarxinfo(!isNarxinfo)}
                  className="infoAbout"
                >
                  <img src={Info} alt="#" />
                </span>
              </p>
              <span className={isNarxinfo == true ? "d-smoothy" : "d-none"}>
                Quyida kursingiz uchun narxlang , agarda kursingiz bepul bo’lsa
                “0” sonini kiritib keting va “Saqlash” tugmasini bosing.
              </span>
              <span>
                <span className="danger-block">! Diqqat</span> kurs narxi
                10.000.000 so’mdan oshib ketmasligi lozim{" "}
              </span>
              <div>
                <input
                  placeholder="Kursni narxini kiriting"
                  onChange={(e) => handleChange("price", e.target.value)}
                  value={data?.price}
                  type="number"
                />
                <p>so'm</p>
              </div>
            </div>
            <div className="block3-2div">
              <p className="titleOfBlock">
                Sertifikat tizimi{" "}
                <span
                  onClick={() => setSertifikatinfo(!isSertifikatinfo)}
                  className="infoAbout"
                >
                  <img src={Info} alt="#" />
                </span>
              </p>
              <span
                className={isSertifikatinfo == true ? "d-smoothy" : "d-none"}
              >
                Kursingizni yakunlagan lar uchun sertifikat taqdim etish imkoni
                mavjud , siz kursingizga sertifikat qo’shishni xohlaysizmi?{" "}
              </span>
              <span>
                {" "}
                <span className="danger-block"> ! Diqqat </span> , bepul
                kurslarga sertifikat tizimini qo’shib bo’lmaydi.
              </span>
              <span>Tez kunlarda sertifikat tizimi ishga tushadi.</span>
              <label>
                <p>Sertifikat tizimini kursga qo’shishni istayman</p>
                <input
                  type="checkbox"
                  id="select-course"
                  checked={isSertificate}
                  onClick={() => setIsSertificate((prev) => !prev)}
                  disabled={true}
                />
              </label>
            </div>
            {isSertificate && (
              <div className="block3-3div">
                <div className="text-div-sert">
                  <div className="top-sert">
                    <p>Sizning logoingiz</p>
                    <img src={arrowBot} alt="" />
                  </div>
                  <button>Logo yuklash</button>
                  <div className="footer-sert">
                    <p className="p-footer-div-sert">Sertifikat matni</p>
                    <img src={arrowBot} alt="" />
                  </div>
                </div>
                <div className="image-sertificate">
                  <div>
                    <div className="logo-content-sert">
                      <img src={whiteLogo} alt="" />
                      <div>
                        <img src={circle1} alt="" />
                        <img src={circle2} alt="" />
                      </div>
                    </div>
                    <div className="footer-content-sert">
                      <div className="footer-div-sert">
                        <div>
                          <div className="footer-logo">
                            <img src={mfactorLogo} alt="" />
                          </div>
                          <h1>SERTIFIKAT</h1>
                          <p>Murod Muxamedjanov</p>
                          <input type="text" />
                          <div className="last-footer-logo">
                            <p>27.08.2021</p>
                            <img src={checkIcon} alt="" />
                          </div>
                        </div>
                      </div>
                      <QrCode />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {active === 4 && (
          <div className="fourth-block-course">
            <p className="main-title-4block">Kurs targ’iboti </p>
            <div className="block4-1div">
              <p>Maslahatlar</p>
              <ul>
                <li>
                  Kursingiz keng tarqalishi uchun kurs nomini iloji boricha
                  qisqa va londa qiling.
                </li>
                <li>
                  Kurs tarifida kursingiz haqida to’liq ma’lumot va u
                  ma’lumotlar to’liq kursingizga mos kelishini ta’minlang.
                </li>
                <li>Imlo xatolarga yo’l qo’ymang.</li>
                <li>Kursingizga treller(anons) video yuklang</li>
                <li>Kurs videosidagi audio va video sifatiga etibor bering</li>
                <li>Kurs rasmida so’zlar 3tadan oshib ketmasin</li>
                <li>Do’stlaringiz bilan ulashing</li>
              </ul>
            </div>
            <div className="block4-1div">
              <p>Kurs uchun shartlar</p>
              <ul>
                <li>Kurs boshqa birovga tegishli bo’lmasligi.</li>
                <li>
                  Kursda tamaki , alkogol va odamlarni psixologiyasiga yomon
                  ta’sir ko’rsatuvchi lavhalar bo’lmasligi.
                </li>
                <li>Kursning mavzusi to’liq yoritib berilishi.</li>
                <li>Kurs kontenti kursni nomi va tarifiga mos kelishi.</li>
                <li>Kursda kamida 4 ta video bo’lishi.</li>
                <li>Kursni davomiyligi kamida 30 minutni tashkil etishi.</li>
                <li>Kurs video o'lchami 16:9 formatda bo’lishi.</li>
                <li>Kurs video sifati kamida 480K bo’lishi.</li>
                <li>Kurs video joyi maksimum 1 GB bo’lishi.</li>
              </ul>
            </div>
          </div>
        )}
        <div className="next-btn prev-btn">
          <div
            className={`${active == 1 && "opacity-none"} back-btn`}
            onClick={() => goBack()}
          >
            <img src={Arrow_left} alt="" />
            <p>Orqaga</p>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className={"modal-content"}>
              <h4>Kurs muvafaqiyatli yuklandi !</h4>
              <button
                onClick={() => {
                  history.push("courses/barchasi");
                  window.location.reload();
                }}
              >
                Kurslarimga o'tish
              </button>
            </div>
          </Modal>
          <div
            onClick={() => {
              // handleOpen();
              active === 4 && id
                ? editCourse()
                : active === 4 && !id
                ? createNewCourse()
                : active < 2
                ? validateGoNext()
                : goNext();
            }}
          >
            {!loading && <p>{active === 4 ? "Yakunlash" : "Keyengisi"}</p>}
            {loading && <p>Yuklanmoqda...</p>}
            {!loading && active !== 4 && <img src={Arrow} alt="" />}
          </div>
        </div>
      </div>
      {isLoad && (
        <div className="modal-upload-video">
          <div className="loader-video-modal">
            <p>Yuklanmoqda...</p>
            <div>
              <Loader />
              <p>{loadVideo} %</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import "./eduoncourses.css";
import File from "../../images/file.svg";
import Test from "../../images/test.svg";
import Certificate from "../../images/certificate.svg";
import Video from "../../images/video.svg";
import Left from "../../images/Arrow - Left Circle.svg";
import Right from "../../images/Arrow - Right Circle.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import Paper from "../../images/Paper Plus.svg";
import { api, API_VERSION } from "../../api/api";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import VideoPlayer from "../ui/VIdeoPlayer";
import "../ui/assets/CoursesSinglePage.css";
// import "plyr-react/dist/plyr.css";
import SkeletonLoading from "../ui/SkeletonLoading";
import axios from "axios";
import Cookies from "universal-cookie";
import Loader from "../ui/Loader";
import VideoIMg from "../images/video.svg";
import FIle from "../images/file.svg";
import TestImg from "./assets/test.svg";
import TestImg1 from "../../images/test-icon.png";
import ModalWindow from "../Modal/Modal";
import { Box, Button, TextField } from "@material-ui/core";

function EduonCourse(props) {
  const cookies = new Cookies();
  const history = useHistory();
  const [isOpen, setisOpen] = useState(false);
  const [editData, setEditData] = useState([]);
  const [name, setName] = useState(editData?.name ? editData?.name : "");
  const [file, setFile] = useState(editData?.file ? editData?.file : "");
  const [editFile, setEditFile] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const { id } = useParams();
  const [isModalOpen, setisModalOpen] = useState(false);
  const [courseModal, setCourseModal] = useState(false);
  const [loadirFile, setLoadirFile] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [module, setModule] = useState([]);
  const [isModalMavzu, setisModalMavzu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [isReact, setisReact] = useState(false);
  const [edit, setEdit] = useState(false);
  const [cover, setCover] = useState("");
  const [courseVideos, setCourseVideos] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [placeNumber, setPlaceNumber] = useState(
    editData?.place_number ? editData?.place_number : ""
  );
  const [dataVideo, setDataVideo] = useState([]);
  const [mavzu, setMavzu] = useState([]);
  const [courseInfo, setCourseInfo] = useState([]);
  const [courseDetails, setCourseDetails] = useState([]);
  const [videoType, setVideoType] = useState("video");
  const [courses, setCourses] = useState([]);
  const [courseVideo, setCourseVideo] = useState("");
  const [load, setLoad] = useState("");
  const [testData, setTestData] = useState({
    title: "",
    duration: "",
    required_score_to_pass: "",
    chances: "",
    questions_count: "",
    topic: "",
    slug: createUuid(),
    course: id ? JSON.parse(id) : "",
  });
  const [uploadVideo, setUploadVideo] = useState({
    title: "",
    image: null,
    course: id,
    description: "",
    video: null,
    url: "",
    place_number: "",
    courseModule: "",
  });
  const [open, setOpen] = useState(false);
  const [currentModuleToEdit, setCurrentModuleToEdit] = useState({});

  const handleChange = (name, value) => {
    setUploadVideo({
      ...uploadVideo,
      [name]: value,
    });
  };
  const handleChangeTest = (name, value) => {
    setTestData({
      ...testData,
      [name]: value,
    });
  };
  const handleChangeMavzu = (name, value) => {
    setMavzu({
      ...mavzu,
      [name]: value,
    });
  };

  const N = 60;
  let numbers = Array.apply(null, { length: N }).map(Number.call, Number);

  const createMavzu = useCallback(() => {
    axios
      .post(
        "https://inpuls-backend.backoffice.uz/api-web/coursemodule/",
        {
          title: mavzu.title,
          place_number: JSON.parse(mavzu.place_number),
          course: JSON.parse(id),
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.get("access_token"),
          },
        }
      )
      .then((res) => {
        toast.success("Mavzu yuklandi!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        api.get(`${API_VERSION}courses/${id}`).then((res) => {
          setLoading(false);
          setCourseInfo(res?.data?.course_obj);
          setCourseDetails(res?.data?.course_obj?.videos);
        });
        setisModalMavzu(false);
        api.get("api-web/coursemodule/").then((res) => {
          setModule(res);
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [id, mavzu, cookies]);

  const submitHandler = useCallback(() => {
    setVideoLoading(true);
    if (uploadVideo.title !== "" && uploadVideo.course !== "") {
      setIsLoad(true);
      let formData = new FormData();
      formData.append("title", uploadVideo.title);
      formData.append("course", uploadVideo.course);
      formData.append("description", uploadVideo.description);
      formData.append("courseModule", uploadVideo.courseModule);
      formData.append("is_file", videoType === "video" ? "1" : "0");
      videoType === "video" &&
        uploadVideo.video !== null &&
        formData.append("video", uploadVideo?.video);
      videoType === "link" &&
        uploadVideo.url !== null &&
        formData.append("url", uploadVideo.url);
      formData.append("place_number", uploadVideo.place_number);
      axios
        .post(
          "https://inpuls-backend.backoffice.uz/api-web/video-doc-upload/",
          formData,
          {
            headers: {
              Authorization: "Bearer " + cookies.get("access_token"),
            },
            onUploadProgress: (progressEvent) => {
              let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setLoad(percentCompleted);
            },
          }
        )
        .then((res) => {
          if (res.data.id) {
            // window.location.reload();
            toast.success("Kusrga video muavfaqiyatli qushildi!!!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoad(false);
            setLoad(0);
            setisOpen(false);
            setVideoLoading(false);
            api.get(`${API_VERSION}courses/${id}`).then((res) => {
              setLoading(false);
              setCourseInfo(res?.data?.course_obj);
              setCourseDetails(res?.data?.course_obj?.videos);
            });
          }
        })
        .catch(() => {
          toast.error("Xatolik , iltimos qaytadan urinib ko'ring", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoad(false);
          setLoad(0);
          setVideoLoading(false);
        });
    } else {
      toast.error("Barcha maydonlar tuldirilishi shart!!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setVideoLoading(false);
    }
  }, [uploadVideo, videoType]);

  const editVideoHandler = useCallback(() => {
    setisOpen(false);
    setVideoLoading(true);
    const formData = new FormData();
    formData.append("title", uploadVideo.title);
    formData.append("pk", dataVideo.id);
    // formData.append("image", uploadVideo.image);
    formData.append("description", uploadVideo.description);
    videoType === "video" &&
      uploadVideo.video !== null &&
      formData.append("video", uploadVideo.video);
    videoType === "link" &&
      uploadVideo.url !== null &&
      formData.append("url", uploadVideo.url);
    // formData.append("place_number", uploadVideo.place_number);
    api.put(`${API_VERSION}video/`, formData).then((res) => {
      if (res.success) {
        setVideoLoading(false);
        toast.success("Video malumotlari ozgartirildi!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        api.get(`${API_VERSION}courses/${id}`).then((res) => {
          setVideoLoading(false);
          setCourseInfo(res?.data?.course_obj);
          setCourseDetails(res?.data?.course_obj?.videos);
        });
      }
    });
  }, [uploadVideo, videoType, dataVideo, id]);

  const deleteVideoHandler = useCallback(
    (value) => {
      const formData = new FormData();
      if (value.file) {
        formData.append("file_id", value.id);
      } else {
        formData.append("video_id", value.id);
      }
      api
        .delete(`${API_VERSION}video/`, {
          data: formData,
        })
        .then((res) => {
          if (res.success === true) {
            setLoading(true);
            toast.success("Video muafaqiyatli uchirildi!!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            api.get(`${API_VERSION}courses/${id}`).then((res) => {
              setLoading(false);
              setCourseInfo(res?.data?.course_obj);
              setCourseDetails(res?.data?.course_obj?.videos);
            });
          }
        });
    },
    [props.setSpeakerCourses]
  );

  // const deleteCourse = useCallback((id) => {
  //     api.delete(`${API_VERSION}courses/${id}/`).then(res => {
  //         toast.success("Kurs ochirildi", {
  //             position: toast.POSITION.TOP_RIGHT
  //         });
  //         api.get(`${API_VERSION}get-speaker-data/`).then(res => {
  //             props.setSpeakerCourses(res)
  //         })
  //     })
  // }, [props.setSpeakerCourses]);
  const addFile = (e) => {
    e.preventDefault();
    setLoadirFile(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    formData.append("courseModule", moduleName);
    formData.append("place_number", placeNumber);
    api
      .post(`${API_VERSION}upload-file/`, formData)
      .then((res) => {
        if (res.success === true) {
          setisModalOpen(false);
          toast.success("File yuklandi", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadirFile(false);
          api.get(`${API_VERSION}courses/${id}`).then((res) => {
            setLoading(false);
            setCourseInfo(res?.data?.course_obj);
            setCourseDetails(res?.data?.course_obj?.videos);
          });
        }
      })
      .catch(() => {
        toast.error("File yuklashda xatolik");
        setLoadirFile(false);
      });
  };

  const editFile1 = (e) => {
    e.preventDefault();
    setLoadirFile(true);
    const formData = new FormData();
    formData.append("name", name);
    {
      file && formData.append("file", file);
    }
    {
      moduleName && formData.append("courseModule", moduleName);
    }
    formData.append("place_number", placeNumber);
    api
      .patch(`${API_VERSION}upload-file-patch/${editData?.id}/`, formData)
      .then((res) => {
        setEditFile(false);
        setEditData([]);
        setisModalOpen(false);
        setName("");
        setPlaceNumber("");
        toast.success("File taxrirlandi", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadirFile(false);
        api.get(`${API_VERSION}courses/${id}`).then((res) => {
          setLoading(false);
          setCourseInfo(res?.data?.course_obj);
          setCourseDetails(res?.data?.course_obj?.videos);
        });
      })
      .catch(() => {
        toast.error("File taxrirlashda xatolik");
        setLoadirFile(false);
      });
  };

  const openEditVideoHandler = useCallback(
    (value) => {
      if (value.file) {
        return;
      }
      setisOpen(true);
      setDataVideo(value);
      setEdit(true);
      setUploadVideo({
        title: value?.title,
        course: courseInfo?.name,
        description: value?.description,
        video: value?.video,
        url: value?.url,
      });
    },
    [courseInfo]
  );

  const setEditHandler = (value) => {
    setEditFile(true);
    setEditData(value);
    setisModalOpen(true);
    setName(value.name);
    setPlaceNumber(value.place_number);
  };

  useEffect(() => {
    let mounted = true;
    api.get(`${API_VERSION}courses/`).then((res) => {
      if (mounted) {
        setCourses(res.data.courses);
      }
    });
    return () => {
      mounted = false;
    };
  }, [id]);

  const getModules = (mounted) => {
    setLoading(true);
    api.get(`${API_VERSION}courses/${id}`).then((res) => {
      if (mounted) {
        setLoading(false);
        setCourseInfo(res?.data?.course_obj);
        setCourseDetails(res?.data?.course_obj?.videos);
      }
    });
  };

  useEffect(() => {
    let mounted = true;
    getModules(mounted);
    return () => {
      mounted = false;
    };
  }, [id]);

  function compareNumbers(a, b) {
    return a.place_number - b.place_number;
  }

  const filterPlaceNumber = useMemo(
    () => courseDetails?.map((x) => x?.video?.place_number),
    [courseDetails]
  );
  const courseAfterFilter = useMemo(
    () => courses?.filter((x) => x.id == id),
    [courses, id]
  );
  function getCourseModule() {
    api.get("api-web/coursemodule/").then((res) => {
      setModule(res);
    });
  }
  useEffect(() => {
    getCourseModule();
  }, []);

  function createUuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  const createQuiz = useCallback(() => {
    api
      .post("quizzes/quiz/create/", {
        title: testData.title,
        duration: testData.duration ? JSON.parse(testData.duration) : 0,
        required_score_to_pass: testData.required_score_to_pass
          ? JSON.parse(testData.required_score_to_pass)
          : 0,
        chances: testData.chances ? JSON.parse(testData.chances) : 0,
        total_question_count: testData.questions_count
          ? JSON.parse(testData.questions_count)
          : 0,
        topic: testData.topic,
        slug: createUuid(),
        course: id ? JSON.parse(id) : "",
      })
      .then((res) => {
        if (res) {
          toast.success("Test yaratildi!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setisReact(false);
          api.get(`${API_VERSION}courses/${id}`).then((res) => {
            setLoading(false);
            setCourseInfo(res?.data?.course_obj);
            setCourseDetails(res?.data?.course_obj?.videos);
          });
        }
      })
      .catch(() => {
        toast.error("Xatolik , iltimos qaytadan urinib ko'ring", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [testData, createUuid, id]);
  const [moduleId, setModuleId] = useState("");
  const move = (quzies) => {
    history.push(`/main/course/${id}/create-test/${quzies.id}`);
    window.localStorage.setItem("totalQuestion", quzies.total_question_count);
  };
  function compareNumbers(a, b) {
    return a.place_number - b.place_number;
  }

  const openModal = (moduleInfoObj) => {
    setCurrentModuleToEdit(moduleInfoObj);
    setOpen(true);
  };

  return (
    <React.Fragment>
      <ModalWindow
        open={open}
        handleClose={() => setOpen(false)}
        currentItem={currentModuleToEdit}
        setCurrentModuleToEdit={setCurrentModuleToEdit}
        getCourseModule={getModules}
      />

      <div className="one-course-content-by-id">
        {courseModal ? (
          <div className="container-video">
            <VideoPlayer
              close={setCourseModal}
              video={courseVideo}
              videoUrl={videoUrl}
            />
          </div>
        ) : (
          ""
        )}
        <div className="top-course-elements-to-editing-and-uploading">
          <div className="div-upload-video-file-test">
            <button
              onClick={() => {
                setisOpen(true);
                setUploadVideo({
                  title: "",
                  image: null,
                  course: id,
                  description: "",
                  video: null,
                  url: "",
                  place_number: "",
                });
                setEdit(false);
              }}
            >
              <img src={Video} alt="" />
              <span>Video yuklash</span>
            </button>
          </div>
          <div className="div-upload-video-file-test">
            <button onClick={() => setisModalOpen(true)}>
              <img src={File} alt="" />
              <span>Fayl yuklash</span>
            </button>
          </div>
          <div className="div-upload-video-file-test">
            <button onClick={() => setisReact(true)}>
              <img src={Test} alt="" />
              <span>Test yuklash</span>
            </button>
          </div>
          <div className="div-upload-video-file-test">
            <button onClick={() => setisReact(true)}>
              <img src={Certificate} alt="" />
              <span>Sertifikat yuklash</span>
            </button>
          </div>
          <div className="div-upload-video-file-test">
            <button onClick={() => setisModalMavzu(true)}>
              <img src={Certificate} alt="" />
              <span>Mavzu qoshish</span>
            </button>
          </div>
        </div>
        <h3 className="course-structure-title">Kurs tarkibi</h3>
        {!loading &&
          courseInfo &&
          courseInfo.modules?.map((mod, index) => {
            const orderTasksFiltered = mod?.files?.concat(
              mod?.videos.concat(mod?.quizzes)
            );
            const finalFilter = orderTasksFiltered?.sort(compareNumbers);
            return (
              <div className="dropdown-menu-video" key={index}>
                <div
                  className="config_box"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    onClick={() =>
                      setModuleId(moduleId !== mod.id ? mod.id : "")
                    }
                  >
                    Module {index + 1} - {mod.title}
                  </h3>
                  <h3>
                    <img src={Edit} alt="" onClick={(e) => openModal(mod)} />
                  </h3>
                </div>
                <div className={`main-drop ${moduleId === mod.id && "show"}`}>
                  {finalFilter?.map((video) => (
                    <div className={`dropdown-item`} key={index}>
                      <div
                        className="name"
                        onClick={() => {
                          {
                            video?.quiztaker && move(video);

                            !video.file && setCourseModal(true);
                            video.url === ""
                              ? setVideoUrl(video?.video)
                              : setVideoUrl(video?.url);
                            !video.file && setCourseVideo(video?.video);
                          }
                        }}
                      >
                        <img
                          src={
                            video?.quiztaker
                              ? TestImg1
                              : video.file
                              ? FIle
                              : Video
                          }
                          alt=""
                          width={40}
                          height={40}
                        />
                        <p>{video.file ? video.name : video?.title}</p>
                      </div>
                      <div className="actions">
                        <img
                          src={Edit}
                          alt=""
                          onClick={(e) => {
                            e.preventDefault();
                            video.file
                              ? setEditHandler(video)
                              : openEditVideoHandler(video);
                          }}
                        />
                        <img
                          src={Delete}
                          alt=""
                          onClick={(e) => {
                            e.preventDefault();
                            deleteVideoHandler(video);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        {loading && (
          <>
            <SkeletonLoading />
            <SkeletonLoading classname="hidden-in-480" />
            <SkeletonLoading classname="hidden-in-768" />
            <SkeletonLoading classname="hidden-in-1280" />
            <SkeletonLoading classname="hidden-in-1280" />
            <SkeletonLoading classname="hidden-in-1280" />
          </>
        )}
        {/*<div className="uploaded-course-file-inside-div">*/}
        {/*    {!loading && courseInfo && courseInfo.modules?.map((x, index) => {*/}
        {/*        const orderTasksFiltered = x.video_courses.sort(compareNumbers);*/}
        {/*        return (*/}
        {/*            <div className="videos_spiker">*/}
        {/*                <p>Module {index + 1} - {x.title}</p>*/}
        {/*                {orderTasksFiltered?.map(video => (*/}
        {/*                    <div className="uploaded-video-course-inside-div-element" key={x?.id}>*/}
        {/*                        <div className="img-div-element-div" onClick={() => {*/}
        {/*                            {*/}
        {/*                                !video.file && setCourseModal(true);*/}
        {/*                                video.url === "" ? setVideoUrl(video?.video) : setVideoUrl(video?.url);*/}
        {/*                                !video.file && setCourseVideo(video?.video);*/}
        {/*                            }*/}
        {/*                        }}>*/}
        {/*                            <img*/}
        {/*                                src={video.file ? FIle : VideoIMg}*/}
        {/*                                alt="sds"/>*/}
        {/*                            {!video.file ? <div className="play-course-button">*/}
        {/*                                <svg*/}
        {/*                                    aria-hidden="true"*/}
        {/*                                    focusable="false"*/}
        {/*                                    data-prefix="fas"*/}
        {/*                                    data-icon="play-circle"*/}
        {/*                                    className="svg-inline--fa fa-play-circle fa-w-16"*/}
        {/*                                    role="img"*/}
        {/*                                    style={{cursor: "pointer"}}*/}
        {/*                                    xmlns="http://www.w3.org/2000/svg"*/}
        {/*                                    viewBox="0 0 512 512"*/}
        {/*                                >*/}
        {/*                                    <path*/}
        {/*                                        fill="#006aff"*/}
        {/*                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"*/}
        {/*                                    />*/}
        {/*                                </svg>*/}
        {/*                            </div> : ""}*/}
        {/*                        </div>*/}
        {/*                        <div className="course-title-div-element-div">*/}
        {/*                            <span>{video.file ? video.name : video?.title}</span>*/}
        {/*                        </div>*/}
        {/*                        <div className="course-parametres-for-settings">*/}
        {/*                            <div className="left-side-course-parametres">*/}
        {/*                                <div>*/}
        {/*                                    <img src={Left} alt=""/>*/}
        {/*                                </div>*/}
        {/*                                <span>{x?.place_number}</span>*/}
        {/*                                <div>*/}
        {/*                                    <img src={Right} alt=""/>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                            <div className="right-side-course-parametres">*/}
        {/*                                <div>*/}
        {/*                                    <img src={Edit} alt="" onClick={() =>*/}
        {/*                                        openEditVideoHandler(video)*/}
        {/*                                    }/>*/}
        {/*                                </div>*/}
        {/*                                <div>*/}
        {/*                                    <img src={Delete} alt="" onClick={(e) => {*/}
        {/*                                        e.preventDefault();*/}
        {/*                                        deleteVideoHandler(video)*/}
        {/*                                    }}/>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>))}*/}
        {/*                {x?.quiz?.map(quzies => (*/}
        {/*                    <div className="uploaded-video-course-inside-div-element" key={x?.id}>*/}
        {/*                        <div className="img-test"*/}
        {/*                             onClick={() => {*/}
        {/*                                 history.push(`/main/course/${id}/create-test/${quzies.id}`);*/}
        {/*                                 window.localStorage.setItem("totalQuestion", quzies.total_question_count)*/}
        {/*                             }}*/}
        {/*                        >*/}
        {/*                            <img*/}
        {/*                                src={TestImg}*/}
        {/*                                width={100}*/}
        {/*                                height={100}*/}
        {/*                                className=""*/}
        {/*                                alt="sds"/>*/}
        {/*                        </div>*/}
        {/*                        <div className="course-title-div-element-div">*/}
        {/*                            <span>{quzies.title}</span>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                ))}*/}
        {/*            </div>*/}
        {/*        )*/}
        {/*    })}*/}
        {/*    /!*<div className="uploaded-video-course-inside-div-element">*!/*/}
        {/*    /!*    <div className="img-div-element-div">*!/*/}
        {/*    /!*        <img src={Img} alt=""/>*!/*/}
        {/*    /!*    </div>*!/*/}
        {/*    /!*    <div className="course-title-div-element-div">*!/*/}
        {/*    /!*        <span>Blog yuritishni 0 dan o’rganamiz. Amaliy mashg’ulot.</span>*!/*/}
        {/*    /!*    </div>*!/*/}
        {/*    /!*    <div className="course-parametres-for-settings">*!/*/}
        {/*    /!*        <div className="left-side-course-parametres">*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Left} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*            <span>1</span>*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Right} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*        </div>*!/*/}
        {/*    /!*        <div className="right-side-course-parametres">*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Edit} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Delete} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*        </div>*!/*/}
        {/*    /!*    </div>*!/*/}
        {/*    /!*</div>*!/*/}
        {/*    /!*<div className="uploaded-file-course-inside-div-element">*!/*/}
        {/*    /!*    <div className="file-name-of-parametres">*!/*/}
        {/*    /!*        <span>Fayl: diajdiuh.doc</span>*!/*/}
        {/*    /!*    </div>*!/*/}
        {/*    /!*    <div className="file-title-of-file-course">*!/*/}
        {/*    /!*        <span>Blog yuritishni 0 dan o’rganamiz. Amaliy mashg’ulot.</span>*!/*/}
        {/*    /!*    </div>*!/*/}
        {/*    /!*    <div className="course-parametres-for-settings">*!/*/}
        {/*    /!*        <div className="left-side-course-parametres">*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Left} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*            <span>2</span>*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Right} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*        </div>*!/*/}
        {/*    /!*        <div className="right-side-course-parametres">*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Edit} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*            <div>*!/*/}
        {/*    /!*                <img src={Delete} alt=""/>*!/*/}
        {/*    /!*            </div>*!/*/}
        {/*    /!*        </div>*!/*/}
        {/*    /!*    </div>*!/*/}
        {/*    /!*</div>*!/*/}
        {/*</div>*/}
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setisOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgb(36, 71, 120, 0.36)",
            },
            content: {
              width: "554px",
              height: "830px",
              left: "0",
              right: "0",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "24px",
              padding: "39px 36px 39px 36px",
            },
          }}
        >
          <div className="course-name-title">
            <p>
              Video nomi <span className="required-input">*</span>
            </p>
            <input
              type="text"
              id="input"
              onChange={(e) => handleChange("title", e.target.value)}
              defaultValue={uploadVideo?.title}
            />
          </div>
          <div className="course-name-title">
            <p>
              Kursni tanlash <span className="required-input">*</span>
            </p>
            <select
              className="category-select-variant"
              name="cars"
              id="input"
              onChange={(e) => handleChange("course", e.target.value)}
              // defaultChecked={courseAfterFilter && courseAfterFilter[0]?.name}
            >
              <option hidden selected />
              {courses &&
                courses.map((x) => (
                  <option
                    key={x.id}
                    value={x.id}
                    selected={courseAfterFilter[0].name === x.name}
                  >
                    {x.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="course-name-title">
            <p>
              Mavzu <span className="required-input">*</span>
            </p>
            <select
              className="category-select-variant"
              name="cars"
              id="input"
              onChange={(e) => handleChange("courseModule", e.target.value)}
              // defaultChecked={courseAfterFilter && courseAfterFilter[0]?.name}
            >
              <option hidden selected />
              {module &&
                module
                  ?.filter((x) => x.course === JSON.parse(id))
                  ?.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.title}
                    </option>
                  ))}
            </select>
          </div>
          <div className="course-image-file">
            <div className="choose-type-video">
              <p>
                Video yuklash
                <input
                  name="radio"
                  type="radio"
                  value="video"
                  id="radio"
                  onClick={() => setVideoType("video")}
                  defaultChecked={videoType === "video"}
                />
              </p>
              <p>
                Video ga link berish
                <input
                  name="radio"
                  type="radio"
                  value="link"
                  id="radio"
                  onClick={() => setVideoType("link")}
                  defaultChecked={videoType === "link"}
                />
              </p>
            </div>
            {videoType === "video" ? (
              <div>
                <div className="course-file-name-label">
                  <div className="course-file-name-div">
                    <p className="course-file-name">
                      {uploadVideo?.video !== null
                        ? uploadVideo?.video?.name
                          ? uploadVideo.video?.name
                          : uploadVideo?.video
                        : ""}
                    </p>
                  </div>
                  <input
                    type="file"
                    name="video"
                    id="video"
                    onChange={(e) => {
                      handleChange("video", e.target.files[0]);
                    }}
                  />
                  <div className="video-cover-div">
                    <label htmlFor="video">
                      <img src={Paper} alt="" />
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div className="course-name-title-link">
                <input
                  type="text"
                  id="input"
                  onChange={(e) => handleChange("url", e.target.value)}
                  defaultValue={uploadVideo.url}
                />
              </div>
            )}
          </div>
          <div className="course-type2">
            <div className="course-name-title-link">
              <p>
                Video tartib raqami <span className="required-input">*</span>
              </p>
              <input
                onChange={(e) => handleChange("place_number", e.target.value)}
                defaultValue={uploadVideo?.place_number}
              />
              {/*<select*/}
              {/*    className="category-select-variant2 variant-3"*/}
              {/*    name="types"*/}
              {/*    id="input"*/}
              {/*    onChange={(e) => handleChange("place_number", e.target.value)}*/}
              {/*    onScroll*/}
              {/*    defaultValue={uploadVideo?.place_number}*/}
              {/*>*/}
              {/*    <option hidden selected/>*/}
              {/*    {numbers.map(x => (*/}
              {/*        <option value={x.id} hidden={filterPlaceNumber?.includes(x + 1)}>{x + 1}</option>*/}
              {/*    ))*/}
              {/*    }*/}
              {/*</select>*/}
            </div>
          </div>
          <div className="about-course-write-content">
            <p>
              Kurs haqida
              {/* <span className="required-input">*</span> */}
            </p>
            <textarea
              rows="4"
              cols="50"
              id="input"
              onChange={(e) => handleChange("description", e.target.value)}
              defaultValue={uploadVideo?.description}
            />
          </div>
          <div className="modal-close-and-upload-buttons">
            <button id="close-btn" onClick={() => setisOpen(false)}>
              Bekor qilish
            </button>
            <button
              id="upload-course-btn"
              type="submit"
              onClick={edit ? editVideoHandler : submitHandler}
            >
              {videoLoading ? `Yuklanmoqda...` : "Yuklash"}
            </button>
          </div>
          {isLoad && (
            <div className="modal-upload-video">
              <div className="loader-video-modal">
                <p>Yuklanmoqda...</p>
                <div>
                  <Loader />
                  <p>{load} %</p>
                </div>
              </div>
            </div>
          )}
        </Modal>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setisModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgb(36, 71, 120, 0.36)",
            },
            content: {
              width: "554px",
              height: "410px",
              left: "0",
              right: "0",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "24px",
              padding: "39px 36px 39px 36px",
              marginTop: "105px",
            },
          }}
        >
          <div className="top-modal-file-upload-from-teacher-div">
            <p>Fayl nomi</p>
            <input
              type="text"
              value={name}
              className="top-modal-file-upload-from-teacher-div-input"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="course-name-title">
            <p>
              Mavzu <span className="required-input">*</span>
            </p>
            <select
              className="category-select-variant"
              name="cars"
              id="input"
              onChange={(e) => setModuleName(e.target.value)}
            >
              <option hidden selected />
              {module &&
                module
                  ?.filter((x) => x.course === JSON.parse(id))
                  ?.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.title}
                    </option>
                  ))}
            </select>
          </div>
          <div className="course-name-title">
            <p>
              Fayl tartib raqami <span className="required-input">*</span>
            </p>
            <input
              value={placeNumber}
              onChange={(e) => setPlaceNumber(e.target.value)}
            />
            {/*<select*/}
            {/*    className="category-select-variant2 variant-3"*/}
            {/*    name="types"*/}
            {/*    id="input"*/}
            {/*    onChange={(e) => handleChange("place_number", e.target.value)}*/}
            {/*    onScroll*/}
            {/*    defaultValue={uploadVideo?.place_number}*/}
            {/*>*/}
            {/*    <option hidden selected/>*/}
            {/*    {numbers.map(x => (*/}
            {/*        <option value={x.id} hidden={filterPlaceNumber?.includes(x + 1)}>{x + 1}</option>*/}
            {/*    ))*/}
            {/*    }*/}
            {/*</select>*/}
          </div>
          <div className="midlle-modal-file-upload-from-teacher-div">
            <p>Faylni yuklang</p>
            <div className="course-file-name-label">
              <div className="course-file-name-div">
                <p className="course-file-name">{file ? file.name : ""}</p>
              </div>
              <input
                type="file"
                name="photo"
                id="video-cover"
                accept=".pdf,.ppt,.xls,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
              />
              <div className="video-cover-div">
                <label htmlFor="video-cover">
                  <img src={Paper} alt="" />
                </label>
              </div>
            </div>
          </div>
          <div className="bottom-modal-file-upload-from-teacher-div">
            <div className="modal-close-and-upload-buttons">
              <button id="close-btn" onClick={() => setisModalOpen(false)}>
                Bekor qilish
              </button>
              <button
                id="upload-course-btn"
                disabled={loadirFile}
                onClick={editFile ? editFile1 : addFile}
              >
                {loadirFile ? "Yuklanmoqda..." : "Yuklash"}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isModal}
          onRequestClose={() => setisModal(false)}
          style={{
            overlay: {
              backgroundColor: "rgb(36, 71, 120, 0.36)",
            },
            content: {
              width: "554px",
              height: "410px",
              left: "0",
              right: "0",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "24px",
              padding: "39px 36px 39px 36px",
              marginTop: "105px",
            },
          }}
        >
          <div className="top-modal-file-upload-from-teacher-div">
            <p>Fayl nomi</p>
            <input
              type="text"
              value="Raqobatdan qutulish usullari"
              className="top-modal-file-upload-from-teacher-div-input"
            />
          </div>
          <div className="midlle-modal-file-upload-from-teacher-div">
            <p>Faylni yuklang</p>
            <div className="course-file-name-label">
              <div className="course-file-name-div">
                <p className="course-file-name">{cover}</p>
              </div>
              <input
                type="file"
                name="photo"
                id="video-cover"
                onChange={(event) => {
                  setCover(event.target.files[0].name);
                }}
              />
              <div className="video-cover-div">
                <label htmlFor="video-cover">
                  <img src={Paper} alt="" />
                </label>
              </div>
            </div>
          </div>
          <div className="bottom-modal-file-upload-from-teacher-div">
            <div className="modal-close-and-upload-buttons">
              <button id="close-btn" onClick={() => setisModal(false)}>
                Bekor qilish
              </button>
              <button id="upload-course-btn">Yuklash</button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isReact}
          onRequestClose={() => setisReact(false)}
          style={{
            overlay: {
              backgroundColor: "rgb(36, 71, 120, 0.36)",
            },
            content: {
              width: "600px",
              height: "85%",
              overflow: "auto",
              left: "0",
              right: "0",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "24px",
              padding: "39px 36px 39px 36px",
              marginTop: "100px",
            },
          }}
        >
          <div className="top-modal-file-upload-from-teacher-div">
            <p>Test nomi</p>
            <input
              type="text"
              style={{ width: "100%" }}
              onChange={(e) => handleChangeTest("title", e.target.value)}
              className="top-modal-file-upload-from-teacher-div-input"
            />
          </div>
          <div className="top-modal-file-upload-from-teacher-div">
            <p>Mavzu</p>
            <div className="course-file-name-label">
              <p>{cover}</p>
              <select
                className="category-select-variant"
                name="cars"
                id="input"
                style={{ width: "100%" }}
                onChange={(e) => handleChangeTest("topic", e.target.value)}
                // defaultChecked={courseAfterFilter && courseAfterFilter[0]?.name}
              >
                <option hidden selected />
                {module &&
                  module
                    ?.filter((x) => x.course === JSON.parse(id))
                    ?.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.title}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div
            className="top-modal-file-upload-from-teacher-div"
            style={{ marginTop: "10px" }}
          >
            <p>Savollar miqdori</p>
            <input
              type="number"
              onChange={(e) =>
                handleChangeTest("questions_count", e.target.value)
              }
              style={{ width: "100%" }}
              className="top-modal-file-upload-from-teacher-div-input"
            />
          </div>
          <div className="test-modal" style={{ marginTop: "10px" }}>
            <div
              className="top-modal-file-upload-from-teacher-div"
              style={{ width: "33%" }}
            >
              <p>O'tish bali</p>
              <input
                type="number"
                width={"100%"}
                onChange={(e) =>
                  handleChangeTest("required_score_to_pass", e.target.value)
                }
                className="test-modal-input"
              />
            </div>
            <div
              className="top-modal-file-upload-from-teacher-div"
              style={{ width: "33%" }}
            >
              <p>Vaqtini belgilash</p>
              <input
                type="number"
                width={"100%"}
                onChange={(e) => handleChangeTest("duration", e.target.value)}
                className="test-modal-input"
              />
            </div>
            <div
              className="top-modal-file-upload-from-teacher-div"
              style={{ width: "33%" }}
            >
              <p>Qayta topshirilsinmi?</p>
              <select
                className="category-select-variant"
                name="cars"
                id="input"
                style={{ width: "100%" }}
                // defaultChecked={courseAfterFilter && courseAfterFilter[0]?.name}
                onChange={(e) => handleChangeTest("chances", e.target.value)}
              >
                <option hidden selected />
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="0">Yoq</option>
              </select>
            </div>
          </div>
          <div className="bottom-modal-file-upload-from-teacher-div">
            <div className="modal-close-and-upload-buttons">
              <button id="close-btn" onClick={() => setisReact(false)}>
                Bekor qilish
              </button>
              <button id="upload-course-btn" onClick={createQuiz}>
                Yuklash
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isModalMavzu}
          onRequestClose={() => setisModalMavzu(false)}
          style={{
            overlay: {
              backgroundColor: "rgb(36, 71, 120, 0.36)",
            },
            content: {
              width: "554px",
              height: "410px",
              left: "0",
              right: "0",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "24px",
              padding: "39px 36px 39px 36px",
              marginTop: "105px",
            },
          }}
        >
          <div>
            <div className="top-modal-file-upload-from-teacher-div">
              <p>Mavzu nomi</p>
              <input
                type="text"
                className="top-modal-file-upload-from-teacher-div-input"
                onChange={(e) => handleChangeMavzu("title", e.target.value)}
              />
            </div>
            <div className="top-modal-file-upload-from-teacher-div">
              <p>Tartib raqami</p>
              <input
                type="number"
                className="top-modal-file-upload-from-teacher-div-input"
                onChange={(e) =>
                  handleChangeMavzu("place_number", e.target.value)
                }
              />
            </div>
            <div className="bottom-modal-file-upload-from-teacher-div">
              <div className="modal-close-and-upload-buttons">
                <button id="close-btn" onClick={() => setisModalMavzu(false)}>
                  Bekor qilish
                </button>
                <button id="upload-course-btn" onClick={createMavzu}>
                  Yuklash
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/*{!isLoad && <div className="modal-upload-video">*/}
        {/*     <div className="loader-video-modal">*/}
        {/*         <p>Yuklanmoqda...</p>*/}
        {/*         <div>*/}
        {/*             <Loader/>*/}
        {/*             <p>{load} %</p>*/}
        {/*         </div>*/}
        {/*     </div>*/}
        {/* </div>}*/}
      </div>
      {/*{openLoadModal &&*/}
      {/*    <div className="modal-load-course">*/}
      {/*        <p>Iltimos kuting</p>*/}
      {/*        <p>Video yuklanyapti {load}%</p>*/}
      {/*</div>}*/}
    </React.Fragment>
  );
}

export default EduonCourse;

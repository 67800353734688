import React, {useState} from 'react';
import {toast} from "react-toastify";
import {api} from "../../api/api";


export default function ProfPassword() {
    const [old_password, setOld_password] = useState('');
    const [new_password, setNew_password] = useState('');
    const [confirm_password, setConfirm_password] = useState('');

    const addProduct = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('old_password', old_password);
        formData.append('new_password', new_password);
        formData.append('confirm_password', confirm_password);

        api.post('update-speaker-password', formData).then(res =>{
            if(res.id !== null) {
                toast.success("Parol muavfaqiyatli almashtirildi!!!", {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        })
    };

    return (
        <React.Fragment>
            <div className="profpassword-wrapper-div-for-content" onSubmit={addProduct}>
                <div className="top-div-for-profpassword-input">
                    <div className="divs-for-prof-passwaord-criteria">
                        <p>Eski parol</p>
                        <input type="password" placeholder="******" onChange={(e) => setOld_password(e.target.value)}/>
                    </div>
                    <div className="divs-for-prof-passwaord-criteria">
                        <p>Yangi parol</p>
                        <input type="password" placeholder="********" onChange={(e) => setNew_password(e.target.value)}/>
                    </div>
                    <div className="divs-for-prof-passwaord-criteria">
                        <p>Yangi parolni tasdiqlash</p>
                        <input type="password"  placeholder="********" onChange={(e) => setConfirm_password(e.target.value)}/>
                    </div>
                </div>
                <div className="bottom-div-for-profpassword-button">
                    <button type="submit" onClick={addProduct}>Saqlash</button>
                </div>
            </div>
        </React.Fragment>
    );
}

import React, {useCallback, useState} from 'react';
import NavBar from '../../../Navbar';
import './photo3.css';
import imgIcon from "../../../../images/img-icon.svg";
import plus from "../../../../images/plus.svg";
import {api} from "../../../../../api/api";
import leftArrow from "../../../../../images/left-arrow.svg";
import {toast} from "react-toastify";

const Photo3 = ({setPage, quizType}) => {
    const [type, setType] = useState("");
    const [image, setImage] = useState("");
    const [file, setFile] = useState();
    const [title, setTitle] = useState("");

    const getImage = (images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setImage(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
        })
    };
    const createAnswers = useCallback(() => {
        const formData = new FormData();
        formData.append("is_active", true);
        formData.append("img1", file);
        formData.append("structure", quizType);
        formData.append("quiz", [window.location.pathname.split("/").at(-1)]);
        const formData1 = new FormData();
        formData1.append("title", title);
        formData.append("structure", quizType);
        formData1.append("is_active", true);
        formData1.append("quiz", [window.location.pathname.split("/").at(-1)]);
        {
            type === "image" ? api.post("quizzes/question/create/", formData).then(res => {
                    if (res) {
                        setPage(4);
                        toast.success("Savol yuklandi!");
                        window.localStorage.setItem("question", res.id);
                    }
                }).catch(() => {
                    toast.error("Xatolik!")
                }) :
                api.post("quizzes/question/create/", formData1).then(res => {
                    if (res) {
                        setPage(4);
                        toast.success("Savol yuklandi!");
                        window.localStorage.setItem("question", res.id);
                    }
                }).catch(() => {
                    toast.error("Xatolik!")
                })
        }
    }, [file, title, type]);


    return (
        <>
            <img src={leftArrow} alt="" onClick={() => setPage(prev => prev - 1)} height={50} width={50} style={{ marginLeft: "20px" }}/>
            <div className="photo-three">
                <div className="photo-wrapper-three">
                    <div className="header-seelct">
                        <div className="question-btns">
                            <div className={`question-btns__text ${type === "text" && "active-bg"}`}
                                 onClick={() => setType("text")}>T
                            </div>
                            <div className={`question-btns__text ${type === "image" && "active-bg"}`}
                                 onClick={() => setType("image")}>
                                <img src={imgIcon} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="photo-items-three">
                        {type === "image" ? <div className="file-3">
                            {image ? <img src={image} alt="" className="image-main-full"/> :
                                <>
                                    <div className="question-add">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            accept=".jpg , .png"
                                            onChange={(e) => {
                                                getImage(e.target.files[0]);
                                                setFile(e.target.files[0])
                                            }
                                            }
                                        />
                                        <label htmlFor="image">
                                            <img src={plus} alt=""/>
                                        </label>
                                    </div>
                                    < span> Rasmni yuklang</span>
                                </>
                            }
                        </div> : type === "text" ?
                            <div className="text-3">
                                <textarea onChange={(e) => setTitle(e.target.value)} name="" id="" cols="30" rows="10"
                                          placeholder="Savolni kiriting"
                                          className="textarea-answer-2"/>
                            </div> : null}
                    </div>
                    {type.length > 0 && <div className="div-btn">
                        <button className="bnt-answers" onClick={createAnswers}
                                disabled={type === "image" ? !file : !title}>Javoblar qo'shish
                        </button>
                    </div>}
                </div>
            </div>
        </>
    );
};

export default Photo3;

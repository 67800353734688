import React from 'react';
import markIcon from '../../images/mark-icon.svg';
import './page4.css';
const Page4 = () => {
	return (
		<>
			<div className='add-question'>
				<div className='add-question'>
					<div className='add-question-btns'>
						<div className='btn'>
							<b className='boold-btn'>B</b>
						</div>
						<div className='btn'>
							<em className='em-btn'>I</em>
						</div>
						<div className='btn'>
							<u className='u-btn'>U</u>
						</div>
						<div className='btn mark-btn'>
							<img src={markIcon} alt='' />
						</div>
					</div>
					<div className='add-text'>
						<h2 className='add-text__title'>Savol matnini kiriting</h2>
						<textarea name='' id='' cols='30' rows='10' placeholder='Type your question ...' className='add-text__input' />
						<button className='add-text__btn'>Variant yuklash</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Page4;

import { Alert, Skeleton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

export default function GroupsTable({ students = [], loading = false }) {
	if (loading) {
		return <Skeleton variant='rectangular' animation='wave' width={1000} height={400} />;
	}

	return (
		<TableContainer component={Paper} sx={{ minWidth: 650, width: 1000 }}>
			<Table aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell align='center' width={60}>
							T/R
						</TableCell>
						<TableCell>Ism</TableCell>
						<TableCell>Familiya</TableCell>
						<TableCell align='right'>Telefon</TableCell>
						<TableCell align='right'>Guruh</TableCell>
						<TableCell align='right'>Course (Kurs)</TableCell>
						<TableCell align='right'>Oxirgi kirgan</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{students.length !== 0 ? (
						students.map((row, i) => (
							<TableRow key={row.id}>
								<TableCell>{i + 1}</TableCell>
								<TableCell component='th' scope='row'>
									{row.first_name}
								</TableCell>
								<TableCell>{row.last_name}</TableCell>
								<TableCell align='right'>{row.phone}</TableCell>
								<TableCell align='right'>{row.group.name}</TableCell>
								<TableCell align='right'>{row.kurs.name}</TableCell>
								<TableCell align='right'>{row.last_sean}</TableCell>
							</TableRow>
						))
					) : (
						<Alert sx={{ margin: '100px' }} severity='info'>
							No Data !
						</Alert>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import './assets/SignUpPage.css';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SignUp1() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');
  const [switchUser, setSwitchUser] = useState(
    JSON.parse(localStorage.getItem('switch'))
  );
  const history = useHistory();
  const submitHandlerSpeaker = useCallback(() => {
    if (phone === '') {
      toast.error('Telefon raqamni kiriting', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoading(true);
      let phoneNumber = phone.replace(/\s/g, '');
      phoneNumber =
        phoneNumber.length === 9
          ? '998' + phoneNumber
          : phoneNumber.includes('+')
          ? phoneNumber.replace('+', '')
          : phoneNumber;
      phoneNumber = phoneNumber.substr(0, 25);
      let form = new FormData();
      form.append('phone_number', phoneNumber);
      localStorage.setItem('phoneSpeaker', phoneNumber);
      axios
        .post('https://inpuls-backend.backoffice.uz/send_code', form)
        .then((r) => {
          if (r.data.status === true) {
            setLoading(false);
            history.push('/registration/code');
          } else if (r.data.status === false) {
            setLoading(false);
            toast.error(
              "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(
            "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    }
  }, [history, phone, switchUser]);
  const submitHandlerSpeakerEmail = useCallback(() => {
    if (phone === '') {
      toast.error('Telefon raqamni kiriting', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoading(true);
      let form = new FormData();
      form.append('phone_number', phone);
      localStorage.setItem('phoneSpeaker', phone);
      axios
        .post('https://inpuls-backend.backoffice.uz/send_code', form)
        .then((r) => {
          if (r.data.status === true) {
            setLoading(false);
            history.replace('/registration/code');
          } else if (r.data.status === false) {
            setLoading(false);
            toast.error(
              "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(
            "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    }
  }, [history, phone, switchUser]);
  useEffect(() => {
    localStorage.setItem('switch', JSON.stringify(switchUser));
  }, [switchUser]);
  return (
    <>
      <section className="sectionSignUpPage">
        <div className="container">
          <div className="mainSignUpPage">
            <div className="LoginPage-inner">
              <div className="signin-courses_sec_btn  login-courses_sec_btn-switch">
                <h2
                  className={
                    switchUser ? 'course_btn_active' : 'course_btn_pass'
                  }
                >
                  {' '}
                  Telefon orqali
                </h2>
                <h3
                  className={`${
                    switchUser ? 'course_btn_active' : 'course_btn_pass'
                  } Bilim-oluvchi`}
                >
                  Telefon
                </h3>
                <button
                  onClick={() => {
                    setSwitchUser(!switchUser);
                  }}
                  className={switchUser ? 'course_btn_1' : 'course_btn_2'}
                  type="button"
                >
                  <div></div>
                </button>
                <h2
                  className={
                    switchUser ? 'course_btn_pass' : 'course_btn_active'
                  }
                >
                  {' '}
                  Email orqali
                </h2>
                <h3
                  className={`${
                    switchUser ? 'course_btn_pass' : 'course_btn_active'
                  } Bilim-oluvchi`}
                >
                  Email
                </h3>
              </div>
            </div>
            <div className="signUpPage-input-item">
              {switchUser ? (
                <input
                  className="signUpPage-inner-item"
                  placeholder="Tel.raqamni kiriting"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              ) : (
                <input
                  className="signUpPage-inner-item email-input"
                  type="email"
                  placeholder="Emailni kiriting"
                  onChange={(e) => setPhone(e.target.value)}
                />
              )}
              {/*{error && <div className="error-text">*/}
              {/*    {error}*/}
              {/*</div>}*/}
                <button
                    className="signUpPage-btn-inner"
                    type="submit"
                    onClick={
                    switchUser ? submitHandlerSpeaker : submitHandlerSpeakerEmail
                    }
                >
                    {loading ? (
                    <div className="block-loading">
                        <p className="mt-2">Iltimos kuting...</p>
                    </div>
                    ) : error ? (
                    <span>Qaytadan kiritish</span>
                    ) : (
                    <span>Davom etish</span>
                    )}
                </button>
              <p className="signUpPage-text-item">
                Akkauntingiz bormi? unda <Link to={'/login'}>bu yerga</Link>{' '}
                bosing
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';
import { api } from "../../api/api";
import { setRefreshToken, setToken, setUser } from "../../store/main/mainActions";
import Hide from "../images/Hide.svg";
import View from "../images/View.svg";
import Logo from "../images/logoNew.svg";
import Loader from "../ui/Loader";
import "./LoginPage.css";


export default function LoginPage() {
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const history = useHistory();
    const [type, setType] = useState(true);
    const [switchUser, setSwitchUser] = useState(true);
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        api.get("v1/companies/90b3d7bd-cf38-4b7f-8021-399f7d2033cf/orders?query_by=&q=&page=1&perPage=10").then(res => {
        })
    }, []);
    const [data, setData] = useState({
        phone: "",
        password: ""
    });
    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };
    useEffect(() => {
        if(JSON.parse(localStorage.getItem('switch')) !== null) {
            setSwitchUser(JSON.parse(localStorage.getItem("switch")))
        }
    }, []);
    

    const submitHandlerSpeaker = useCallback((e) => {
        e.preventDefault();
        setLoading(true);
        let phoneNumber = data.phone;
        phoneNumber = phoneNumber.length === 9 ? "998" + phoneNumber : phoneNumber.includes("+") ? phoneNumber.replace("+", "") : phoneNumber;
        phoneNumber = phoneNumber.substr(0, 25);
        const form = new FormData();
        form.append("phone", phoneNumber);
        form.append("password", data.password);
        axios.post("https://inpuls-backend.backoffice.uz/login", form)
            .then((res) => {
                    if (res.data.success === true) {
                        setLoading(false);
                        cookies.set("access_token", res.data.data.token.access, { path: '/' });
                        dispatch(setToken(res.data.data.token.access));
                        cookies.set('user', res.data.data.speaker);
                        dispatch(setUser(res.data.data.speaker));
                        cookies.set('refreshToken', res.data.data.token.refresh, { path: '/' });
                        dispatch(setRefreshToken(res.data.data.token.refresh));
                        history.push("/");
                    }
                    else {
                        setLoading(false);
                        toast.error("Telefon raqam yoki parol xato", {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                }
                )
                .catch(err=>{setLoading(false);
                });
    }, [data, dispatch, cookies, history]);
    const submitHandlerSpeakerEmail = useCallback((e) => {
        e.preventDefault();
        setLoading(true);
        const form = new FormData();
        form.append("email", phone);
        form.append("password", data.password);
        axios.post("https://inpuls-backend.backoffice.uz/login", form)
            .then((res) => {
                    if (res.data.success === true) {
                        setLoading(false);
                        cookies.set("access_token", res.data.data.token.access, { path: '/' });
                        dispatch(setToken(res.data.data.token.access));
                        cookies.set('user', res.data.data.speaker);
                        dispatch(setUser(res.data.data.speaker));
                        cookies.set('refreshToken', res.data.data.token.refresh, { path: '/' });
                        dispatch(setRefreshToken(res.data.data.token.refresh));
                        history.push("/");
                    }
                    else {
                        setLoading(false);
                        toast.error("Telefon raqam yoki parol xato", {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                }
            )
    }, [data, dispatch, cookies, history, phone]);
    return (
        <>
            <section className="sectionLoginPage">
                <div className="header">
                    <div className="logo-header"> <img src={Logo} alt=""/></div>
                    <h2 className="course_btn_title">SPIKERLAR KIRISHI</h2>
                    <a className="header-link" style={{ opacity: 0 }} href="https://eduon.uz/auth/login"><div className="btn-header-user">Bilim oluvchi</div></a>
                </div>
                <div className="container">
                    {loading && <Loader />}
                    {!loading && <div className="mainLoginPage">
                        <div className="LoginPage-inner">
                            <div className="login-courses_sec_btn ">
                                <div className="LoginPage-inner">
                                        <div className="signin-courses_sec_btn  login-courses_sec_btn-switch">
                                            <h2 className={switchUser ? 'course_btn_active' : 'course_btn_pass'}> Telefon orqali</h2>
                                            <h3 className={`${switchUser ? 'course_btn_active' : 'course_btn_pass'} Bilim-oluvchi`}>Telefon</h3>
                                            <button onClick={() => {
                                                setSwitchUser(prev => !prev); 
                                            }}
                                                    className={switchUser ? 'course_btn_1' : 'course_btn_2'}
                                                    type="button">
                                                <div></div>
                                            </button>
                                            <h2 className={switchUser ? 'course_btn_pass' : 'course_btn_active'}>  Email orqali</h2>
                                            <h3 className={`${switchUser ? 'course_btn_pass' : 'course_btn_active'} Bilim-oluvchi`}>Email</h3>
                                        </div>
                                    </div>

                            </div>
                        </div>
                        <div className="loginPage-input-item">
                            { switchUser ?
                            <PhoneInput
                                containerClass="loginPage-inner-item phone-flag"
                                country={'uz'}
                                value={data.login}
                                type="text"
                                masks={{uz: '(..) ...-..-..'}}
                                id="login"
                                required
                                autoComplete="false"
                                onChange={e => setData({...data , phone: e})}
                            />:
                            <input
                            className="loginPage-inner-item email-input"
                            type="email"
                            placeholder="Emailni kiriting"
                            onChange={(e) => setPhone(e.target.value)}
                        />}
                            <div className="parol-input">
                            <input
                                className="loginPage-inner-item"
                                type={`${type ? "password" : "text"}`}
                                placeholder="Parolni kiriting"
                                onChange={(e) => handleChange("password", e.target.value)}
                            />
                       
                                    <span className="loginPage-inner-icon"
                                          onClick={() => setType(!type)}>
                                        <img src={`${type ? Hide : View}`} alt=""/>
                                    </span>
            
                            </div>
                           
                            <p className="loginPage-text-item" onClick={() => history.replace("/reset-password/")} style={{cursor: "pointer"}}>
                                Parolingizni unutdingizmi?
                            </p>
                            <div className="button-box">
                                <button className="loginPage-btn-inner" type="submit" 
                                onClick={() => {history.replace("/registration/");localStorage.setItem("switch", JSON.stringify(switchUser));
                            }}>Ro'yxatdan o'tish
                                </button>
                                <button className="loginPage-btn-inner-kirish" type="submit" onClick={switchUser ? 
                                submitHandlerSpeaker : submitHandlerSpeakerEmail}>Tizimga kirish
                                </button>
                            </div>

                        </div>
                        <div className="loginPage-btn-item" />
                    </div>}
                </div>
            </section>
        </>
    );
}

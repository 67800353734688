import React, {useState} from 'react';
import NavBar from '../../../Navbar';
import './photo1.css';
import plus from "../../../../images/plus.svg";
import {api} from "../../../../../api/api";
import leftArrow from "../../../../../images/left-arrow.svg";
import {toast} from "react-toastify";

const Photo1 = ({setPage, quizType}) => {
    const [image, setImage] = useState("");
    const [file, setFile] = useState();
    const [title, setTitle] = useState("");

    const getImage = (images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setImage(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
        })
    };

    const createAnswers = () => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("is_active", true);
        formData.append("img1", file);
        formData.append("structure", quizType);
        formData.append("quiz", window.location.pathname.split("/").at(-1));
        api.post("quizzes/question/create/", formData).then(res => {
            if (res) {
                setPage(4);
                toast.success("Savol yuklandi!");
                window.localStorage.setItem("question", res.id);
            }
        }).catch(() => {
            toast.error("Xatolik!")
        })
    };
    return (
        <>
            <img src={leftArrow} alt="" onClick={() => setPage(prev => prev - 1)} height={50} width={50} style={{ marginLeft: "20px" }}/>
            <div className="photo-one">
                <div className="photo-wrapper-one">
                    <div className="photo-items-one">
                        <div className="bordered-photo-1">
                            <div className="bordered-photo-item">
                                {image ? <img src={image} alt="" width={200} height={100} className="image-main-full"/>
                                    : <>
                                        <div className="question-add">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                accept=".jpg , .png"
                                                onChange={(e) => {
                                                    getImage(e.target.files[0]);
                                                    setFile(e.target.files[0])
                                                }
                                                }
                                            />
                                            <label htmlFor="image">
                                                <img src={plus} alt=""/>
                                            </label>
                                        </div>
                                        <span>Rasmni yuklang</span> </>}
                            </div>
                        </div>
                        <textarea name="" id="" placeholder="savolni yozing"
                                  onChange={(e) => setTitle(e.target.value)}/>
                        <button className="bnt-answers" disabled={title.length < 1} onClick={createAnswers}>Javoblar qo'shish</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Photo1;

import React from "react";

export default function MainContainer(props){

    if(props.isAuthed) return props.children;
    return(
        <div >
            {props.children}
        </div>
    )
}
import React from "react";
import {NavLink, Route, Switch, BrowserRouter, Redirect} from "react-router-dom";
import "./profile.css";
import ProfPassword from "./profpassword";
import ProfInfo from "./profinfo";
import ProfCard from "./profcard";

export default function Profile(props) {
    return (
      <React.Fragment>
        <BrowserRouter>
          <div className="main-content-for-profile">
            <Redirect to="/main/profile/profinfo" from="/main/profile"/>
            <div className="navbar-of-main-content-for-profile">
              <NavLink
                exact
                to="/main/profile/profinfo/"
                activeClassName="selected-for-prof-info"
              >
                Ma’lumotlar
              </NavLink>
              <NavLink to="/main/profile/profcard" activeClassName="selected-for-prof-info">
                Karta
              </NavLink>
              <NavLink
                to="/main/profile/profpassword"
                activeClassName="selected-for-prof-info"
              >
                Parol
              </NavLink>
            </div>
            <div className="main-content-div-for-profile-wrapper">
              <Switch>
                <Route exact path="/main/profile/profinfo">
                  <ProfInfo data={props.speakerData} cookies={props.cookies} profile={props.checkProfileInfo}/>
                </Route>
                <Route path="/main/profile/profcard">
                  <ProfCard checkCardInfo={props.checkCardInfo}/>
                </Route>
                <Route path="/main/profile/profpassword">
                  <ProfPassword />
                </Route>
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }

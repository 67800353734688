import React, { useEffect, useState } from 'react';
import { api } from '../../../api/api';
import Table from '../Table';
import { default as Select, default as Selects } from '../select';
import './Test.css';

const Test = () => {
	const [faculty, setFaculty] = useState([]);
	const [course, setCourse] = useState([]);
	const [group, setGroup] = useState([]);
	const [kurs, setKurs] = useState([]);
	const [theme, setTheme] = useState([]);
	const [test, setTest] = useState([]);

	const [facultyId, setFacultuId] = useState('');
	const [courseId, setCourseId] = useState('');
	const [groupId, setGroupId] = useState('');
	const [kursId, setKursId] = useState('');
	const [themeId, setThemeId] = useState('');
	const [testId, setTestId] = useState('');

	const [data, setData] = useState([]);

	useEffect(() => {
		api.get('/faculty/').then(res => setFaculty(res));
	}, []);

	useEffect(() => {
		if (facultyId) {
			api.get(`/university/course?q=${facultyId}`).then(res => setCourse(res));
		}
	}, [facultyId]);

	useEffect(() => {
		if (courseId) {
			api.get(`/group?q=${courseId}`).then(res => setGroup(res));
		}
	}, [courseId]);

	useEffect(() => {
		if (groupId) {
			api.get(`/api-web/get-course/?kurs=${groupId}`).then(res => setKurs(res.data));
		}
	}, [groupId]);

	useEffect(() => {
		if (kursId) {
			api.get(`/api-web/coursemodule/?subject=${kursId}`).then(res => {
				return setTheme(res);
			});
		}
	}, [kursId]);

	useEffect(() => {
		if (themeId) {
			api.get(`/quizzes/shortquiz/list/?modul=${themeId}`).then(res => {
				setTest(res);
			});
		}
	}, [themeId]);

	useEffect(() => {
		if (facultyId && courseId && groupId && kursId && themeId && testId) {
			api.get(
				`/quizzes/quizzes/get/result/?faculty=${facultyId}&group=${groupId}&course=${courseId}&kurs=${kursId}&theme=${themeId}&quiz=${testId}`
			).then(res => {
				setData(res);
			});
		}
	}, [facultyId, courseId, groupId, kursId, themeId, testId]);

	return (
		<>
			<main className='filter-page'>
				<div className='container-filter'>
					<div className='filter-page-selects'>
						<Select
							selectTitle={'Fakultetni tanlash'}
							options={faculty}
							setFacultyId={setFacultuId}
						/>
						<Selects
							selectTitle={'Kurs tanlash'}
							options={course}
							setFacultyId={setCourseId}
						/>
						<Select
							selectTitle={'Guruhni tanlash'}
							options={group}
							setFacultyId={setGroupId}
						/>
						<Select
							selectTitle={'Fanni tanlash'}
							options={kurs}
							setFacultyId={setKursId}
						/>
						<Select
							selectTitle={'Mavzuni tanlash'}
							options={theme}
							setFacultyId={setThemeId}
						/>
						<Select
							selectTitle={'Testni tanlash'}
							options={test}
							setFacultyId={setTestId}
						/>
					</div>
					<Table test={data} />
				</div>
			</main>
		</>
	);
};

export default Test;

import React, {useCallback, useEffect, useState} from 'react';
import "../../login/ParolUnut_2.css";
import {useHistory} from "react-router-dom";
import {api} from "../../../api/api"
import Logo from "../../images/Logo1.svg";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";
import Loader from "../../ui/Loader";

export default function ParolUnut_2() {
    const history = useHistory();
    const cookies = new Cookies();
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const [phone, setPhone] = useState("");
    useEffect(() => (
        setPhone(window.localStorage.getItem("phoneSpeaker"))
    ), []);
    const submitHandler = useCallback(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append("code", code);
        formData.append("phone_number", phone);
        api.post("reset-password", formData).then((res) => {
                if (res.success === true) {
                    setLoading(false);
                    history.replace("/reset-password/change-password");
                    cookies.set("token", res.data.token.access, {path: "/"});
                } else {
                    setLoading(false);
                    toast.error("Kod notugri, iltimos tekshirib koring", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            }
        ).catch(() => {
            setLoading(false);
            toast.error("Kod notugri, iltimos tekshirib koring", {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }, [phone, history, code]);
    return (
        <div className="ParolUnut_2_block _main">
            <div className='container'>
                <div className="forget-password-wrapper">
                    <div className="LoginPage-inner">
                        <div className="login-courses_sec_btn ">
                            <img src={Logo} alt="" onClick={() => history.replace("/login")}/>
                        </div>
                    </div>
                    <div className="ParolUnut_2">
                        <p className="text-for2">Kodni kiriting</p>
                        <div className="ParolUnut_1_block_input">
                            <input type='text' name="code" onChange={(e) => setCode(e.target.value)}/>
                        </div>
                        <div className='ParolUnut_1_tasdiq'>
                            <p onClick={() => history.replace("/reset-password/")}>orqaga</p>
                            <button type="submit" onClick={submitHandler}>{loading ?
                                <span className="loader-login"><Loader/></span> : "Tasdiqlash"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
import React from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import "./eduoncourses.css";
import Comments from "../../images/comment.svg";
import Star from "../../images/Star 2.svg";
import Sold from "../../images/Bag.svg";
import Confirm from "./Tasdiq.svg";
import NotConfirm from "./NoTasdiq.svg";
import SkeletonLoading from "../ui/SkeletonLoading";

const data = require("../../json/eduoncourses.json");
const newData = require("../../json/newcourseedu.json");

export default function EduonCourses(props) {
  const history = useHistory();
  return (
    <React.Fragment>
      <BrowserRouter>
        <div className="main-content-for-eduon-courses">
          <p className="uploaded-courses-div-paragraph-element">
            Yuklangan kurslar
          </p>
          <div className="uploaded-courses-from-teacher">
            {!props.loading &&
              props.speakerCourses?.courses?.map((x) => {
                return (
                  <div className="curses-div-from-teacher" key={x.id}>
                    <div
                      onClick={() => {
                        history.replace(`/main/courses/barchasi/${x?.id}`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="img-div-course-div">
                        <img
                          src={`https://inpuls-backend.backoffice.uz/media/${x.image}`}
                          alt=""
                        />
                        <div className="img-status">
                          <img
                            src={
                              x?.is_confirmed === true ? Confirm : NotConfirm
                            }
                            alt=""
                            className="confirm-img status_img"
                          />
                        </div>
                      </div>
                      <div className="course-title-from-courses-div">
                        <span style={{ overflowWrap: "break-word" }}>{x.name}</span>
                      </div>
                      <div className="course-price-rating-comment-number-show-div">
                        <span className="course-rating-of-teacher">
                          <div>
                            <img src={Star} alt="" />
                          </div>
                          {x.course_rank.count}
                          <span> ({x.course_rank.rank})</span>
                        </span>
                        <span className="course-sold-indicator">
                          <div>
                            <img src={Sold} alt="" />
                          </div>
                          {x.sell_count}
                        </span>
                        <span className="course-comments-from-students">
                          <div>
                            <img src={Comments} alt="" />
                          </div>
                          {data.comments}
                        </span>
                      </div>
                    </div>
                    <div className="course-price-and-editing-button-show-div">
                      <span>
                        {x.price} <span> </span>
                        <span className="inside-span-sum">so`m</span>
                      </span>
                      <button
                        // alert(
                        //   "Bu bo'limda texnik ishlar olib borilmoqda ,iltimos bir ozdan so'ng urinib ko'ring"
                        // );
                        onClick={(e) => {
                          e.preventDefault();
                          props.setCourseId(x.id);
                          localStorage.setItem("editVideo", "1");
                          history.replace({
                            pathname: "/main/add-to-course",
                            state: {
                              id: x.id,
                            },
                          });
                        }}
                      >
                        Tahrirlash
                      </button>
                    </div>
                  </div>
                );
              })}
            <div
              className="curses-div-from-teacher_plus"
              // alert(
              //   "Bu bo'limda texnik ishlar olib borilmoqda ,iltimos bir ozdan so'ng urinib ko'ring"
              // )
              onClick={() => {
                props.checkProfileInfo
                  ? history.replace("/main/add-to-course")
                  : history.replace("/main/profile");
              }}
            >
              <span>+</span>
              <h3>Kurs yuklash</h3>
            </div>
            {props.loading && (
              <>
                <SkeletonLoading />
                <SkeletonLoading classname="hidden-in-480" />
                <SkeletonLoading classname="hidden-in-768" />
                <SkeletonLoading classname="hidden-in-1280" />
              </>
            )}
          </div>
          {/* <p className="uploaded-courses-div-paragraph-element2">
                        Yangi kurs
                    </p>
                    <div className="new-courses-from-teacher">
                        {newData?.map((newData) => {
                            return (
                                <div className="new-course-div-from-teacher">
                                    <div className="top-new-course-div">
                                        <img src={CRM} alt=""/>
                                    </div>
                                    <div className="title-content-of-new-course-div">
                                        <div>
                                            <p>{newData.title}</p>
                                        </div>
                                        <div className="title-content-of-new-course-div-button-div">
                                            <button>Davom ettirish</button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div> */}
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
}

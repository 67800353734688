import React from 'react';
import { Redirect } from 'react-router-dom';
import ParolUnut_1 from '../ForgotPasswordPages/ParolUnut_1/ParolUnut_1';
import ParolUnut_2 from '../ForgotPasswordPages/ParolUnut_2/ParolUnut_2';
import ParolUnut_3 from '../ForgotPasswordPages/ParolUnut_3/ParolUnut_3';
import LoginPage from '../login/LoginPage';
import MainPage from '../main';
import SignUp1 from '../registration/SignUp1';
import SignUp2 from '../registration/SignUp2';
import SignUp3 from '../registration/SignUp3';
import Page2 from '../test/page2';
import Photo1 from '../test/page2/components/photo1';
import Photo2 from '../test/page2/components/photo2';
import Photo3 from '../test/page2/components/photo3';
import Photo4 from '../test/page2/components/photo4';
import Page3 from '../test/page3';
import Page4 from '../test/page4';
import Page5 from '../test/page5';
import Page6 from '../test/page6';
import Page7 from '../test/page7';
import Page8 from '../test/page8';

export const routes = [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to='/main' />,
	},
	{
		path: '/login',
		component: () => <LoginPage />,
	},
	{
		path: '/reset-password/',
		exact: true,
		component: () => <ParolUnut_1 />,
	},
	{
		path: '/reset-password/verify',
		component: () => <ParolUnut_2 />,
	},
	{
		path: '/reset-password/change-password',
		component: () => <ParolUnut_3 />,
	},
	{
		path: '/registration/',
		exact: true,
		component: () => <SignUp1 />,
	},
	{
		path: '/registration/code',
		component: () => <SignUp2 />,
	},
	{
		path: '/registration/full_reg',
		component: () => <SignUp3 />,
	},
	{
		path: '/main',
		component: () => <Redirect to='/main/statistics' />,
		exact: true,
	},
	{
		path: '/main/statistics',
		exact: true,
		component: () => <MainPage />,
	},
	{
		path: '/main/my-groups',
		component: () => <MainPage />,
	},
	{
		path: '/main/courses/barchasi',
		component: () => <MainPage />,
	},
	{
		path: '/main/courses/tahrirlash',
		component: () => <MainPage />,
	},
	{
		path: '/main/profile',
		component: () => <MainPage />,
	},
	{
		path: '/main/info',
		component: () => <MainPage />,
	},
	{
		path: '/main/add-to-course',
		component: () => <MainPage />,
	},
	{
		path: '/main/chat',
		component: () => <MainPage />,
	},
	{
		path: '/main/test',
		component: () => <MainPage />,
	},
	{
		path: '/main/groups',
		component: () => <MainPage />,
	},
	{
		path: '/main/course',
		component: () => <MainPage />,
	},
	{
		path: '/main/page2',
		component: () => <Page2 />,
	},
	{
		path: '/main/photo1',
		component: () => <Photo1 />,
	},
	{
		path: '/main/photo1',
		component: () => <Photo1 />,
	},
	{
		path: '/main/photo2',
		component: () => <Photo2 />,
	},
	{
		path: '/main/photo3',
		component: () => <Photo3 />,
	},
	{
		path: '/main/photo4',
		component: () => <Photo4 />,
	},
	{
		path: '/main/page3',
		component: () => <Page3 />,
	},
	{
		path: '/main/page4',
		component: () => <Page4 />,
	},
	{
		path: '/main/page5',
		component: () => <Page5 />,
	},
	{
		path: '/main/page6',
		component: () => <Page6 />,
	},
	{
		path: '/main/page7',
		component: () => <Page7 />,
	},
	{
		path: '/main/page8',
		component: () => <Page8 />,
	},
];

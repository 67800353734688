import React, { useCallback, useEffect, useState } from "react";
import Plus from "../../images/Paper Plus.svg";
import Camera from "../../images/Camera.png";
import { useSelector } from "react-redux";
import { api, API_VERSION } from "../../api/api";
import moment from "moment";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import Cookies from "universal-cookie";

export default function ProfInfo(props) {
  const [edit, setEdit] = useState(false);
  const speakerData = props.cookies.get("speaker");
  const [speakerDate, setSpeakerDate] = useState([]);
  const cookies = new Cookies();
  const [data, setData] = useState({
    phone: speakerData?.other?.phone ? speakerData?.other?.phone : "",
    first_name: speakerData?.speaker?.first_name
      ? speakerData?.speaker?.first_name
      : "",
    last_name: speakerData?.speaker?.last_name
      ? speakerData?.speaker?.last_name
      : "",
    gender: speakerData?.other?.gender ? speakerData?.other?.gender : "",
    job: speakerData?.other?.kasbi ? speakerData?.other?.kasbi : "",
    company: speakerData?.other?.compony ? speakerData?.other?.compony : "",
    both_day: speakerData?.other?.both_date
      ? speakerData?.other?.both_date
      : "",
    country: speakerData?.other?.country ? speakerData?.other?.country : "",
    city: speakerData?.other?.city ? speakerData?.other?.city : "",
    email: speakerData?.speaker?.email ? speakerData?.speaker?.email : "",
    image: speakerData?.other?.image ? speakerData?.other?.image : null,
    logo: speakerData?.other?.logo ? speakerData?.other?.logo : null,
  });


  const [country, setCountry] = useState([]);
  const [regions, setRegions] = useState([]);

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const submitHandler = useCallback(() => {
    let phoneNumber = data.phone;
    phoneNumber =
      phoneNumber.length === 9
        ? "998" + phoneNumber
        : phoneNumber.includes("+")
        ? phoneNumber.replace("+", "")
        : phoneNumber;
    phoneNumber = phoneNumber.substr(0, 25);

    let form = new FormData();
    form.append("phone", phoneNumber);
    form.append("first_name", data.first_name);
    form.append("last_name", data.last_name);
    form.append("gender", data.gender);
    form.append("job", data.job);
    form.append("company", data.company);
    form.append("both_day", data.both_day);
    form.append("country", data.country);
    form.append("region", data.city);
    form.append("email", data.email);
    data.logo !== "" && form.append("logo", data.logo);
    data.image !== "" && form.append("image", data.image);

    if (
      phoneNumber &&
      data.first_name !== "" &&
      data.last_name !== "" &&
      data?.gender !== "" &&
      data?.job !== "" &&
      data?.company !== "" &&
      data?.both_day !== "" &&
      data?.country !== "" &&
      data?.city !== "" &&
      data?.email !== ""
    ) {
      api
        .post("update-speaker", form)
        .then((res) => {
          if (res.id !== null) {
            toast.success("Malumotlar muavfaqiyatli qushildi!!!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .then(() => {
          api.get("speaker").then((res) => {
            setSpeakerDate(res.speaker_data);
          });
        })
        .catch(() => {
          toast.error("Xatolik, Qaytadan urinib koring", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      // .finally(() => window.location.reload());
    } else {
      toast.error("Barcha maydonlar tuldirilishi shart!!!");
    }
  }, [data]);

  useEffect(() => {
    api.get(`${API_VERSION}get-countries/`).then((res) => setCountry(res.data));
    api.get(`${API_VERSION}get-regions/`).then((res) => setRegions(res.data));
  }, []);

  useEffect(() => {
    let mounted = true;
    api.get("speaker").then((res) => {
      if (mounted) {
        setSpeakerDate(res.speaker_data);
        cookies.set("speaker", res.speaker_data, { path: "/" });
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!props.profile) {
      toast.warning(
        "Kurs yuklash uchun , barcha malumotlar to'ldirilishi shart",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [props.profile]);

  return (
    <React.Fragment>
      <div className="main-content-for-prof-info-wrapper-div">
        <div className="top-info-for-profile-div">
          <div className="left-top-info-for-profile-div">
            <div
              className="profile-picture-for-profinfo"
              style={{
                backgroundImage: `url(https://inpuls-backend.backoffice.uz${speakerDate?.other?.image})`,
              }}
            >
              <input
                type="file"
                className="display-hidden"
                id="phone-speaker"
                onChange={(e) => handleChange("image", e.target.files[0])}
              />
              <label htmlFor="phone-speaker">
                <img src={Camera} alt="plus" />
              </label>
            </div>
            {/*<div className="edit-btn">*/}
            {/*    <button type="submit"*/}
            {/*            onClick={() => setEdit(prev => !prev)}>{edit ? "Bekor qilish" : "Tahrirlash"}</button>*/}
            {/*</div>*/}
          </div>
          <div className="right-top-info-for-profile-div">
            <div className="middle-main-profinfo-for-profinfo-div">
              <div className="info-divs-inside-inputs-and-titles">
                <p>Ism {edit && <span className="required-input">*</span>}</p>
                <input
                  type="text"
                  defaultValue={speakerDate?.speaker?.first_name}
                  className="right-top-profile-div-inputs"
                  onChange={(e) => handleChange("first_name", e.target.value)}
                />
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Telefon raqam{" "}
                  {edit && <span className="required-input">*</span>}
                </p>
                <input
                  type="text"
                  defaultValue={
                    speakerDate?.other?.phone
                      ? `+${speakerDate?.other?.phone}`
                      : ""
                  }
                  placeholder="tel numer ni kiring"
                  className="right-top-profile-div-inputs"
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Sharif {edit && <span className="required-input">*</span>}
                </p>
                <input
                  type="text"
                  defaultValue={speakerDate?.speaker?.last_name}
                  className="right-top-profile-div-inputs"
                  onChange={(e) => handleChange("last_name", e.target.value)}
                />
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Electron pochta{" "}
                  {edit && <span className="required-input">*</span>}
                </p>
                <input
                  type="text"
                  defaultValue={speakerDate?.speaker?.email}
                  className="right-top-profile-div-inputs "
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
            </div>
            {/*<div className="right-top-info-top-part-of-profinfo-mobile-responsive">*/}
            {/*    <div className="top-right-top-info-top-part-of-profinfo-mobile-responsive">*/}
            {/*        <div*/}
            {/*            className="left-top-right-top-info-top-part-of-profinfo-mobile-responsive display-hidden">*/}
            {/*            <div className="profile-picture-for-profinfo" style={{*/}
            {/*                backgroundImage: `url(https://backend.eduon.uz${speakerData?.other.image})`*/}
            {/*            }}>*/}
            {/*                <input type="file" className="display-hidden" id="phone-speaker"*/}
            {/*                       onChange={(e) => handleChange("image", e.target.files[0])}/>*/}
            {/*                <label htmlFor="phone-speaker">*/}
            {/*                    <img src={Camera} alt="plus"/>*/}
            {/*                </label>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="right-top-right-top-info-top-part-of-profinfo-mobile-responsive">*/}
            {/*            {edit ?*/}
            {/*                <input*/}
            {/*                    type="text"*/}
            {/*                    defaultValue={speakerData?.speaker?.first_name}*/}
            {/*                    onChange={(e) => handleChange("first_name", e.target.value)}*/}
            {/*                    className="right-top-profile-div-inputs right-top-profile-div-inputs-mobile-responsive"*/}
            {/*                /> :*/}
            {/*                <input*/}
            {/*                    type="text"*/}
            {/*                    value={speakerDate?.speaker?.first_name}*/}
            {/*                    disabled*/}
            {/*                    className="right-top-profile-div-inputs right-top-profile-div-inputs-mobile-responsive"*/}
            {/*                />*/}
            {/*            }*/}
            {/*            {edit ?*/}
            {/*                <input*/}
            {/*                    type="text"*/}
            {/*                    defaultValue={speakerData?.speaker?.last_name}*/}
            {/*                    onChange={(e) => handleChange("last_name", e.target.value)}*/}
            {/*                    className="right-top-profile-div-inputs right-top-profile-div-inputs-mobile-responsive right-top-profile-div-inputs-mobile-responsive2"*/}
            {/*                />*/}
            {/*                :*/}
            {/*                <input*/}
            {/*                    type="text"*/}
            {/*                    value={speakerDate?.speaker?.last_name}*/}
            {/*                    disabled*/}
            {/*                    className="right-top-profile-div-inputs right-top-profile-div-inputs-mobile-responsive right-top-profile-div-inputs-mobile-responsive2"*/}
            {/*                />*/}
            {/*            }*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="bottom-right-top-info-top-part-of-profinfo-mobile-responsive">*/}
            {/*        {edit ? <input*/}
            {/*                type="text"*/}
            {/*                defaultValue={speakerData?.speaker?.email}*/}
            {/*                onChange={(e) => handleChange("email", e.target.value)}*/}
            {/*                className="right-top-profile-div-inputs"*/}
            {/*            /> :*/}
            {/*            <input*/}
            {/*                type="text"*/}
            {/*                value={speakerDate?.speaker?.email}*/}
            {/*                disabled*/}
            {/*                className="right-top-profile-div-inputs"*/}
            {/*            />*/}
            {/*        }*/}
            {/*        {edit ?*/}
            {/*            <input*/}
            {/*                type="text"*/}
            {/*                defaultValue={speakerDate?.other?.phone ? `+${speakerDate?.other?.phone}` : ""}*/}
            {/*                onChange={(e) => handleChange("phone", e.target.value)}*/}
            {/*                className="right-top-profile-div-inputs"*/}
            {/*            /> :*/}
            {/*            (speakerDate?.other?.phone !== undefined ?*/}
            {/*                <input*/}
            {/*                    type="text"*/}
            {/*                    value={speakerDate?.other?.phone ? `+${speakerDate?.other?.phone}` : ""}*/}
            {/*                    disabled*/}
            {/*                    className="right-top-profile-div-inputs"*/}
            {/*                    onChange={(e) => handleChange("phone", e.target.value)}*/}
            {/*                /> : <input*/}
            {/*                    type="text"*/}
            {/*                    disabled*/}
            {/*                    className="right-top-profile-div-inputs"*/}
            {/*                    onChange={(e) => handleChange("phone", e.target.value)}*/}
            {/*                />)*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="middle-main-profinfo-for-profinfo-div">
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Tug’ilgan sana{" "}
                  {edit && <span className="required-input">*</span>}
                </p>
                <InputMask
                  type="date"
                  mask="9999-99-99"
                  placeholder="YYYY-MM-DD"
                  onChange={(e) => handleChange("both_day", e.target.value)}
                  value={speakerData?.other?.both_date}
                />
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Kasb/Status{" "}
                  {edit && <span className="required-input">*</span>}
                </p>
                <input
                  type="text"
                  onChange={(e) => handleChange("job", e.target.value)}
                  defaultValue={speakerDate?.other?.kasbi}
                  placeholder="Kasbingizni kiring"
                />
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Mamlakat {edit && <span className="required-input">*</span>}
                </p>
                <select
                  className="category-select-variant-profile"
                  name="cars"
                  onChange={(e) => handleChange("country", e.target.value)}
                >
                  <option hidden selected></option>
                  {country &&
                    country?.map((x) => {
                      return (
                        <option
                          key={x.id}
                          value={x.id}
                          selected={
                            Number(speakerDate?.other?.country) === x.id
                          }
                        >
                          {x.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Viloyat {edit && <span className="required-input">*</span>}
                </p>
                <select
                  className="category-select-variant-profile"
                  name="cars"
                  onChange={(e) => handleChange("city", e.target.value)}
                >
                  <option hidden selected></option>
                  {regions &&
                    regions.map((x) => {
                      return (
                        <option
                          key={x.id}
                          value={x.id}
                          selected={Number(speakerDate?.other?.city) === x.id}
                        >
                          {x.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>Kompaniya </p>
                <input
                  type="text"
                  onChange={(e) => handleChange("company", e.target.value)}
                  defaultValue={speakerDate?.other?.compony}
                />
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>Kompaniyani logotipi</p>
                <div className="alone-input-of-profinfo-div-outside-div-block">
                  <input
                    type="text"
                    className="input-wer"
                    disabled={true}
                    value={
                      data.logo !== null ? data.logo.name || data?.logo : ""
                    }
                  />
                  <input
                    type="file"
                    id="video"
                    className="alone-input-of-profinfo-div"
                    onChange={(e) => handleChange("logo", e.target.files[0])}
                  />
                  <button>
                    <label htmlFor="video">
                      <img src={Plus} alt="" />
                    </label>
                  </button>
                </div>
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p>
                  Jisni tanlang{" "}
                  {edit && <span className="required-input">*</span>}
                </p>
                <label className="SignUpPage3_label mt-2">
                  Erkak
                  <input
                    name="radio"
                    type="radio"
                    value="Erkak"
                    defaultChecked={speakerData?.other?.gender === "Erkak"}
                    onChange={(e) => handleChange("gender", e.target.value)}
                  />
                </label>
              </div>
              <div className="info-divs-inside-inputs-and-titles">
                <p className="display-hidden">Jisni tanlang</p>
                <label className="SignUpPage3_label ">
                  Ayol
                  <input
                    name="radio"
                    type="radio"
                    value="Ayol"
                    defaultChecked={speakerData?.other?.gender === "Ayol"}
                    onChange={(e) => handleChange("gender", e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="third-middle-main-profinfo-for-profinfo-div">
              <div className="btn-thirds-middle-main">
                <button type="submit" onClick={submitHandler}>
                  Saqlash
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

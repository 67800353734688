import { useState } from 'react';
import selectIcon from '../../images/selec-icon.svg';
import './Select.css';

const Selects = ({ selectTitle, options, setFacultyId }) => {
	const [isActive, setIsActive] = useState(false);
	const [selected, setSelected] = useState(selectTitle);

	return (
		<div className='select'>
			<div className='select-btn' onClick={() => setIsActive(!isActive)}>
				<span>{selected}</span>
				<img src={selectIcon} alt='' />
			</div>
			{isActive && (
				<div className='select-container'>
					{options?.map(option => (
						<div
							key={option?.id}
							className='select-item'
							onClick={e => {
								setSelected(e.target.textContent);
								setIsActive(false);
								setFacultyId(option?.id);
							}}
						>
							{option?.name ?? option?.title}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Selects;

import React from 'react';
import imgIcon from '../../images/img-icon.svg';
import plus from '../../images/plus.svg';
import './page3.css';

const Page3 = () => {
	return (
		<>
			<div className='question'>
				<dsiv className='wrapper-question'>
					<div className='question-btns'>
						<div className='question-btns__text'>T</div>
						<div className='question-btns__text'>
							<img src={imgIcon} alt='' />
						</div>
					</div>
					<h2 className='question-title'>Matn yoki rasm kiritish uchun widgetni tanlang</h2>
					<div className='question-container'>
						<div className='question-add'>
							<img src={plus} alt='' />
						</div>
						<em className='question-container__text'>Drag widgets here</em>
					</div>
				</dsiv>
			</div>
		</>
	);
};

export default Page3;

import React, { useRef, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { useReactToPrint } from 'react-to-print';
import './Table.css';
const Table = ({ test }) => {
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'emp-data',
	});
	const [modale, setModale] = useState(false);

	return (
		<>
			<ul className={modale ? 'modale-window' : 'show'}>
				<li className='modale-window__item'>
					<ReactHTMLTableToExcel
						id='test-table-xls-button'
						className='modale-window__item'
						table='table-to-xls'
						filename='tablexls'
						sheet='tablexls'
						buttonText='Download as Excel (xsl)'
					/>
				</li>

				<li className='modale-window__item' onClick={handlePrint}>
					Download as PDF
				</li>
			</ul>
			<table id='table-to-xls' ref={componentRef}>
				<caption>
					<h2 className='export' onClick={() => setModale(!modale)}>
						Export file <span></span>
					</h2>
				</caption>
				<thead>
					<tr>
						<th className='col' scope='col'>
							Talaba ismi, familiyasi
						</th>
						<th className='col' scope='col'>
							Student ID
						</th>
						<th className='col' scope='col'>
							O’tish ballari
						</th>
						<th className='col' scope='col'>
							Talaba to’plagan ball
						</th>
						<th className='col' scope='col'>
							O’tish holati
						</th>
						<th className='col' scope='col'>
							Sarflangan vaqt
						</th>
						<th className='col' scope='col'>
							Test vaqti
						</th>
					</tr>
				</thead>
				<tbody>
					{test?.map((item, i) => {
						const {
							id,
							score,
							completed,
							user,
							answers,
							quiz,
							duration1,
							date_created,
						} = item;
						return (
							<tr key={i}>
								<td data-label='Talaba ismi, familiyasi'>
									<NavLink
										to={`/main/test/result/${id}`}
										style={{
											color: 'blue',
											cursor: 'pointer',
										}}
									>
										{user.first_name}
										{'  '}
										{user.last_name}
									</NavLink>
								</td>
								<td data-label='Student ID'>{id}</td>
								<td data-label='O’tish ballari'>
									{quiz?.duration}
								</td>
								<td data-label='Talaba to’plagan ball'>{score}</td>
								<td data-label='O’tish holati'>
									{completed ? "O'tdi" : 'Yiqildi'}
								</td>
								<td data-label='Sarflangan vaqt' className='houre'>
									{duration1}
								</td>
								<td data-label='Test vaqti' className='houre'>
									{date_created?.slice(0, 9)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default Table;

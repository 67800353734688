import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../api/api";
import "./assets/SignUpPage3.css";

const INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  password: "",
  confirmPassword: "",
};

export default function SignUp3() {
  const [phone, setPhone] = useState("");
  const [gender, setGernder] = useState("");
  const [code, setCode] = useState("");
  const [switchUser, setSwitchUser] = useState(
    JSON.parse(localStorage.getItem("switch"))
  );
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setPhone(window.localStorage.getItem("phoneSpeaker"));
    setCode(window.localStorage.getItem("code"));
  }, []);

  const setDataClick = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const history = useHistory();

  const submitHandlerSpeaker = useCallback(() => {
    if (
      data.password !== "" &&
      data.last_name !== "" &&
      data.first_name !== "" &&
      gender !== "" &&
      data.password === data.confirmPassword
    ) {
      let form = new FormData();
      form.append("phone", phone);
      form.append("first_name", data.first_name);
      form.append("last_name", data.last_name);
      form.append("password1", data.password);
      form.append("password2", data.confirmPassword);
      form.append("gender", gender);
      axios
        .post(`${API_URL}reg-full`, form)
        .then((res) => {
          if (res.data.success === true) {
            history.replace("/login");
          } else {
            toast.error(res.data.error);
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      {
        data.password !== data.confirmPassword
          ? toast.error(
              "Parollar mos kelmayapti, Iltimos tekshirib qaytadab urinib kuring",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            )
          : toast.error(
              "Barcha maydonlar tuldirilishi shart, iltimos , qaytadan urinib koring",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
      }
    }
  }, [history, phone, code, gender, data]);

  const submitHandlerSpeakerEmail = useCallback(() => {
    if (
      data.password !== "" &&
      data.last_name !== "" &&
      data.first_name !== "" &&
      gender !== "" &&
      data.password === data.confirmPassword
    ) {
      let form = new FormData();
      form.append("email", phone);
      form.append("first_name", data.first_name);
      form.append("last_name", data.last_name);
      form.append("password1", data.password);
      form.append("password2", data.confirmPassword);
      form.append("gender", gender);
      // form.append("code", code);
      axios
        .post(`${API_URL}reg-full`, form)
        .then((res) => {
          if (res.data.success === true) {
            history.replace("/login");
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      {
        data.password !== data.confirmPassword
          ? toast.error(
              "Parollar mos kelmayapti, Iltimos tekshirib qaytadab urinib kuring",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            )
          : toast.error(
              "Barcha maydonlar tuldirilishi shart, iltimos , qaytadan urinib koring",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
      }
    }
  }, [history, phone, code, gender, data]);

  return (
    <section className="sectionSignUpPage3">
      <div className="container">
        <div className="mainSignUpPage3">
          <div className="LoginPage-inner">
            {/* <div className="login-courses_sec_btn ">
              <img
                src={Logo}
                alt=""
                onClick={() => history.replace("/login")}
              />
            </div> */}
          </div>
          <div className="signUpPage-input-item3">
            <input
              className="signUpPage-inner-item3"
              placeholder="Ismingiz"
              type="text"
              name="first_name"
              onChange={(e) => setDataClick("first_name", e.target.value)}
            />
            <input
              className="signUpPage-inner-item3"
              placeholder="Familiyangiz"
              type="text"
              name="last_name"
              onChange={(e) => setDataClick("last_name", e.target.value)}
            />
            <input
              className="signUpPage-inner-item3"
              placeholder="Parol"
              type="password"
              name="password"
              onChange={(e) => setDataClick("password", e.target.value)}
            />
            <input
              className="signUpPage-inner-item3"
              placeholder="Parolni tasdiqlang"
              type="password"
              name="confirmPassword"
              onChange={(e) => setDataClick("confirmPassword", e.target.value)}
            />

            <label className="SignUpPage3_label">
              Erkak
              <input
                name="radio"
                type="radio"
                value="Erkak"
                id="radio"
                onChange={(e) => setGernder(e.target.value)}
              />
            </label>

            <label className="SignUpPage3_label">
              Ayol
              <input
                name="radio"
                type="radio"
                value="Ayol"
                id="radio"
                onChange={(e) => setGernder(e.target.value)}
              />
            </label>
          </div>
          {/*<div className="signUpPage-text-link-chak">*/}
          {/*    <Link to={Routes.Conditions}>Foydalanish shartlari </Link>*/}
          {/*    <label>*/}
          {/*        <p className="signUpPage-text-p">bilan tanishdim</p>*/}
          {/*    </label>*/}
          {/*    <div className="checkbox">*/}
          {/*        <label className="custom-checkbox" >*/}
          {/*            <input type="checkbox" name="color-1" value="indigo" onClick={() => setCheck(prevState => !prevState)}/>*/}
          {/*            <span></span>*/}
          {/*        </label>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className="singUpPage-inner-btn-item3">
            {/*{error && <div className="error-text-full-reg" >*/}
            {/*    {error}*/}
            {/*</div>}*/}
            <button
              className="signUpPage-btn-inner3"
              type="submit"
              onClick={
                switchUser ? submitHandlerSpeaker : submitHandlerSpeakerEmail
              }
            >
              Davom etish
            </button>
            <p className="signUpPage-text-item3">
              Akkauntingiz bormi? unda <Link to={"/login"}>bu yerga</Link>{" "}
              bosing
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const SelectComponent = ({ course, setCourse, label, options = [], disabled = false }) => {
	return (
		<FormControl fullWidth>
			<InputLabel id='demo-simple-select-label'>{label}</InputLabel>
			<Select labelId='demo-simple-select-label' id='demo-simple-select' disabled={disabled} label={label} value={course} onChange={e => setCourse(e.target.value)}>
				{options.map(o => (
					<MenuItem key={o.value} value={o.value}>
						{o.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectComponent;

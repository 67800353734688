import React from 'react';
import leftArrow from '../../../images/left-arrow.svg';
import './page2.css';

const Page2 = ({ setQuizType, setPage, page }) => {
	return (
		<>
			<img src={leftArrow} alt='' onClick={() => setPage(prev => prev - 1)} height={50} width={50} style={{ marginLeft: '20px', display: `${page !== 2 && 'none'}` }} />
			<div className='question-position' style={{ minHeight: page === 2 ? '80vh' : '' }}>
				<h2 className='question-position-title'>{page === 2 ? 'Savol' : 'Izoh'} strukturasini tanlang</h2>
				<div className='question-position-container'>
					<div
						className='column'
						onClick={() => {
							setQuizType(1);
							setPage && setPage(prev => prev + 1);
						}}
					>
						<div className='column-photo'>
							<span>Photo</span>
						</div>
						<div className='column-text'>
							<span>Text</span>
						</div>
					</div>
					<div
						className='row'
						onClick={() => {
							setQuizType(2);
							setPage && setPage(prev => prev + 1);
						}}
					>
						<div className='row-photo'>
							<span>Photo</span>
						</div>
						<div className='row-text'>
							<span>Text</span>
						</div>
					</div>
					<div
						className='together'
						onClick={() => {
							setQuizType(3);
							setPage && setPage(prev => prev + 1);
						}}
					>
						<span>Photo/Text</span>
					</div>
					<div
						className='superior'
						onClick={() => {
							setQuizType(4);
							setPage && setPage(prev => prev + 1);
						}}
					>
						<div className='superior-photos'>
							<span className='superior-photos__item'>Photos</span>
							<span className='superior-photos__item'>Photos</span>
							<span className='superior-photos__item'>Photos</span>
							<span className='superior-photos__item'>Photos</span>
						</div>
						<div className='superior-text'>
							<span>text</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Page2;

import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/ui/Loader';
import './result.css';

const TestResult = () => {
	const { id } = useParams();
	const [test, setTest] = useState(null);
	const [loading, setLoading] = useState(false);

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`https://inpuls-backend.backoffice.uz/quizzes/quiz_by_user/?quiztaker=${id}`);

			setTest(response.data);
		} catch (error) {
			toast.error(error?.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(async () => {
		if (id) {
			await fetchData();
		}
	}, [id]);

	if (loading) {
		return (
			<Box height={'60vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
				<Loader />
			</Box>
		);
	}

	return (
		<>
			<Box marginTop={10}>
				<div className='result-page'>
					<header className='result-header'>
						<h1>JAVOBLAR VARAQASI</h1>
					</header>

					<section className='test-info'>
						<p>
							Test kuni: <strong>{new Date(test && test.quiztaker.unlock_at).toISOString().split('T')[0]}</strong>
						</p>

						<p>
							Test vaqti: <strong>{new Date(test && test.quiztaker.unlock_at).toTimeString().split(' ')[0].slice(0, 5)}</strong>
						</p>

						<p>
							F.I.O:{' '}
							<strong style={{ textDecoration: 'underline' }}>
								{test?.user?.first_name || 'familiya'} {test?.user?.last_name || 'ism'}
							</strong>
						</p>
					</section>

					<section className='answer-sheet'>
						<div className='answer-section'>
							<h3>Savol № va alohida javoblar</h3>
							<table>
								<thead>
									<tr>
										<th>Savol №</th>
										<th rowSpan={4}>Tanladingiz</th>
										<th rowSpan={4}>Tog'ri javob</th>
										<th rowSpan={4}>Xolat</th>
									</tr>
								</thead>
								<tbody>
									{test?.questions?.map((result, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{result?.answers?.find(a => a.status == true)?.title}</td>
											<td>{result?.answers?.find(a => a.is_correct == true)?.title}</td>
											<td>
												{result?.answers?.filter(a => a.passed === true).length !== 0 ? (
													<span className='correct'>✓</span>
												) : (
													<span className='incorrect'>X</span>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</section>
				</div>
			</Box>

			<Box margin={12}>
				<div className='quiz-result-container'>
					<h1 className='quiz-result-title'>Natijalar</h1>
					<ul className='quiz-result-list'>
						{test?.questions?.map((result, index) => (
							<li key={index} className='quiz-result-item'>
								<span className='quiz-question'>
									<span style={{ fontWeight: 'bolder' }}>{index + 1}.</span> {result?.title}
								</span>
								<span className={`quiz-result-icon ${result.answers.filter(a => a.passed === true).length ? 'correct' : 'incorrect'}`}>
									{result.answers.filter(a => a.passed === true).length ? '✓' : 'X'}
								</span>
							</li>
						))}
					</ul>
				</div>
			</Box>
		</>
	);
};

export default TestResult;

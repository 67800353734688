import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../../../api/api';
import plus from '../../../images/Edit.svg';
import leftArrow from '../../../images/left-arrow.svg';
import Page2 from '../page2';
import './page8.css';

const Page8 = ({ data }) => {
	const [answers, setAnswers] = useState('');
	const [addAnswer, setAddAnswer] = useState(false);
	const [newAnswer, setNewAnswer] = useState('');

	const [chooseType, setChooseType] = useState(false);
	const [typeAnswer, setTypeAnswer] = useState(null);
	const [change, setChange] = useState(false);

	const [questionTitle, setQuestionTitle] = useState(data?.title);
	const [answerTitle, setAnswerTitle] = useState({
		a: '',
		b: '',
		c: '',
		d: '',
		e: '',
	});
	const [answerCorrect, setAnswerCorrect] = useState({
		a: null,
		b: null,
		c: null,
		d: null,
		e: null,
	});
	const [idAnswer, setIdAnswer] = useState({
		a: '',
		b: '',
		c: '',
		d: '',
		e: '',
	});
	const [descTitle, setDescTitle] = useState({
		a: '',
		b: '',
		c: '',
		d: '',
		e: '',
	});
	const [image, setImage] = useState(data.img1);
	const [image1, setImage1] = useState(data.img2 ? data?.img2 : '');
	const [image2, setImage2] = useState(data.img3 ? data?.img3 : '');
	const [image3, setImage3] = useState(data.img4 ? data?.img4 : '');

	const [file, setFile] = useState();
	const [file1, setFile1] = useState();
	const [file2, setFile2] = useState();
	const [file3, setFile3] = useState();

	const [descImage, setDescImage] = useState('');
	const [descImage1, setDescImage1] = useState('');
	const [descImage2, setDescImage2] = useState('');
	const [descImage3, setDescImage3] = useState('');

	const [descFile, setDescFile] = useState();
	const [descFile1, setDescFile1] = useState();
	const [descFile2, setDescFile2] = useState();
	const [descFile3, setDescFile3] = useState();

	useEffect(() => {
		api.get(`quizzes/answer/create/?q=${data?.id}`).then(res => {
			setAnswers(res);
		});
	}, []);

	const getImage = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setImage(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};
	const getImage1 = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setImage1(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};
	const getImage2 = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setImage2(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};
	const getImage3 = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setImage3(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};

	const editQuestion = () => {
		const formData = new FormData();
		formData.append('title', questionTitle);
		{
			file && formData.append('img1', file);
		}
		{
			file1 && formData.append('img2', file1);
		}
		{
			file2 && formData.append('img3', file2);
		}
		{
			file3 && formData.append('img4', file3);
		}
		{
			typeAnswer !== null && formData.append('structure', typeAnswer);
		}
		api.patch(`quizzes/question/${data?.id}/`, formData).then(() => {
			toast.success('Ozgartirildi');
			window.location.reload();
		});
	};

	const editAnswer = () => {
		const formData = new FormData();
		{
			answerTitle.a && formData.append('title', answerTitle.a);
		}
		{
			answerCorrect.a && formData.append('is_correct', answerCorrect.a);
		}
		{
			descTitle.a.length > 0 && formData.append('description', descTitle.a);
		}
		{
			descFile && formData.append('img1', descFile);
		}
		{
			descFile1 && formData.append('img2', descFile1);
		}
		{
			descFile2 && formData.append('img3', descFile2);
		}
		{
			descFile3 && formData.append('img4', descFile3);
		}
		api.patch(`quizzes/answer/${idAnswer?.a}/`, formData).then(() => {
			toast.success('Ozgartirildi');
			window.location.reload();
		});
	};
	const editAnswer1 = () => {
		const formData = new FormData();
		{
			answerTitle.b && formData.append('title', answerTitle.b);
		}
		{
			answerCorrect.b && formData.append('is_correct', answerCorrect.b);
		}
		{
			descTitle.b.length > 0 && formData.append('description', descTitle.b);
		}
		{
			descFile && formData.append('img1', descFile);
		}
		{
			descFile1 && formData.append('img2', descFile1);
		}
		{
			descFile2 && formData.append('img3', descFile2);
		}
		{
			descFile3 && formData.append('img4', descFile3);
		}
		api.patch(`quizzes/answer/${idAnswer?.b}/`, formData).then(() => {
			toast.success('Ozgartirildi');
			window.location.reload();
		});
	};
	const editAnswer2 = () => {
		const formData = new FormData();
		{
			answerTitle.c && formData.append('title', answerTitle.c);
		}
		{
			answerCorrect.c && formData.append('is_correct', answerCorrect.c);
		}
		{
			descTitle.c.length > 0 && formData.append('description', descTitle.c);
		}
		{
			descFile && formData.append('img1', descFile);
		}
		{
			descFile1 && formData.append('img2', descFile1);
		}
		{
			descFile2 && formData.append('img3', descFile2);
		}
		{
			descFile3 && formData.append('img4', descFile3);
		}
		api.patch(`quizzes/answer/${idAnswer?.c}/`, formData).then(() => {
			toast.success('Ozgartirildi');
			window.location.reload();
		});
	};
	const editAnswer3 = () => {
		const formData = new FormData();
		{
			answerTitle.d && formData.append('title', answerTitle.d);
		}
		{
			answerCorrect.d && formData.append('is_correct', answerCorrect.d);
		}
		{
			descTitle.d.length > 0 && formData.append('description', descTitle.d);
		}
		{
			descFile && formData.append('img1', descFile);
		}
		{
			descFile1 && formData.append('img2', descFile1);
		}
		{
			descFile2 && formData.append('img3', descFile2);
		}
		{
			descFile3 && formData.append('img4', descFile3);
		}
		api.patch(`quizzes/answer/${idAnswer?.d}/`, formData).then(() => {
			toast.success('Ozgartirildi');
		});
	};
	const editAnswer4 = () => {
		const formData = new FormData();
		{
			answerTitle.e && formData.append('title', answerTitle.e);
		}
		{
			answerCorrect.e && formData.append('is_correct', answerCorrect.e);
		}
		{
			descTitle.e.length > 0 && formData.append('description', descTitle.e);
		}
		{
			descFile && formData.append('img1', descFile);
		}
		{
			descFile1 && formData.append('img2', descFile1);
		}
		{
			descFile2 && formData.append('img3', descFile2);
		}
		{
			descFile3 && formData.append('img4', descFile3);
		}
		api.patch(`quizzes/answer/${idAnswer?.e}/`, formData).then(() => {
			toast.success('Ozgartirildi');
			window.location.reload();
		});
	};

	const editAnswersAll = () => {
		if (
			answerTitle.a.length > 0 ||
			descTitle.a.length > 0 ||
			descFile ||
			descFile1 ||
			descFile2 ||
			descFile3 ||
			answerCorrect.a
		) {
			editAnswer();
		}
		if (
			answerTitle.b.length > 0 ||
			descTitle?.b?.length > 0 ||
			descFile ||
			descFile1 ||
			descFile2 ||
			descFile3 ||
			answerCorrect.b
		) {
			editAnswer1();
		}
		if (
			answerTitle.c.length > 0 ||
			descTitle?.c?.length > 0 ||
			descFile ||
			descFile1 ||
			descFile2 ||
			descFile3 ||
			answerCorrect.c
		) {
			editAnswer2();
		}
		if (
			answerTitle.d.length > 0 ||
			descTitle?.d?.length > 0 ||
			descFile ||
			descFile1 ||
			descFile2 ||
			descFile3 ||
			answerCorrect.d
		) {
			editAnswer3();
		}
		if (
			answerTitle.e.length > 0 ||
			descTitle?.e?.length > 0 ||
			descFile ||
			descFile1 ||
			descFile2 ||
			descFile3 ||
			answerCorrect.e
		) {
			editAnswer4();
		}
	};
	const getImageDesc = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setDescImage(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};
	const getImageDesc1 = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setDescImage1(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};
	const getImageDesc2 = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setDescImage2(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};
	const getImageDesc3 = images => {
		const formData = new FormData();
		formData.append('file', images);
		formData.append('name', 'image');
		api.post('api-web/upload-file/', formData).then(res => {
			setDescImage3(
				`https://inpuls-backend.backoffice.uz/media/${res.path.split('/')[6]}/${
					res.path.split('/')[7]
				}`
			);
		});
	};
	const createAnswert = () => {
		const formData = new FormData();
		formData.append('title', newAnswer);
		formData.append('is_correct', false);
		formData.append('question', data?.id);
		api.post(`quizzes/answer/create/`, formData).then(res => {
			api.get(`quizzes/answer/create/?q=${data?.id}`).then(res => {
				setAnswers(res);
				setNewAnswer('');
				setAddAnswer(false);
			});
		});
	};
	return (
		<>
			<div className='question-check'>
				<img
					src={leftArrow}
					alt=''
					onClick={() => window.location.reload()}
					height={50}
					width={50}
					style={{ cursor: 'pointer' }}
				/>
				<div className='question-check-container'>
					<div className='question-check-head'>
						<h2>1-savol</h2>
						<div className='question-check-btns'>
							<div className='question-check-btn'>
								<b className='question-check__boold-btn'>B</b>
							</div>
							<div className='question-check-btn'>
								<em className='question-check__em-btn'>I</em>
							</div>
							<div className='question-check-btn'>
								<u className='question-check__u-btn'>U</u>
							</div>
						</div>
					</div>
					{chooseType && typeAnswer === null ? (
						<Page2 setQuizType={setTypeAnswer} />
					) : (
						<>
							{((data?.structure === 1 && !change) ||
								typeAnswer === 1) && (
								<>
									<div
										className='bordered-photo-1'
										style={{ marginTop: '5px' }}
									>
										<div className='bordered-photo-item'>
											<img
												src={image}
												alt=''
												width={200}
												height={100}
												className='image-main-full'
											/>
											<label htmlFor='image'>
												<img
													src={plus}
													alt=''
													width={30}
													height={30}
													className='plus-img'
												/>
											</label>
											<input
												type='file'
												name='image'
												id='image'
												accept='.jpg , .png'
												onChange={e => {
													getImage(
														e.target
															.files[0]
													);
													setFile(
														e.target
															.files[0]
													);
												}}
											/>
										</div>
									</div>
									<div className='question-check-text-box'>
										<textarea
											value={questionTitle}
											onChange={e =>
												setQuestionTitle(
													e.target.value
												)
											}
										/>
									</div>
								</>
							)}
							{((data?.structure === 2 && !change) ||
								typeAnswer === 2) && (
								<>
									<div
										className='photo-items-two'
										style={{ marginTop: '10px' }}
									>
										<div className='bordered-photo-item w-50'>
											<img
												src={image}
												alt=''
												width={200}
												height={100}
												className='image-main-full'
											/>
											<label htmlFor='image'>
												<img
													src={plus}
													alt=''
													width={30}
													height={30}
													className='plus-img'
												/>
											</label>
											<input
												type='file'
												name='image'
												id='image'
												accept='.jpg , .png'
												onChange={e => {
													getImage(
														e.target
															.files[0]
													);
													setFile(
														e.target
															.files[0]
													);
												}}
											/>
										</div>
										<div className='w-50 textarea-answer-2'>
											<textarea
												name=''
												className=''
												value={questionTitle}
												placeholder='Savolni kiriting'
												id=''
												cols='30'
												rows='10'
												onChange={e =>
													setQuestionTitle(
														e.target.value
													)
												}
											/>
										</div>
									</div>
								</>
							)}
							{((data?.structure === 3 && !change) ||
								typeAnswer === 3) && (
								<>
									<div
										className='text-3'
										style={{ marginTop: '10px' }}
									>
										<textarea
											value={questionTitle}
											name=''
											id=''
											cols='30'
											rows='10'
											placeholder='Savolni kiriting'
											className='textarea-answer-2'
											onChange={e =>
												setQuestionTitle(
													e.target.value
												)
											}
										/>
									</div>
								</>
							)}
							{((data?.structure === 4 && !change) ||
								typeAnswer === 4) && (
								<>
									<div
										className='photo-items-four'
										style={{ marginTop: '10px' }}
									>
										<div className='photo-items-four__imgs'>
											<div className='file-4'>
												<img
													src={image}
													alt=''
													className='image-main-full'
												/>
												<label htmlFor='image'>
													<img
														src={plus}
														alt=''
														width={30}
														height={30}
														className='plus-img'
													/>
												</label>
												<input
													type='file'
													name='image'
													id='image'
													accept='.jpg , .png'
													onChange={e => {
														getImage(
															e.target
																.files[0]
														);
														setFile(
															e.target
																.files[0]
														);
													}}
												/>
											</div>
											<div className='file-4'>
												<img
													src={image1}
													alt=''
													className='image-main-full'
												/>
												<label htmlFor='image'>
													<img
														src={plus}
														alt=''
														width={30}
														height={30}
														className='plus-img'
													/>
												</label>
												<input
													type='file'
													name='image'
													id='image'
													accept='.jpg , .png'
													onChange={e => {
														getImage1(
															e.target
																.files[0]
														);
														setFile1(
															e.target
																.files[0]
														);
													}}
												/>
											</div>
											<div className='file-4'>
												<img
													src={image2}
													alt=''
													className='image-main-full'
												/>
												<label htmlFor='image'>
													<img
														src={plus}
														alt=''
														width={30}
														height={30}
														className='plus-img'
													/>
												</label>
												<input
													type='file'
													name='image'
													id='image'
													accept='.jpg , .png'
													onChange={e => {
														getImage2(
															e.target
																.files[0]
														);
														setFile2(
															e.target
																.files[0]
														);
													}}
												/>
											</div>
											<div className='file-4'>
												<img
													src={image3}
													alt=''
													className='image-main-full'
												/>
												<label htmlFor='image'>
													<img
														src={plus}
														alt=''
														width={30}
														height={30}
														className='plus-img'
													/>
												</label>
												<input
													type='file'
													name='image'
													id='image'
													accept='.jpg , .png'
													onChange={e => {
														getImage3(
															e.target
																.files[0]
														);
														setFile3(
															e.target
																.files[0]
														);
													}}
												/>
											</div>
										</div>
										<textarea
											name=''
											id=''
											cols='30'
											value={questionTitle}
											rows='10'
											placeholder='Savolni kiriting'
											className='text-area-4'
											onChange={e =>
												setQuestionTitle(
													e.target.value
												)
											}
										/>
									</div>
								</>
							)}
							<div className='change-type-btn'>
								<button
									onClick={() => {
										setChooseType(true);
										setTypeAnswer(null);
										setChange(true);
									}}
								>
									Savol strukturasini ozgartirish
								</button>
							</div>
						</>
					)}
					<hr />
					<div className='variant-text-container'>
						{answers &&
							answers?.map((answer, index) => (
								<div className='variant-text-container__cell'>
									<h2 className='variant-text-container__cell__title'>
										Variant{' '}
										{index === 0
											? 'A'
											: index === 1
											? 'B'
											: index === 2
											? 'C'
											: index === 3
											? 'D'
											: 'E'}
									</h2>
									<div className='variant-text-container__cell__item'>
										<div className='question-variant-box'>
											<input
												type='text'
												className='question-input'
												defaultValue={answer.title}
												onChange={e => {
													index === 0
														? setAnswerTitle(
																{
																	...answerTitle,
																	a: e
																		.target
																		.value,
																}
														  )
														: index === 1
														? setAnswerTitle(
																{
																	...answerTitle,
																	b: e
																		.target
																		.value,
																}
														  )
														: index === 2
														? setAnswerTitle(
																{
																	...answerTitle,
																	c: e
																		.target
																		.value,
																}
														  )
														: index === 3
														? setAnswerTitle(
																{
																	...answerTitle,
																	d: e
																		.target
																		.value,
																}
														  )
														: setAnswerTitle(
																{
																	...answerTitle,
																	e: e
																		.target
																		.value,
																}
														  );
												}}
												onInput={() => {
													index === 0
														? setIdAnswer({
																...idAnswer,
																a: answer.id,
														  })
														: index === 1
														? setIdAnswer({
																...idAnswer,
																b: answer.id,
														  })
														: index === 2
														? setIdAnswer({
																...idAnswer,
																c: answer.id,
														  })
														: index === 3
														? setIdAnswer({
																...idAnswer,
																d: answer.id,
														  })
														: setIdAnswer({
																...idAnswer,
																e: answer.id,
														  });
												}}
											/>
										</div>
										<input
											type='radio'
											name='answers'
											className='question-variant-box__input'
											defaultChecked={answer.is_correct}
											onChange={e =>
												index === 0
													? setAnswerCorrect({
															a: e
																.target
																.checked,
															b: null,
															c: null,
															d: null,
															e: null,
													  })
													: index === 1
													? setAnswerCorrect({
															a: null,
															b: e
																.target
																.checked,
															c: null,
															d: null,
															e: null,
													  })
													: index === 2
													? setAnswerCorrect({
															a: null,
															b: null,
															c: e
																.target
																.checked,
															d: null,
															e: null,
													  })
													: index === 3
													? setAnswerCorrect({
															a: null,
															b: null,
															c: null,
															d: e
																.target
																.checked,
															e: null,
													  })
													: setAnswerCorrect({
															a: null,
															b: null,
															c: null,
															d: null,
															e: e
																.target
																.checked,
													  })
											}
											onClick={() => {
												index === 0
													? setIdAnswer({
															...idAnswer,
															a: answer.id,
													  })
													: index === 1
													? setIdAnswer({
															...idAnswer,
															b: answer.id,
													  })
													: index === 2
													? setIdAnswer({
															...idAnswer,
															c: answer.id,
													  })
													: index === 3
													? setIdAnswer({
															...idAnswer,
															d: answer.id,
													  })
													: setIdAnswer({
															...idAnswer,
															e: answer.id,
													  });
											}}
										/>
									</div>
								</div>
							))}
						<div className='change-type-btn'>
							<button
								disabled={answers.length > 4}
								onClick={() => setAddAnswer(true)}
							>
								Javob qo'shish
							</button>
						</div>
						<div
							className={`modal-body ${
								addAnswer && 'active-alert-withd'
							}`}
						>
							<div className='modal-alert-withdrawal modal-alert-withdrawal-1 card-modal'>
								<div className='question-variant-chooes-container'>
									<div className='question-variant-chooes'>
										<div className='question-variant-box'>
											<textarea
												onChange={e =>
													setNewAnswer(
														e.target.value
													)
												}
												className='question-input question-textarea'
											/>
										</div>
									</div>
									{/*<div className={`question-variant-a`dd-box`}>*/}
									{/*    <img src={PaperPlus} alt=""/>*/}
									{/*</div>*/}
								</div>
								<div className='change-type-btn'>
									<button
										style={{
											width: '150px',
											textAlign: 'center',
											marginRight: '30px',
										}}
										onClick={() => setAddAnswer(false)}
									>
										Yopish
									</button>
									<button
										style={{
											width: '150px',
											textAlign: 'center',
										}}
										onClick={createAnswert}
									>
										Tasdiqlash
									</button>
								</div>
							</div>
						</div>
						<hr />
						<div className='comment-box'>
							{answers &&
								answers?.map((desc, index) => (
									<>
										<h2 className='comment-box-title'>
											Izoh{' '}
											{index === 0
												? 'A'
												: index === 1
												? 'B'
												: index === 2
												? 'C'
												: index === 3
												? 'D'
												: 'E'}
										</h2>
										{desc.structure === 1 && (
											<>
												<div className='bordered-photo-1'>
													<div className='bordered-photo-item'>
														<img
															src={
																desc?.img1
															}
															alt=''
															width={
																200
															}
															height={
																100
															}
															className='image-main-full'
														/>
														<label
															htmlFor='image3'
															onClick={() => {
																index ===
																0
																	? setIdAnswer(
																			{
																				...idAnswer,
																				a: desc.id,
																			}
																	  )
																	: index ===
																	  1
																	? setIdAnswer(
																			{
																				...idAnswer,
																				b: desc.id,
																			}
																	  )
																	: index ===
																	  2
																	? setIdAnswer(
																			{
																				...idAnswer,
																				c: desc.id,
																			}
																	  )
																	: index ===
																	  3
																	? setIdAnswer(
																			{
																				...idAnswer,
																				d: desc.id,
																			}
																	  )
																	: setIdAnswer(
																			{
																				...idAnswer,
																				e: desc.id,
																			}
																	  );
															}}
														>
															<img
																src={
																	plus
																}
																alt=''
																onClick={() => {
																	index ===
																	0
																		? setIdAnswer(
																				{
																					...idAnswer,
																					a: desc.id,
																				}
																		  )
																		: index ===
																		  1
																		? setIdAnswer(
																				{
																					...idAnswer,
																					b: desc.id,
																				}
																		  )
																		: index ===
																		  2
																		? setIdAnswer(
																				{
																					...idAnswer,
																					c: desc.id,
																				}
																		  )
																		: index ===
																		  3
																		? setIdAnswer(
																				{
																					...idAnswer,
																					d: desc.id,
																				}
																		  )
																		: setIdAnswer(
																				{
																					...idAnswer,
																					e: desc.id,
																				}
																		  );
																}}
																width={
																	30
																}
																height={
																	30
																}
																className='plus-img'
															/>
														</label>
														<input
															type='file'
															name='image3'
															id='image3'
															style={{
																display: 'none',
															}}
															accept='.jpg , .png'
															onChange={e => {
																getImageDesc(
																	e
																		.target
																		.files[0]
																);
																setDescFile(
																	e
																		.target
																		.files[0]
																);
															}}
														/>
													</div>
												</div>
												<textarea
													className='desc-text'
													placeholder='izohni yozing'
													defaultValue={
														desc?.description
													}
													onChange={e => {
														index === 0
															? setDescTitle(
																	{
																		...descTitle,
																		a: e
																			.target
																			.value,
																	}
															  )
															: index ===
															  1
															? setDescTitle(
																	{
																		...descTitle,
																		b: e
																			.target
																			.value,
																	}
															  )
															: index ===
															  2
															? setDescTitle(
																	{
																		...descTitle,
																		c: e
																			.target
																			.value,
																	}
															  )
															: index ===
															  3
															? setDescTitle(
																	{
																		...descTitle,
																		d: e
																			.target
																			.value,
																	}
															  )
															: setDescTitle(
																	{
																		...descTitle,
																		e: e
																			.target
																			.value,
																	}
															  );
													}}
													onInput={() => {
														index === 0
															? setIdAnswer(
																	{
																		...idAnswer,
																		a: desc.id,
																	}
															  )
															: index ===
															  1
															? setIdAnswer(
																	{
																		...idAnswer,
																		b: desc.id,
																	}
															  )
															: index ===
															  2
															? setIdAnswer(
																	{
																		...idAnswer,
																		c: desc.id,
																	}
															  )
															: index ===
															  3
															? setIdAnswer(
																	{
																		...idAnswer,
																		d: desc.id,
																	}
															  )
															: setIdAnswer(
																	{
																		...idAnswer,
																		e: desc.id,
																	}
															  );
													}}
												/>
											</>
										)}
										{desc.structure === 2 && (
											<>
												<div className='photo-items-two'>
													<div className='bordered-photo-item w-50'>
														<img
															src={
																desc.img1
															}
															alt=''
															width={
																200
															}
															height={
																100
															}
															className='image-main-full'
														/>
														<label
															htmlFor='image5'
															onClick={() => {
																index ===
																0
																	? setIdAnswer(
																			{
																				...idAnswer,
																				a: desc.id,
																			}
																	  )
																	: index ===
																	  1
																	? setIdAnswer(
																			{
																				...idAnswer,
																				b: desc.id,
																			}
																	  )
																	: index ===
																	  2
																	? setIdAnswer(
																			{
																				...idAnswer,
																				c: desc.id,
																			}
																	  )
																	: index ===
																	  3
																	? setIdAnswer(
																			{
																				...idAnswer,
																				d: desc.id,
																			}
																	  )
																	: setIdAnswer(
																			{
																				...idAnswer,
																				e: desc.id,
																			}
																	  );
															}}
														>
															<img
																src={
																	plus
																}
																alt=''
																width={
																	30
																}
																height={
																	30
																}
																className='plus-img'
															/>
														</label>
														<input
															type='file'
															name='image5'
															id='image5'
															style={{
																display: 'none',
															}}
															accept='.jpg , .png'
															onChange={e => {
																getImageDesc1(
																	e
																		.target
																		.files[0]
																);
																setDescFile1(
																	e
																		.target
																		.files[0]
																);
															}}
														/>
													</div>
													<div className='w-50 textarea-answer-2'>
														<textarea
															name=''
															className=''
															defaultValue={
																desc?.description
															}
															placeholder='Izohni kiriting'
															id=''
															cols='30'
															rows='10'
															onChange={e => {
																index ===
																0
																	? setDescTitle(
																			{
																				...descTitle,
																				a: e
																					.target
																					.value,
																			}
																	  )
																	: index ===
																	  1
																	? setDescTitle(
																			{
																				...descTitle,
																				b: e
																					.target
																					.value,
																			}
																	  )
																	: index ===
																	  2
																	? setDescTitle(
																			{
																				...descTitle,
																				c: e
																					.target
																					.value,
																			}
																	  )
																	: index ===
																	  3
																	? setDescTitle(
																			{
																				...descTitle,
																				d: e
																					.target
																					.value,
																			}
																	  )
																	: setDescTitle(
																			{
																				...descTitle,
																				e: e
																					.target
																					.value,
																			}
																	  );
															}}
															onInput={() => {
																index ===
																0
																	? setIdAnswer(
																			{
																				...idAnswer,
																				a: desc.id,
																			}
																	  )
																	: index ===
																	  1
																	? setIdAnswer(
																			{
																				...idAnswer,
																				b: desc.id,
																			}
																	  )
																	: index ===
																	  2
																	? setIdAnswer(
																			{
																				...idAnswer,
																				c: desc.id,
																			}
																	  )
																	: index ===
																	  3
																	? setIdAnswer(
																			{
																				...idAnswer,
																				d: desc.id,
																			}
																	  )
																	: setIdAnswer(
																			{
																				...idAnswer,
																				e: desc.id,
																			}
																	  );
															}}
														/>
													</div>
												</div>
											</>
										)}
										{desc.structure === 3 && (
											<>
												<div className='text-3'>
													<textarea
														defaultValue={
															desc?.description
														}
														name=''
														id=''
														cols='30'
														rows='10'
														placeholder='Izohni kiriting'
														className='textarea-answer-2'
														onChange={e => {
															index ===
															0
																? setDescTitle(
																		{
																			...descTitle,
																			a: e
																				.target
																				.value,
																		}
																  )
																: index ===
																  1
																? setDescTitle(
																		{
																			...descTitle,
																			b: e
																				.target
																				.value,
																		}
																  )
																: index ===
																  2
																? setDescTitle(
																		{
																			...descTitle,
																			c: e
																				.target
																				.value,
																		}
																  )
																: index ===
																  3
																? setDescTitle(
																		{
																			...descTitle,
																			d: e
																				.target
																				.value,
																		}
																  )
																: setDescTitle(
																		{
																			...descTitle,
																			e: e
																				.target
																				.value,
																		}
																  );
														}}
														onInput={() => {
															index ===
															0
																? setIdAnswer(
																		{
																			...idAnswer,
																			a: desc.id,
																		}
																  )
																: index ===
																  1
																? setIdAnswer(
																		{
																			...idAnswer,
																			b: desc.id,
																		}
																  )
																: index ===
																  2
																? setIdAnswer(
																		{
																			...idAnswer,
																			c: desc.id,
																		}
																  )
																: index ===
																  3
																? setIdAnswer(
																		{
																			...idAnswer,
																			d: desc.id,
																		}
																  )
																: setIdAnswer(
																		{
																			...idAnswer,
																			e: desc.id,
																		}
																  );
														}}
													/>
												</div>
											</>
										)}
										{desc.structure === 4 && (
											<>
												<div className='photo-items-four__imgs'>
													<div className='file-4'>
														<img
															src={
																desc?.img1
															}
															alt=''
															className='image-main-full'
														/>
														<label htmlFor='imageee'>
															<img
																src={
																	plus
																}
																alt=''
																width={
																	30
																}
																height={
																	30
																}
																className='plus-img'
															/>
														</label>
														<input
															type='file'
															name='imageee'
															id='imageee'
															style={{
																display: 'none',
															}}
															accept='.jpg , .png'
															onChange={e => {
																getImageDesc(
																	e
																		.target
																		.files[0]
																);
																setDescFile(
																	e
																		.target
																		.files[0]
																);
															}}
														/>
													</div>
													<div className='file-4'>
														<img
															src={
																descImage1
																	? descImage1
																	: desc?.img2
															}
															alt=''
															className='image-main-full'
														/>
														<label htmlFor='imageee6'>
															<img
																src={
																	plus
																}
																alt=''
																width={
																	30
																}
																height={
																	30
																}
																className='plus-img'
															/>
														</label>
														<input
															type='file'
															name='imageee6'
															id='imageee6'
															style={{
																display: 'none',
															}}
															accept='.jpg , .png'
															onChange={e => {
																getImageDesc1(
																	e
																		.target
																		.files[0]
																);
																setDescFile2(
																	e
																		.target
																		.files[0]
																);
															}}
														/>
													</div>
													<div className='file-4'>
														<img
															src={
																descImage2
																	? descImage2
																	: desc?.img3
															}
															alt=''
															className='image-main-full'
														/>
														<label htmlFor='imageee77'>
															<img
																src={
																	plus
																}
																alt=''
																width={
																	30
																}
																height={
																	30
																}
																className='plus-img'
															/>
														</label>
														<input
															type='file'
															name='imageee77'
															id='imageee77'
															style={{
																display: 'none',
															}}
															accept='.jpg , .png'
															onChange={e => {
																getImageDesc2(
																	e
																		.target
																		.files[0]
																);
																setDescFile2(
																	e
																		.target
																		.files[0]
																);
															}}
														/>
													</div>
													<div className='file-4'>
														<img
															src={
																descImage3
																	? descImage3
																	: desc?.img4
															}
															alt=''
															className='image-main-full'
														/>
														<label htmlFor='imageee88'>
															<img
																src={
																	plus
																}
																alt=''
																width={
																	30
																}
																height={
																	30
																}
																className='plus-img'
															/>
														</label>
														<input
															type='file'
															name='imageee88'
															id='imageee99'
															style={{
																display: 'none',
															}}
															accept='.jpg , .png'
															onChange={e => {
																getImageDesc3(
																	e
																		.target
																		.files[0]
																);
																setDescFile3(
																	e
																		.target
																		.files[0]
																);
															}}
														/>
													</div>
												</div>
												<textarea
													name=''
													id=''
													cols='30'
													defaultValue={
														desc.description
													}
													rows='10'
													placeholder='Izhoni kiriting'
													className='text-area-4'
												/>
											</>
										)}
									</>
								))}
						</div>
					</div>
				</div>
				<div className='change-save-btn'>
					<button
						className='change-btn'
						onClick={() => {
							editQuestion();
							editAnswersAll();
						}}
					>
						O’zgarishlarni saqlash
					</button>
				</div>
			</div>
		</>
	);
};

export default Page8;

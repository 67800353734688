import * as t from './mainTypes';

export const setToken = (type) => (dispatch) => {
  dispatch({ type: t.SET_TOKEN, payload: type });
};
export const setRefreshToken = (type) => (dispatch) => {
  dispatch({ type: t.SET_REFRESH_TOKEN, payload: type });
};

export const setUser = (type) => (dispatch) => {
  dispatch({ type: t.SET_USER, payload: type });
};

export const setEditVideo = (type) => (dispatch) => {
  dispatch({ type: t.EDIT_VIDEO, payload: type });
};

export const setSelectData = (type) => (dispatch) => {
  dispatch({ type: t.SELECT_DATA, payload: type });
};

import React, {useCallback, useState} from 'react';
import "../../login/ParolUnut_1.css"
import {useHistory} from "react-router-dom";
import {api} from "../../../api/api";
import Logo from "../../images/Logo1.svg";
import {toast} from "react-toastify";
import Loader from "../../ui/Loader";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function ParolUnut_1() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState({
        phone: ""
    });
    const submitHandler = useCallback(() => {
        setLoading(true);
        let phoneNumber = phone.phone;
        phoneNumber = phoneNumber.length === 9 ? "998" + phoneNumber : phoneNumber?.includes("+") ? phoneNumber.replace("+", "") : phoneNumber;
        phoneNumber = phoneNumber.substr(0, 25);
        localStorage.setItem("phoneSpeaker", phone.phone);
        const formData = new FormData();
        formData.append("phone_number", phone.phone);
        api.post("reset-password-check", formData).then((res) => {
                if (res.status === true) {
                    setLoading(false);
                    history.replace("/reset-password/verify")
                } else {
                    setLoading(false);
                    toast.error("Telefon raqamda xatolik.", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        ).catch(() => {
                setLoading(false);
                toast.error("Telefon raqamda xatolik.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        )
    }, [history, phone]);
    return (
        <div className="ParolUnut_1_block">
            <div className='container'>
                <div className="forget-password-wrapper">
                    <div className="LoginPage-inner">
                        <div className="login-courses_sec_btn ">
                            <img src={Logo} alt="" onClick={() => history.replace("/login")}/>
                        </div>
                    </div>
                    <div className="ParolUnut_1 ">
                        <h4 style={{marginBottom: "50px"}}>PAROLNI TIKLASH</h4>
                        <div className="ParolUnut_1_block_input">
                            <div className='ParolUnut_1_div2 mb-2'>
                                <PhoneInput
                                    containerClass="input-forget phone-flag"
                                    country={'uz'}
                                    value={phone.login}
                                    type="text"
                                    masks={{uz: '(..) ...-..-..'}}
                                    id="login"
                                    required
                                    autoComplete="false"
                                    onChange={e => setPhone({...phone , phone: e})}                                />
                            </div>
                        </div>

                        <div className='ParolUnut_1_tasdiq'>
                            <p onClick={() => history.replace("/login")}>orqaga</p>
                            <button type="submit" onClick={submitHandler}>{loading ?
                                <span className="loader-login"><Loader/></span> : "Tasdiqlash"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

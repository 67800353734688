import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api, API_VERSION } from '../../api/api';

const UpdateGroupStudentsLevel = () => {
	const [levels, setLevels] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [usersList, setUsersList] = useState([]);

	const [currentStudentId, setCurrentStudentId] = useState(0);
	const [currentKursId, setCurrentKursId] = useState(0);

	function fetchItem(url = '', saveData = () => {}) {
		let mounted = true;

		setIsLoading(true);
		api.get(url).then(res => {
			if (mounted) {
				saveData(res);
				setIsLoading(false);
			}
		});

		return () => {
			mounted = false;
		};
	}

	const updateCurrentStudentLevel = () => {
		const currentStudentToChangeObj = {
			student_id: currentStudentId,
			kurs: currentKursId,
		};

		api.put(`${API_VERSION}edit_user/`, currentStudentToChangeObj)
			.then(res => {
				if (res.success) {
					toast.success(res.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			})
			.catch(err => {
				toast.error(err.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			});
	};

	useEffect(() => {
		// https://inpuls-backend.backoffice.uz/university/course/       1-chi kurs
		// https://inpuls-backend.backoffice.uz/backoffice/users-list

		// https://inpuls-backend.backoffice.uz/api-web/edit_user/  PUT

		fetchItem(`https://inpuls-backend.backoffice.uz/university/course/`, setLevels);

		fetchItem(`https://inpuls-backend.backoffice.uz/backoffice/users-list`, setUsersList);
	}, []);

	return (
		<div style={{ padding: '2rem' }}>
			<h3>Talabalarni kursni o'zgartirish</h3>
			<br />

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className='selects-block'>
					<p>O'quvchilar ruyxati</p>
					<select
						name=''
						id=''
						// defaultValue={""}
						onChange={({ target }) => setCurrentStudentId(Number(target.value))}
					>
						<option hidden selected>
							O'quvchini tanlang
						</option>
						{usersList &&
							usersList?.results?.map((x, idx) => (
								<option
									key={idx}
									value={x.id}
									// selected={data?.course_university === x.id}
								>
									{x.name}
								</option>
							))}
					</select>
				</div>
				<div className='selects-block'>
					<p>O'quvchilar ruyxati</p>
					<select
						name=''
						id=''
						// defaultValue={""}
						onChange={({ target }) => setCurrentKursId(Number(target.value))}
					>
						<option hidden selected>
							Kursni tanlang
						</option>
						{levels &&
							levels?.map((x, idx) => (
								<option
									key={idx}
									value={x.id}
									// selected={data?.course_university === x.id}
								>
									{x.name}
								</option>
							))}
					</select>
				</div>
			</div>

			<div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
				<Button onClick={updateCurrentStudentLevel} variant='contained' color='primary'>
					Saqlash
				</Button>
			</div>
		</div>
	);
};

export default UpdateGroupStudentsLevel;

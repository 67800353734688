import React, {useCallback, useState} from 'react';
import NavBar from '../Navbar';
import markIcon from '../../images/mark-icon.svg';
import PaperPlus from '../../images/Paper-Plus.svg';
import iconImage from '../../images/icpon-img.svg';
import './page6.css';
import Page2 from "../page2";
import plus from "../../images/plus.svg";
import imgIcon from "../../images/img-icon.svg";
import {api} from "../../../api/api";
import { useHistory } from "react-router-dom"
import {toast} from "react-toastify";

const Page6 = () => {
    const [isActive, setIsActive] = useState(false);
    const history = useHistory();
    const [add, setAdd] = useState(1);
    const [desc, setDesc] = useState(0);
    const [answer, setAnswer] = useState("");
    const [type, setType] = useState(0);
    const [typeDesc, setTypeDecs] = useState("");
    const [file1, setFile1] = useState("");
    const [file2, setFile2] = useState("");
    const [file3, setFile3] = useState("");
    const [file4, setFile4] = useState("");

    const [decsTitle, setDecsTitle] = useState("");
    const [descImage1, setDescImage1] = useState();
    const [descImage2, setDescImage2] = useState();
    const [descImage3, setDescImage3] = useState();
    const [descImage4, setDescImage4] = useState();

    const getImage = useCallback((images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setDescImage1(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
        })
    }, []);
    const getImage1 = useCallback((images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setDescImage2(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
        })
    }, []);

    const getImage2 = useCallback((images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setDescImage3(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
        })
    }, []);

    const getImage3 = useCallback((images) => {
        const formData = new FormData();
        formData.append("file", images);
        formData.append("name", "image");
        api.post("api-web/upload-file/", formData).then(res => {
            setDescImage4(`https://inpuls-backend.backoffice.uz/media/${res.path.split("/")[6]}/${res.path.split("/")[7]}`)
        })
    }, []);

    const createAnswer = () => {
        const formData = new FormData();
        formData.append("title", answer);
        formData.append("is_correct", isActive);
        {type > 0 && formData.append("structure", type)};
        formData.append("description", decsTitle);
        formData.append("question", window.localStorage.getItem("question"));
        {
            file1 && formData.append("img1", file1)
        }
        {
            file2 && formData.append("img2", file2)
        }
        {
            file3 && formData.append("img3", file3)
        }
        {
            file4 && formData.append("img4", file4)
        }
        api.post("quizzes/answer/create/", formData).then(() => {
            setType(0);
            setDecsTitle("");
            setDesc(0);
            setIsActive(false);
            setAnswer("");
            setFile1("");
            setFile2("");
            setFile3("");
            setFile4("");
            setDescImage1("");
            setDescImage2("");
            setDescImage3("");
            setDescImage4("");
            toast.success("Javoblar saqlandi");
            window.location.reload();
        })
    };
    const createAnswer1 = () => {
        setAdd(prev => prev + 1);
        const formData = new FormData();
        formData.append("title", answer);
        formData.append("is_correct", isActive);
        {type > 0 && formData.append("structure", type)};
        formData.append("description", decsTitle);
        formData.append("question", window.localStorage.getItem("question"));
        {
            file1 && formData.append("img1", file1)
        }
        {
            file2 && formData.append("img2", file2)
        }
        {
            file3 && formData.append("img3", file3)
        }
        {
            file4 && formData.append("img4", file4)
        }
        api.post("quizzes/answer/create/", formData).then(res => {
            setType(0);
            setDecsTitle("");
            setDesc(0);
            setIsActive(false);
            setAnswer("");
            setFile1("");
            setFile2("");
            setFile3("");
            setFile4("");
            toast.success("Javoblar saqlandi");
            setDescImage1("");
            setDescImage2("");
            setDescImage3("");
            setDescImage4("");
        })
    };
    return (
        <>
            <div className="add-create-question-variants">
                <p className="add-create-question-variants__title-1" style={{marginBottom: "50px"}}>Variant</p>
                <div className="add-create-question-variants__item">
                    <p className="add-create-question-variants__title">Variant {add === 1 ? "A" : add === 2 ? "B" : add === 3 ? "C" : add === 4 ? "D" : add === 5 ? "E" : "F"}</p>
                    <div className="question-variant-chooes-container">
                        <div className="question-variant-chooes">
                            <div className="question-variant-box">
                                <input type="text" className="question-input" value={answer}
                                       onChange={(e) => setAnswer(e.target.value)}/>
                            </div>
                            <input type="checkbox" className="question-variant-box__input" checked={isActive}
                                   onClick={() => setIsActive(prev => !prev)}/>
                        </div>
                        {/*<div className={`question-variant-a`dd-box`}>*/}
                        {/*    <img src={PaperPlus} alt=""/>*/}
                        {/*</div>*/}
                    </div>
                </div>
                {desc === 0 && <div className="btn-container">
                    <button className="add-comment-btn" onClick={() => setDesc(1)}>Izoh qoshish</button>
                </div>}
                {desc === 1 && type === 0 && <Page2 setQuizType={setType}/>}
                {type === 1 && (
                    <div className="photo-items-one">
                        <p className="add-create-question-variants__title-1">Izoh</p>
                        <div className="bordered-photo-1">
                            <div className="bordered-photo-item">
                                {descImage1 ?
                                    <img src={descImage1} alt="" width={200} height={100} className="image-main-full"/>
                                    : <>
                                        <div className="question-add">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                accept=".jpg , .png"
                                                onChange={(e) => {
                                                    getImage(e.target.files[0]);
                                                    setFile1(e.target.files[0])
                                                }
                                                }
                                            />
                                            <label htmlFor="image">
                                                <img src={plus} alt=""/>
                                            </label>
                                        </div>
                                        <span>Rasmni yuklang</span> </>
                                }
                            </div>
                        </div>
                        <textarea name="" id="" placeholder="izohni yozing"
                                  onChange={(e) => setDecsTitle(e.target.value)}/>
                    </div>
                )}
                {type === 2 && (
                    <>
                        <p className="add-create-question-variants__title-1" style={{marginBottom: "20px"}}>Izoh</p>
                        <div className="photo-items-two">
                            <div className="bordered-photo-item w-50">
                                {descImage1 ?
                                    <img src={descImage1} alt="" width={200} height={100} className="image-main-full"/>
                                    : <>
                                        <div className="question-add">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                accept=".jpg , .png"
                                                onChange={(e) => {
                                                    getImage(e.target.files[0]);
                                                    setFile1(e.target.files[0])
                                                }
                                                }
                                            />
                                            <label htmlFor="image">
                                                <img src={plus} alt=""/>
                                            </label>
                                        </div>
                                        <span>Rasmni yuklang</span>
                                    </>}
                            </div>
                            <div className="w-50 textarea-answer-2">
                            <textarea name="" className="" onChange={(e) => setDecsTitle(e.target.value)}
                                      placeholder="Izohni kiriting" id="" cols="30" rows="10"/>
                            </div>
                        </div>
                    </>
                )}
                {type === 3 && (
                    <>
                        <p className="add-create-question-variants__title-1" style={{marginBottom: "20px"}}>Izoh</p>
                        <div className="photo-wrapper-three">
                            <div className="header-seelct">
                                <div className="question-btns">
                                    <div className={`question-btns__text ${typeDesc === "text" && "active-bg"}`}
                                         onClick={() => setTypeDecs("text")}>T
                                    </div>
                                    <div className={`question-btns__text ${typeDesc === "image" && "active-bg"}`}
                                         onClick={() => setTypeDecs("image")}>
                                        <img src={imgIcon} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="photo-items-three">
                                {typeDesc === "image" ? <div className="file-3">
                                    {descImage1 ? <img src={descImage1} alt="" className="image-main-full"/> :
                                        <>
                                            <div className="question-add">
                                                <input
                                                    type="file"
                                                    name="image"
                                                    id="image"
                                                    accept=".jpg , .png"
                                                    onChange={(e) => {
                                                        getImage(e.target.files[0]);
                                                        setFile1(e.target.files[0])
                                                    }
                                                    }
                                                />
                                                <label htmlFor="image">
                                                    <img src={plus} alt=""/>
                                                </label>
                                            </div>
                                            < span> Rasmni yuklang</span>
                                        </>
                                    }
                                </div> : typeDesc === "text" ?
                                    <div className="text-3">
                                <textarea onChange={(e) => setDecsTitle(e.target.value)} name="" id="" cols="30"
                                          rows="10"
                                          placeholder="Izohni kiriting"
                                          className="textarea-answer-2"/>
                                    </div> : null}
                            </div>
                        </div>
                    </>
                )}
                {type === 4 && (
                    <>
                        <p className="add-create-question-variants__title-1" style={{marginBottom: "20px"}}>Izoh</p>
                        <div className="photo-items-four">
                            <div className="photo-items-four__imgs">
                                <div className="file-4">
                                    {descImage1 ?
                                        <img src={descImage1} alt="" className="image-main-full"/> : <>
                                            <div className="question-add">
                                                <input
                                                    type="file"
                                                    name="image"
                                                    id="image"
                                                    accept=".jpg , .png"
                                                    onChange={(e) => {
                                                        getImage(e.target.files[0]);
                                                        setFile1(e.target.files[0])
                                                    }
                                                    }
                                                />
                                                <label htmlFor="image">
                                                    <img src={plus} alt=""/>
                                                </label>
                                            </div>
                                            <span>Rasmni yuklang</span> </>}
                                </div>
                                <div className="file-4">
                                    {descImage2 ? <img src={descImage2} alt=""
                                                       className="image-main-full"/> : <>
                                        <div className="question-add">
                                            <input
                                                type="file"
                                                name="imagee"
                                                id="imagee"
                                                style={{display: "none"}}
                                                accept=".jpg , .png"
                                                onChange={(e) => {
                                                    getImage1(e.target.files[0]);
                                                    setFile2(e.target.files[0])
                                                }
                                                }
                                            />
                                            <label htmlFor="imagee">
                                                <img src={plus} alt=""/>
                                            </label>
                                        </div>
                                        <span>Rasmni yuklang</span>
                                    </>}
                                </div>
                                <div className="file-4">
                                    {descImage3 ? <img src={descImage3} alt=""
                                                       className="image-main-full"/> : <>
                                        <div className="question-add">
                                            <input
                                                type="file"
                                                name="imageee"
                                                id="imageee"
                                                style={{display: "none"}}
                                                accept=".jpg , .png"
                                                onChange={(e) => {
                                                    getImage2(e.target.files[0]);
                                                    setFile3(e.target.files[0])
                                                }
                                                }
                                            />
                                            <label htmlFor="imageee">
                                                <img src={plus} alt=""/>
                                            </label>
                                        </div>
                                        <span>Rasmni yuklang</span>
                                    </>}
                                </div>
                                <div className="file-4">
                                    {descImage4 ? <img src={descImage4} alt=""
                                                       className="image-main-full"/> : <>
                                        <div className="question-add">
                                            <input
                                                type="file"
                                                name="next"
                                                id="next"
                                                style={{display: "none"}}
                                                accept=".jpg , .png"
                                                onChange={(e) => {
                                                    getImage3(e.target.files[0]);
                                                    setFile4(e.target.files[0])
                                                }
                                                }
                                            />
                                            <label htmlFor="next">
                                                <img src={plus} alt=""/>
                                            </label>
                                        </div>
                                        <span>Rasmni yuklang</span>
                                    </>}
                                </div>
                            </div>
                            <textarea name="" id="" cols="30" onChange={(e) => setDecsTitle(e.target.value)} rows="10"
                                      placeholder="Izhoni kiriting"
                                      className="text-area-4"/>
                        </div>
                    </>
                )}
                {/*{desc === 2 && <>*/}
                {/*    <p className="add-create-question-variants__title-1">Izoh</p>*/}
                {/*    <div className="add-create-question-variants__item">*/}
                {/*        /!*<p className="add-create-question-variants__title">Variant A</p>*!/*/}
                {/*        <div className="question-variant-chooes-container">*/}
                {/*            <div className="question-variant-chooes">*/}
                {/*                <div className="question-variant-box">*/}
                {/*                    <input type="text" className="question-input"*/}
                {/*                           onChange={(e) => setDecsTitle(e.target.value)}/>*/}
                {/*                    /!*Movarounnahr*!/*/}
                {/*                    /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*                </div>*/}
                {/*                /!*<input type="radio" className="question-variant-box__input"/>*!/*/}
                {/*            </div>*/}
                {/*            /!*<div className={`question-variant-add-box`}>*!/*/}
                {/*            /!*  <img src={PaperPlus} alt=""/>*!/*/}
                {/*            /!*</div>*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</>}*/}
                <div className="btns-footer">
                    <div className="btn-body">
                        <div className="btn-container">
                            <button className="add-comment-btn" disabled={answer.length < 1}
                                    onClick={createAnswer}>Saqlash
                            </button>
                        </div>
                        <div className="btn-container">
                            <button className="add-comment-btn" disabled={answer.length < 1} onClick={createAnswer1}>Saqlash va javob qoshish
                            </button>
                        </div>
                    </div>
                </div>
                {/*{variants > 1 && <div className="add-create-question-variants__item">*/}
                {/*    <p className="add-create-question-variants__title">Variant B</p>*/}
                {/*    <div className="question-variant-chooes-container">*/}
                {/*        <div className="question-variant-chooes">*/}
                {/*            <div className="question-variant-box">*/}
                {/*                <input type="text" className="question-input" onChange={(e) => setAnswer2(e.target.value)}/>*/}
                {/*                /!*Movarounnahr*!/*/}
                {/*                /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*            </div>*/}
                {/*            <input type="radio" className="question-variant-box__input"/>*/}
                {/*        </div>*/}
                {/*        <div className={`question-variant-add-box ${variants > 2 && "d-none"}`}*/}
                {/*             onClick={() => setVariants(3)}>*/}
                {/*            <img src={PaperPlus} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>}*/}
                {/*{variants > 2 && <div className="add-create-question-variants__item">*/}
                {/*    <p className="add-create-question-variants__title">Variant C</p>*/}
                {/*    <div className="question-variant-chooes-container">*/}
                {/*        <div className="question-variant-chooes">*/}
                {/*            <div className="question-variant-box">*/}
                {/*                <input type="text" className="question-input"/>*/}
                {/*                /!*Movarounnahr*!/*/}
                {/*                /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*            </div>*/}
                {/*            <input type="radio" className="question-variant-box__input" onChange={(e) => setAnswer3(e.target.value)}/>*/}
                {/*        </div>*/}
                {/*        <div className={`question-variant-add-box ${variants > 3 && "d-none"}`}*/}
                {/*             onClick={() => setVariants(4)}>*/}
                {/*            <img src={PaperPlus} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>}*/}
                {/*{variants > 3 && <div className="add-create-question-variants__item">*/}
                {/*    <p className="add-create-question-variants__title">Variant D</p>*/}
                {/*    <div className="question-variant-chooes-container">*/}
                {/*        <div className="question-variant-chooes">*/}
                {/*            <div className="question-variant-box">*/}
                {/*                <input type="text" className="question-input"/>*/}
                {/*                /!*Movarounnahr*!/*/}
                {/*                /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*            </div>*/}
                {/*            <input type="radio" className="question-variant-box__input" onChange={(e) => setAnswer4(e.target.value)}/>*/}
                {/*        </div>*/}
                {/*        <div className={`question-variant-add-box ${variants > 4 && "d-none"}`}>*/}
                {/*            <img src={PaperPlus} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>}*/}
                {/*{desc < 1 && <div className="btn-container">*/}
                {/*    <button className="add-comment-btn" onClick={() => setDesc(1)}>Izoh qo’shish</button>*/}
                {/*</div>}*/}
                {/*{desc > 0 && (*/}
                {/*    <>*/}
                {/*        <p className="add-create-question-variants__title" style={{marginBottom: "50px"}}>Izohlar</p>*/}
                {/*        <div className="add-create-question-variants__item">*/}
                {/*            <p className="add-create-question-variants__title">Variant A</p>*/}
                {/*            <div className="question-variant-chooes-container">*/}
                {/*                <div className="question-variant-chooes">*/}
                {/*                    <div className="question-variant-box">*/}
                {/*                        <input type="text" className="question-input"*/}
                {/*                               onChange={(e) => setDecsTitle1(e.target.value)}/>*/}
                {/*                        /!*Movarounnahr*!/*/}
                {/*                        /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*                    </div>*/}
                {/*                    <input type="radio" className="question-variant-box__input"/>*/}
                {/*                </div>*/}
                {/*                <div className={`question-variant-add-box ${variants > 3 && "d-none"}`}*/}
                {/*                     onClick={() => setVariants(4)}>*/}
                {/*                    <img src={PaperPlus} alt=""/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="add-create-question-variants__item">*/}
                {/*            <p className="add-create-question-variants__title">Variant B</p>*/}
                {/*            <div className="question-variant-chooes-container">*/}
                {/*                <div className="question-variant-chooes">*/}
                {/*                    <div className="question-variant-box">*/}
                {/*                        <input type="text" className="question-input"/>*/}
                {/*                        /!*Movarounnahr*!/*/}
                {/*                        /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*                    </div>*/}
                {/*                    <input type="radio" className="question-variant-box__input"*/}
                {/*                           onChange={(e) => setDecsTitle2(e.target.value)}/>*/}
                {/*                </div>*/}
                {/*                <div className={`question-variant-add-box ${variants > 3 && "d-none"}`}*/}
                {/*                     onClick={() => setVariants(4)}>*/}
                {/*                    <img src={PaperPlus} alt=""/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="add-create-question-variants__item">*/}
                {/*            <p className="add-create-question-variants__title">Variant C</p>*/}
                {/*            <div className="question-variant-chooes-container">*/}
                {/*                <div className="question-variant-chooes">*/}
                {/*                    <div className="question-variant-box">*/}
                {/*                        <input type="text" className="question-input"*/}
                {/*                               onChange={(e) => setDecsTitle3(e.target.value)}/>*/}
                {/*                        /!*Movarounnahr*!/*/}
                {/*                        /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*                    </div>*/}
                {/*                    <input type="radio" className="question-variant-box__input"/>*/}
                {/*                </div>*/}
                {/*                <div className={`question-variant-add-box ${variants > 3 && "d-none"}`}*/}
                {/*                     onClick={() => setVariants(4)}>*/}
                {/*                    <img src={PaperPlus} alt=""/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="add-create-question-variants__item">*/}
                {/*            <p className="add-create-question-variants__title">Variant D</p>*/}
                {/*            <div className="question-variant-chooes-container">*/}
                {/*                <div className="question-variant-chooes">*/}
                {/*                    <div className="question-variant-box">*/}
                {/*                        <input type="text" className="question-input"*/}
                {/*                               onChange={(e) => setDecsTitle4(e.target.value)}/>*/}
                {/*                        /!*Movarounnahr*!/*/}
                {/*                        /!*<span> − "oq kiyimlilar" qo‘zg‘oloni − Bobo-Go‘klan boshchiligida</span>*!/*/}
                {/*                    </div>*/}
                {/*                    <input type="radio" className="question-variant-box__input"/>*/}
                {/*                </div>*/}
                {/*                <div className={`question-variant-add-box ${variants > 3 && "d-none"}`}*/}
                {/*                     onClick={() => setVariants(4)}>*/}
                {/*                    <img src={PaperPlus} alt=""/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
        </>
    );
};

export default Page6;

import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
// import Logo from "../images/Logo1.svg";
import "./assets/SignUpPage2.css";
import { toast } from "react-toastify";
import { API_URL } from "../../api/api";

export default function SignUp2() {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  //   API_URL
  const submitHandlerSpeaker = useCallback(() => {
    if (code === "") {
      toast.error("Tasdiqlash kodini kiriting!");
    } else {
      setLoading(true);
      let form = new FormData();
      form.append("phone_number", phone);
      form.append("code", code);
      axios
        .post(`${API_URL}signup`, form)
        .then((res) => {
          if (res.data.success === true) {
            setLoading(false);
            history.replace("/registration/full_reg");
            localStorage.setItem("code", res?.data.data.code);
          } else if (res.data.success === false) {
            setLoading(false);
            toast.error("Tasdiqlash kodi notog'ri!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            // setError(translate("TITTLE_CODE_VERIFY_ERROR"));
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error("Tasdiqlash kodi notog'ri!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }, [history, phone, code]);

  useEffect(() => setPhone(window.localStorage.getItem("phoneSpeaker")), []);

  return (
    <>
      <section className="sectionSignUpPage2">
        <div className="container">
          <div className="mainSignUpPage2">
            <div className="LoginPage-inner">
              {/* <div className="login-courses_sec_btn ">
                <img
                  src={Logo}
                  alt=""
                  onClick={() => history.replace("/login")}
                />
              </div> */}
            </div>
            <div className="signUpPage-input-item2">
              <input
                className="signUpPage-inner-item2"
                placeholder="Kodni kiriting"
                type="text"
                name="code"
                onChange={(e) => setCode(e.target.value)}
              />
              <button
                className="signUpPage-btn-inner2"
                type="submit"
                onClick={submitHandlerSpeaker}
              >
                {loading ? (
                  <div className="block-loading">
                    <p className="">Iltimos kuting...</p>
                  </div>
                ) : (
                  <span>Davom etish</span>
                )}
              </button>
              <p className="signUpPage-text-item2">
                Akkauntingiz bormi? unda <Link to={"/login"}>bu yerga</Link>{" "}
                bosing
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

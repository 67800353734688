import * as t from './mainTypes';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
  user: cookies.get('user') ? cookies.get('user') : null,
  token: cookies.get('access_token') ? cookies.get('access_token') : null,
  refreshToken: cookies.get('refreshToken') ? cookies.get('refreshToken') : null,
  editVideo: cookies.get('editVideo') ? cookies.get('editVideo') : null,
  selectData: null,
};

const main = (state = initialState, action) => {
  switch (action.type) {
    case t.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case t.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case t.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    case t.EDIT_VIDEO:
      return {
        ...state,
        editVideo: action.payload,
      };
    case t.SELECT_DATA:
      return {
        ...state,
        selectData: action.payload,
      };
    default:
      return { ...state };
  }
};

export default main;

import React from "react";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import "../eduoncourses/eduoncourses.css";


export default function SkeletonLoading(props) {
    return (
        <SkeletonTheme highlightColor="#C6D4E1">
            <div className={`dropdown-menu-video ${props.classname}`}>
                <div className="img-div-course-div"><Skeleton height={40}/></div>
            </div>
        </SkeletonTheme>
    )
}
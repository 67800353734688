import React, {useCallback, useState} from 'react';
import "../../login/ParolUnut_3.css"
import {useHistory} from "react-router-dom";
import Logo from "../../images/Logo1.svg";
import {toast} from "react-toastify";
import axios from "axios";
import Cookies from "universal-cookie";
import Loader from "../../ui/Loader";
import View from "../../images/View.svg"
import Hide from "../../images/Hide.svg";


export default function ParolUnut_3() {
    const [parol, setParol] = useState("1");
    const [veiw, setView] = useState(true);
    const cookies = new Cookies();
    const [parol2, setParol2] = useState("2");
    const [type, setType] = useState(true);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const submitHandler = useCallback(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append("new_password", parol);
        formData.append("confirm_password", parol2);
        axios.post("https://backend.eduon.uz/change-password", formData, {
            headers: {
                'Accept': 'application/json',
                Authorization: "Bearer " + cookies.get("token"),
                "Content-Type": "application/json"
            }
        }).then((res) => {
            if (res.data.success === true) {
                setLoading(false);
                history.replace("/login");
                cookies.remove("token", {path: "/"});
            } else {
                setLoading(false);
                toast.error("Iltimos, parolni qaytadan kiriting", {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }).catch(() => {
            setLoading(false);
            toast.error("Iltimos, parolni qaytadan kiriting", {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }, [history, parol, parol2, cookies]);
    return (
        <div className="ParolUnut_3_block _main">
            <div className='container'>
                <div className="forget-password-wrapper">
                    <div className="LoginPage-inner">
                        <div className="login-courses_sec_btn ">
                            <img src={Logo} alt="" onClick={() => history.replace("/login")}/>
                        </div>
                    </div>
                    <div className="ParolUnut_3">
                        <p>Yangi parolni kiriting</p>
                        <div className="ParolUnut_1_block_input">
                            <input type={`${type ? "password" : "text"}`} name="password"
                                   onChange={(e) => setParol(e.target.value)}/>

                        </div>
                        <label>
                                    <span className="loginPage-inner-icon-for"
                                          onClick={() => setType(!type)}>
                                        <img src={`${type ? Hide : View}`} alt=""/>
                                    </span>
                        </label>
                        <p>Parolni takror kiriting</p>
                        <div className="ParolUnut_1_block_input">
                            <input  type={`${type ? "password" : "text"}`} name="password1"
                                   onChange={(e) => setParol2(e.target.value)}/>
                        </div>
                        <label>
                                    <span className="loginPage-inner-icon-for"
                                          onClick={() => setType(!type)}>
                                        <img src={`${type ? Hide : View}`} alt=""/>
                                    </span>
                        </label>
                        <h5 onClick={() => setView(prevState => !prevState)}
                            style={{marginTop: "10px"}}>{veiw ? "Parolni ko'rsatish" : "Parolni berkitish"}</h5>
                        <div className='ParolUnut_1_tasdiq'>
                            <p onClick={() => history.replace("/reset-password/verify")}>orqaga</p>
                            <button type="submit"
                                    onClick={() => (parol !== parol2 ? toast.error("Parolni tekshirib koring", {
                                        position: toast.POSITION.TOP_RIGHT
                                    }) : submitHandler())}>{loading ?
                                <span className="loader-login"><Loader/></span> : "Tasdiqlash"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

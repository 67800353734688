import { Box, TextField } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import './Modal.css';

function ModalWindow({ open, handleClose, currentItem, setCurrentModuleToEdit, getCourseModule }) {
	const cookies = new Cookies();
	const handleChange = e => {
		setCurrentModuleToEdit({ ...currentItem, title: e.target.value });
	};

	const handleSubmit = async () => {
		const newCurrentItem = {
			title: currentItem?.title,
		};
		const editUrl = `https://inpuls-backend.backoffice.uz/api-web/coursemodule/${currentItem?.id}/`;
		const headers = {
			method: 'PATCH',

			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
			body: JSON.stringify(newCurrentItem),
		};
		try {
			const response = await fetch(editUrl, headers);
			const data = await response.json();

			if (!!data) {
				getCourseModule(true);
				handleClose();
				toast.success('Module ismi muffaqqiyatli taxrirlandi');
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Module ni taxrirlang</DialogTitle>
				<DialogContent>
					<Box
						style={{
							width: '100%',
							minHeight: '130px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
						}}
					>
						<TextField
							label='Module title'
							variant='outlined'
							value={currentItem?.title}
							onChange={handleChange}
						/>
						<div
							style={{
								with: '100%',
								display: 'flex',
								justifyContent: 'center',
								gap: '0.8rem',
							}}
						>
							<Button
								sx={{ backgroundColor: '#e6e6e6', color: 'black' }}
								onClick={handleClose}
								variant='contained'
							>
								bekor qilish
							</Button>
							{`   `}
							<Button
								sx={{ backgroundColor: '#006afe' }}
								onClick={handleSubmit}
								variant='contained'
							>
								Topshirish
							</Button>
						</div>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default ModalWindow;

import React, { useEffect, useState } from 'react';
import { api } from '../../../api/api';
import plus from '../../images/plus.svg';
import Page2 from '../page2';
import Photo1 from '../page2/components/photo1';
import Photo2 from '../page2/components/photo2';
import Photo3 from '../page2/components/photo3';
import Photo4 from '../page2/components/photo4';
import Page6 from '../page6';
import Page7 from '../page7';
import Page8 from '../page8';
import './page1.css';

const Page1 = () => {
	const [page, setPage] = useState(1);
	const [quizType, setQuizType] = useState(0);
	const [data, setData] = useState([]);
	const [editData, setEditData] = useState([]);

	useEffect(() => {
		api.get(`quizzes/question/create`, {
			params: {
				q: window.location.pathname.split('/').at(-1),
			},
		}).then(res => {
			setData(res);
		});
	}, []);
	return (
		<>
			{page === 1 && (
				<div className='question'>
					{data.length > 0 ? (
						<div className='btn-question'>
							<button onClick={() => setPage(2)}>Savol yuklash</button>
						</div>
					) : (
						<div className='question-container' style={{ marginTop: '100px' }}>
							<div className='question-add' onClick={() => setPage(2)}>
								<img src={plus} alt='' />
							</div>
							<em className='question-container__text'>Drag widgets here</em>
						</div>
					)}
					{/*<em className="question-container__text">Drag widgets here</em>*/}
					<Page7 data={data} setPage={setPage} setEditData={setEditData} />
				</div>
			)}
			{page === 2 && <Page2 setQuizType={setQuizType} setPage={setPage} page={page} />}
			{page === 3 &&
				(quizType === 1 ? (
					<Photo1 setPage={setPage} quizType={quizType} />
				) : quizType === 2 ? (
					<Photo2 setPage={setPage} quizType={quizType} />
				) : quizType === 3 ? (
					<Photo3 setPage={setPage} quizType={quizType} />
				) : quizType === 4 ? (
					<Photo4 setPage={setPage} quizType={quizType} />
				) : null)}
			{page === 4 && <Page6 setQuizType={setQuizType} setPage={setPage} />}
			{page === 5 && <Page8 data={editData} setPage={setPage} />}
		</>
	);
};

export default Page1;

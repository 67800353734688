import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { Circle, Line } from 'rc-progress';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Area,
	AreaChart,
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { API_VERSION, api } from '../../api/api';
import Folder from '../../images/Folder.svg';
import Ticket from '../../images/Ticket.svg';
import Fourth from '../../images/fourth.svg';
import testIcon from '../../images/test-icon.png';
import Users from '../../images/users.svg';
import { numFormatter } from '../../utils/functions/num-formatter';
import './statistics.css';

const countries = require('../../json/location-stats1.json');
const cities = require('../../json/location-stats2.json');
const data = require('../../json/data.json');

export default function Statistics() {
	const history = useHistory();
	const [countryShow, setCountryShow] = useState('davlat');
	const [dataa, setDataa] = useState([]);
	const [soldOptions, setSoldOptions] = useState('hafta');
	const [counties, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [rank, setRank] = useState([]);
	const [auditory, setAuditory] = useState([]);
	const [rankOption, setRankOption] = useState('Umumiy');
	const [ageShow, setAgeShow] = useState('');
	const [genderShow, setGenderShow] = useState('');
	const [courseSoldStatistics, setCourseSoldStatistics] = useState([]);
	const [jinsPercent, setJinsPercent] = useState(2);

	const circleContainerStyle = {
		width: '158px',
		height: '158px',
	};

	// filter sold statistics
	let newSoldArrAfterFilterMonth = [
		{ day: '1', Summa: 0 },
		{ day: '2', Summa: 0 },
		{ day: '3', Summa: 0 },
		{ day: '4', Summa: 0 },
		{ day: '5', Summa: 0 },
		{ day: '6', Summa: 0 },
		{ day: '7', Summa: 0 },
		{ day: '8', Summa: 0 },
		{ day: '9', Summa: 0 },
		{ day: '10', Summa: 0 },
		{ day: '11', Summa: 0 },
		{ day: '12', Summa: 0 },
		{ day: '13', Summa: 0 },
		{ day: '14', Summa: 0 },
		{ day: '15', Summa: 0 },
		{ day: '16', Summa: 0 },
		{ day: '17', Summa: 0 },
		{ day: '18', Summa: 0 },
		{ day: '19', Summa: 0 },
		{ day: '20', Summa: 0 },
		{ day: '21', Summa: 0 },
		{ day: '22', Summa: 0 },
		{ day: '23', Summa: 0 },
		{ day: '24', Summa: 0 },
		{ day: '25', Summa: 0 },
		{ day: '26', Summa: 0 },
		{ day: '27', Summa: 0 },
		{ day: '28', Summa: 0 },
		{ day: '29', Summa: 0 },
		{ day: '30', Summa: 0 },
		{ day: '31', Summa: 0 },
	];
	let newSoldArrAfterFilterWeek = [
		{ day: 'dushanba', Summa: 0 },
		{ day: 'seshanba', Summa: 0 },
		{ day: 'chorshanba', Summa: 0 },
		{ day: 'payshanba', Summa: 0 },
		{ day: 'juma', Summa: 0 },
		{ day: 'shanba', Summa: 0 },
		{ day: 'yakshaba', Summa: 0 },
	];
	let newSoldArrAfterFilterYear = [
		{ day: 'ya', Summa: 0 },
		{ day: 'fe', Summa: 0 },
		{ day: 'ma', Summa: 0 },
		{ day: 'ap', Summa: 0 },
		{ day: 'ma', Summa: 0 },
		{ day: 'in', Summa: 0 },
		{ day: 'il', Summa: 0 },
		{ day: 'av', Summa: 0 },
		{ day: 'se', Summa: 0 },
		{ day: 'ok', Summa: 0 },
		{ day: 'no', Summa: 0 },
		{ day: 'de', Summa: 0 },
	];

	courseSoldStatistics?.map(x => {
		if (soldOptions === 'hafta') {
			if (x.day === 2) {
				newSoldArrAfterFilterWeek[0].Summa = x.sells;
			}
			if (x.day === 3) {
				newSoldArrAfterFilterWeek[1].Summa = x.sells;
			}
			if (x.day === 4) {
				newSoldArrAfterFilterWeek[2].Summa = x.sells;
			}
			if (x.day === 5) {
				newSoldArrAfterFilterWeek[3].Summa = x.sells;
			}
			if (x.day === 6) {
				newSoldArrAfterFilterWeek[4].Summa = x.sells;
			}
			if (x.day === 7) {
				newSoldArrAfterFilterWeek[5].Summa = x.sells;
			}
			if (x.day === 'yakshanba') {
				newSoldArrAfterFilterWeek[6].Summa = x.sells;
			}
		}
		if (soldOptions === 'yil') {
			if (x.month === 1) {
				newSoldArrAfterFilterYear[0].Summa = x.sells;
			}
			if (x.month === 2) {
				newSoldArrAfterFilterYear[1].Summa = x.sells;
			}
			if (x.month === 3) {
				newSoldArrAfterFilterYear[2].Summa = x.sells;
			}
			if (x.month === 4) {
				newSoldArrAfterFilterYear[3].Summa = x.sells;
			}
			if (x.month === 5) {
				newSoldArrAfterFilterYear[4].Summa = x.sells;
			}
			if (x.month === 6) {
				newSoldArrAfterFilterYear[5].Summa = x.sells;
			}
			if (x.month === 7) {
				newSoldArrAfterFilterYear[6].Summa = x.sells;
			}
			if (x.month === 8) {
				newSoldArrAfterFilterYear[7].Summa = x.sells;
			}
			if (x.month === 9) {
				newSoldArrAfterFilterYear[8].Summa = x.sells;
			}
			if (x.month === 10) {
				newSoldArrAfterFilterYear[9].Summa = x.sells;
			}
			if (x.month === 11) {
				newSoldArrAfterFilterYear[10].Summa = x.sells;
			}
			if (x.month === 12) {
				newSoldArrAfterFilterYear[11].Summa = x.sells;
			}
		}
		if (soldOptions === 'oy') {
			if (x.day === 1) {
				newSoldArrAfterFilterMonth[0].Summa = x.sells;
			}
			if (x.day === 2) {
				newSoldArrAfterFilterMonth[1].Summa = x.sells;
			}
			if (x.day === 3) {
				newSoldArrAfterFilterMonth[2].Summa = x.sells;
			}
			if (x.day === 4) {
				newSoldArrAfterFilterMonth[3].Summa = x.sells;
			}
			if (x.day === 5) {
				newSoldArrAfterFilterMonth[4].Summa = x.sells;
			}
			if (x.day === 6) {
				newSoldArrAfterFilterMonth[5].Summa = x.sells;
			}
			if (x.day === 7) {
				newSoldArrAfterFilterMonth[6].Summa = x.sells;
			}
			if (x.day === 8) {
				newSoldArrAfterFilterMonth[7].Summa = x.sells;
			}
			if (x.day === 9) {
				newSoldArrAfterFilterMonth[8].Summa = x.sells;
			}
			if (x.day === 10) {
				newSoldArrAfterFilterMonth[9].Summa = x.sells;
			}
			if (x.day === 11) {
				newSoldArrAfterFilterMonth[10].Summa = x.sells;
			}
			if (x.day === 12) {
				newSoldArrAfterFilterMonth[11].Summa = x.sells;
			}
			if (x.day === 13) {
				newSoldArrAfterFilterMonth[12].Summa = x.sells;
			}
			if (x.day === 14) {
				newSoldArrAfterFilterMonth[13].Summa = x.sells;
			}
			if (x.day === 15) {
				newSoldArrAfterFilterMonth[14].Summa = x.sells;
			}
			if (x.day === 16) {
				newSoldArrAfterFilterMonth[15].Summa = x.sells;
			}
			if (x.day === 17) {
				newSoldArrAfterFilterMonth[16].Summa = x.sells;
			}
			if (x.day === 18) {
				newSoldArrAfterFilterMonth[17].Summa = x.sells;
			}
			if (x.day === 19) {
				newSoldArrAfterFilterMonth[18].Summa = x.sells;
			}
			if (x.day === 20) {
				newSoldArrAfterFilterMonth[19].Summa = x.sells;
			}
			if (x.day === 21) {
				newSoldArrAfterFilterMonth[20].Summa = x.sells;
			}
			if (x.day === 22) {
				newSoldArrAfterFilterMonth[21].Summa = x.sells;
			}
			if (x.day === 23) {
				newSoldArrAfterFilterMonth[22].Summa = x.sells;
			}
			if (x.day === 24) {
				newSoldArrAfterFilterMonth[23].Summa = x.sells;
			}
			if (x.day === 25) {
				newSoldArrAfterFilterMonth[24].Summa = x.sells;
			}
			if (x.day === 26) {
				newSoldArrAfterFilterMonth[25].Summa = x.sells;
			}
			if (x.day === 27) {
				newSoldArrAfterFilterMonth[26].Summa = x.sells;
			}
			if (x.day === 28) {
				newSoldArrAfterFilterMonth[27].Summa = x.sells;
			}
			if (x.day === 29) {
				newSoldArrAfterFilterMonth[28].Summa = x.sells;
			}
			if (x.day === 30) {
				newSoldArrAfterFilterMonth[29].Summa = x.sells;
			}
			if (x.day === 31) {
				newSoldArrAfterFilterMonth[30].Summa = x.sells;
			}
		}
	});

	const newDataArrAfterFilterAuditory = [
		{
			name: 'yanvar',
			content: 0,
			auditory: 0,
			amt: 2400,
		},
		{
			name: 'fevral',
			content: 0,
			auditory: 0,
			amt: 2210,
		},
		{
			name: 'mart',
			content: 0,
			auditory: 0,
			amt: 2290,
		},
		{
			name: 'aprel',
			content: 0,
			auditory: 0,
			amt: 2000,
		},
		{
			name: 'may',
			content: 0,
			auditory: 0,
			amt: 2181,
		},
		{
			name: 'iyun',
			content: 0,
			auditory: 0,
			amt: 2120,
		},
		{
			name: 'iyul',
			content: 0,
			auditory: 0,
			amt: 2500,
		},
		{
			name: 'avgust',
			content: 0,
			auditory: 0,
			amt: 2100,
		},
		{
			name: 'sentabr',
			content: 0,
			auditory: 0,
			amt: 2100,
		},
		{
			name: 'oktabr',
			content: 0,
			auditory: 0,
			amt: 2100,
		},
		{
			name: 'noyabr',
			content: 0,
			auditory: 0,
			amt: 2100,
		},
		{
			name: 'dekabr',
			content: 0,
			auditory: 0,
			amt: 2100,
		},
	];

	auditory?.content?.map(x => {
		if (x.month === 1) {
			newDataArrAfterFilterAuditory[0].content = x.content;
		}
		if (x.month === 2) {
			newDataArrAfterFilterAuditory[1].content = x.content;
		}
		if (x.month === 3) {
			newDataArrAfterFilterAuditory[2].content = x.content;
		}
		if (x.month === 4) {
			newDataArrAfterFilterAuditory[3].content = x.content;
		}
		if (x.month === 5) {
			newDataArrAfterFilterAuditory[4].content = x.content;
		}
		if (x.month === 6) {
			newDataArrAfterFilterAuditory[5].content = x.content;
		}
		if (x.month === 7) {
			newDataArrAfterFilterAuditory[6].content = x.content;
		}
		if (x.month === 8) {
			newDataArrAfterFilterAuditory[7].content = x.content;
		}
		if (x.month === 9) {
			newDataArrAfterFilterAuditory[8].content = x.content;
		}
		if (x.month === 10) {
			newDataArrAfterFilterAuditory[9].content = x.content;
		}
		if (x.month === 11) {
			newDataArrAfterFilterAuditory[10].content = x.content;
		}
		if (x.month === 12) {
			newDataArrAfterFilterAuditory[11].content = x.content;
		}
	});

	auditory?.auditory?.map(x => {
		if (x.month === 1) {
			newDataArrAfterFilterAuditory[0].auditory = x.auditory;
		}
		if (x.month === 2) {
			newDataArrAfterFilterAuditory[1].auditory = x.auditory;
		}
		if (x.month === 3) {
			newDataArrAfterFilterAuditory[2].auditory = x.auditory;
		}
		if (x.month === 4) {
			newDataArrAfterFilterAuditory[3].auditory = x.auditory;
		}
		if (x.month === 5) {
			newDataArrAfterFilterAuditory[4].auditory = x.auditory;
		}
		if (x.month === 6) {
			newDataArrAfterFilterAuditory[5].auditory = x.auditory;
		}
		if (x.month === 7) {
			newDataArrAfterFilterAuditory[6].auditory = x.auditory;
		}
		if (x.month === 8) {
			newDataArrAfterFilterAuditory[7].auditory = x.auditory;
		}
		if (x.month === 9) {
			newDataArrAfterFilterAuditory[8].auditory = x.auditory;
		}
		if (x.month === 10) {
			newDataArrAfterFilterAuditory[9].auditory = x.auditory;
		}
		if (x.month === 11) {
			newDataArrAfterFilterAuditory[10].auditory = x.auditory;
		}
		if (x.month === 12) {
			newDataArrAfterFilterAuditory[11].auditory = x.auditory;
		}
	});

	// age of students
	const ages1_17 = dataa?.u1_17;
	const ages18_23 = dataa?.u18_23;
	const ages24_29 = dataa?.u24_29;
	const ages30_35 = dataa?.u30_35;
	const ages36_45 = dataa?.u36_45;
	const ages46 = dataa?.u46p;
	const agesUndefined =
		100 -
		dataa?.u1_17 -
		dataa?.u18_23 -
		dataa?.u24_29 -
		dataa?.u30_35 -
		dataa?.u36_45 -
		dataa?.u46p;
	let countriesArray = [];
	const countriesFilter = counties && Object?.entries(counties);
	counties &&
		countriesFilter.forEach(([key, value]) => {
			countriesArray.push({ country: key, percent: value });
		});

	let citiesArray = [];
	const citiesFilter = cities && Object.entries(cities);
	cities &&
		citiesFilter.forEach(([key, value]) => {
			citiesArray.push({ cities: key, percent: value });
		});

	useEffect(() => {
		let mounted = true;
		api.get(`${API_VERSION}get-statistics/`).then(res => {
			if (mounted) {
				setDataa(res.data);
			}
		});
	}, []);

	useEffect(() => {
		let mounted = true;
		api.get(`${API_VERSION}get-sell-course-statistics/?query=${soldOptions}`).then(res => {
			if (mounted) {
				soldOptions === 'hafta'
					? setCourseSoldStatistics(res?.data?.weekly_statistics)
					: soldOptions === 'oy'
					? setCourseSoldStatistics(res?.data?.monthly_statistics)
					: setCourseSoldStatistics(res.data.yearly_statistics);
			}
		});
		return () => {
			mounted = false;
		};
	}, [soldOptions]);

	useEffect(() => {
		let mounted = true;
		api.get(`${API_VERSION}get-user-country-statics/`).then(res => {
			if (mounted) {
				setCountries(res.data?.country_statistic);
			}
		});
		api.get(`${API_VERSION}get-user-region-statistics/`).then(res => {
			if (mounted) {
				setCities(res.data?.region_statistic);
			}
		});

		api.get(`${API_VERSION}content-and-auditory/`).then(res => {
			if (mounted) {
				setAuditory(res);
			}
		});
	}, []);

	useEffect(() => {
		let mounted = true;
		api.get(`${API_VERSION}get-rank-statistics/`, {
			params: {
				query: rankOption,
			},
		}).then(res => {
			if (mounted) {
				setRank(res);
			}
		});
		return () => {
			mounted = false;
		};
	}, [rankOption]);

	const [percent, setPercent] = useState(9);

	function changeState(age) {
		let agePercent =
			age === '1'
				? Math.round(ages1_17)
				: age === '8'
				? Math.round(ages18_23)
				: age === '24'
				? Math.round(ages24_29)
				: age === '30'
				? Math.round(ages30_35)
				: age === '36'
				? Math.round(ages36_45)
				: age === '46'
				? Math.round(ages46)
				: age
				? Math.round(agesUndefined)
				: 0;

		setPercent(agePercent);
		setAgeShow(age);
	}

	return (
		<React.Fragment>
			<div className='stats-main-container'>
				<div className='course-stats-in-numbers'>
					<div className='courses-info-div'>
						<div className='left-side-of-courses-stats--info-div'>
							<p>
								{dataa?.sell_count
									? numFormatter(dataa?.sell_count)
									: 0}
							</p>
							<span>Sotilgan kurslar</span>
						</div>
						<div className='right-side-of-courses-stats--info-div'>
							<div className='right-side-of-courses-stats--info-div-first'>
								<img src={Ticket} alt='' />
							</div>
						</div>
					</div>
					<div className='courses-info-div info-div'>
						<div className='left-side-of-courses-stats--info-div'>
							<p>
								{dataa?.sell_count
									? numFormatter(dataa?.sell_count)
									: 0}
							</p>
							<span>Ko'rilgan bepul kurslar</span>
						</div>
						<div className='right-side-of-courses-stats--info-div'>
							<div className='right-side-of-courses-stats--info-div-second-zero'>
								<RemoveRedEyeIcon color='secondary' />
							</div>
						</div>
					</div>
					<div className='courses-info-div info-div'>
						<div className='left-side-of-courses-stats--info-div'>
							<p>
								{dataa?.user_count
									? numFormatter(dataa?.user_count)
									: 0}
							</p>
							<span>O’quvchilar soni</span>
						</div>
						<div className='right-side-of-courses-stats--info-div'>
							<div className='right-side-of-courses-stats--info-div-second'>
								<img src={Users} alt='' />
							</div>
						</div>
					</div>
					<div className='courses-info-div info-div'>
						<div className='left-side-of-courses-stats--info-div'>
							<p>
								{dataa?.course_count
									? numFormatter(dataa?.course_count)
									: 0}
							</p>
							<span>Kurslar soni</span>
						</div>
						<div className='right-side-of-courses-stats--info-div'>
							<div className='right-side-of-courses-stats--info-div-third'>
								<img src={Folder} alt='' />
							</div>
						</div>
					</div>
					<div className='courses-info-div info-div bigger-info-div'>
						<div className='left-side-of-courses-stats--info-div'>
							<p>{dataa?.cash ? numFormatter(dataa?.cash) : 0}</p>
							<span>Balans</span>
						</div>
						<div className='right-side-of-courses-stats--info-div right-side-of-info-div-fourth-divest'>
							<div className='right-side-of-courses-stats--info-div-fourth'>
								<img src={Fourth} alt='' />
							</div>
						</div>
					</div>
					<div
						className='courses-info-div info-div bigger-info-div'
						onClick={() => {
							history.push('/main/test/');
							window.location.reload();
						}}
					>
						<div className='left-side-of-courses-stats--info-div'>
							<p>{dataa?.cash ? numFormatter(dataa?.cash) : 0}</p>
							<span>Test natijalari</span>
						</div>
						<div className='right-side-of-courses-stats--info-div right-side-of-info-div-fourth-divest'>
							<div className='right-side-of-courses-stats--info-div-fourth last-5'>
								<img src={testIcon} alt='' />
							</div>
						</div>
					</div>
				</div>
				<div className='course-teaching-auditory'>
					<div className='main-container-auditory'>
						<div className='auditory-div'>
							<span>Kontent va auditoriya</span>
							<ul>
								<li className='li_1'>Auditoriya</li>
								<li className='li_2'>Kontent</li>
							</ul>
						</div>
						<div className='auditory-chart'>
							<ResponsiveContainer width='95%' height='90%'>
								<BarChart
									width={750}
									height={280}
									data={newDataArrAfterFilterAuditory}
									stackOffset='sign'
									margin={{
										top: 20,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray='2 2' />
									<XAxis dataKey='name' />
									<YAxis />
									<Tooltip />
									<Bar
										dataKey='content'
										stackId='a'
										fill='#E6E9F5'
										barSize={17}
									/>
									<Bar
										dataKey='auditory'
										stackId='a'
										fill='#006AFF'
										barSize={17}
									/>
								</BarChart>
							</ResponsiveContainer>
						</div>

						{/*</ResponsiveContainer>*/}
					</div>
				</div>

				<div className='course-stats-in-diagrams'>
					<div className='right-sidebar-auditory'>
						<p>O’quvchilar yoshi</p>
						<div className='stat__2_2'>
							<div
								style={circleContainerStyle}
								className='stat_2_2_inside-div'
							>
								<Circle
									percent={percent}
									strokeWidth={6}
									strokeLinecap='round'
									strokeColor={'rgb(0, 106, 255)'}
								/>
								{/* <Circle
                  percent={[
                    ages1_17,
                    ages18_23,
                    ages24_29,
                    ages30_35,
                    ages36_45,
                    ages46,
                    agesUndefined,
                  ]}
                  gapDegree={0}
                  gapPosition="top"
                  strokeWidth={14}
                  trailWidth={14}
                  transition="0.6s"
                  strokeLinecap="round"
                  strokeColor={[
                    "#006AFF",
                    "#F2C94C",
                    "#2D9CDB",
                    "#6FCF97",
                    "#F2994A",
                    "#a83295",
                    "#E6E9F2",
                  ]}
                /> */}
								<div className='indicator-age'>
									<div>{percent}%</div>
								</div>
							</div>
							<ul className='ul-binnasa-div'>
								<li
									// style={{ border: "1px solid red", cursor: "pointer" }}
									className={`li_1 ${
										ageShow === '1' && 'active'
									}`}
									onClick={() => changeState('1')}
								>
									1-17
								</li>
								<li
									className={`li_2 ${
										ageShow === '8' && 'active'
									}`}
									onClick={() => changeState('8')}
								>
									18-23
								</li>
								<li
									className={`li_3 ${
										ageShow === '24' && 'active'
									}`}
									onClick={() => changeState('24')}
								>
									24-29
								</li>
								<li
									className={`li_4 ${
										ageShow === '30' && 'active'
									}`}
									onClick={() => changeState('30')}
								>
									30-35
								</li>
								<li
									className={`li_5 ${
										ageShow === '36' && 'active'
									}`}
									onClick={() => changeState('36')}
								>
									36-45
								</li>
								<li
									className={`li_6 ${
										ageShow === '46' && 'active'
									}`}
									onClick={() => changeState('46')}
								>
									46+
								</li>
								<li
									className={`li_7 ${
										ageShow === 'un' && 'active'
									}`}
									onClick={() => changeState('un')}
								>
									Undefined
								</li>
							</ul>
						</div>
					</div>
					{/* <div className="courses-left-diagram">
            <div className="courses-left-with-span-and-select-div">
              <span>Ko`rsatkich</span>
              <select
                name=""
                id=""
                onChange={(e) => setRankOption(e.target.value)}
              >
                <option value="Umumiy" selected={rankOption === "Umumiy"}>
                  Umumiy
                </option>
                <option value="Spiker">Speaker</option>
                <option value="Video">Video</option>
                <option value="Kontent">Kontent</option>
                <option value="Kurs">Kurs</option>
              </select>
            </div>
            <div className="rating-mui-div">
              <div>
                <Rating name="size-medium_1" value={1} />
                <span className="rating-mui-div-inside-span">
                  {rank.cnt_1} ta
                </span>
              </div>
              <div>
                <Rating name="size-medium_1" value={2} />
                <span className="rating-mui-div-inside-span">
                  {rank.cnt_2} ta
                </span>
              </div>
              <div>
                <Rating name="size-medium_1" value={3} />
                <span className="rating-mui-div-inside-span">
                  {rank.cnt_3} ta
                </span>
              </div>
              <div>
                <Rating name="size-medium_1" value={4} />
                <span className="rating-mui-div-inside-span">
                  {rank.cnt_4} ta
                </span>
              </div>
              <div>
                <Rating name="size-medium_1" value={5} />
                <span className="rating-mui-div-inside-span">
                  {rank.cnt_5} ta
                </span>
              </div>
              <div className="last-child-of-mui-rating-div">
                <span className="rating-span-totally-score-title">
                  O’rtacha
                </span>
                <span className="rating-span-totally-score">
                  {rank.total?.toFixed(1)}
                </span>
              </div>
            </div>
          </div> */}
					<div className='courses-middle-diagram'>
						<div className='courses-middle-diagram-selection'>
							<select name='' id='' className='first-child-select'>
								<option value='Sotilgan kurslar'>
									Sotilgan kurslar
								</option>
							</select>
							<select
								name=''
								id=''
								className='second-child-select'
								onChange={e => setSoldOptions(e.target.value)}
							>
								<option value='hafta'>hafta</option>
								<option value='oy'>oy</option>
								<option value='yil'>yil</option>
							</select>
						</div>
						<ResponsiveContainer width='100%' height='80%'>
							<AreaChart
								width={400}
								height={130}
								data={
									soldOptions === 'hafta'
										? newSoldArrAfterFilterWeek
										: soldOptions === 'oy'
										? newSoldArrAfterFilterMonth
										: newSoldArrAfterFilterYear
								}
								margin={{
									top: 10,
									right: 15,
									left: 15,
									bottom: 0,
								}}
							>
								<defs>
									<linearGradient
										id='colorUv'
										x1='0'
										y1='0'
										x2='0'
										y2='1'
									>
										<stop
											offset='5%'
											stopColor='#006AFF'
											stopOpacity={0.3}
										/>
										<stop
											offset='95%'
											stopColor='#006AFF'
											stopOpacity={0}
										/>
									</linearGradient>
								</defs>
								<XAxis dataKey='day' axisLine={false} />
								<YAxis axisLine={true} />
								<Tooltip />
								<Area
									type='monotone'
									dataKey='Summa'
									stroke='#006AFF'
									fillOpacity={1}
									fill='url(#colorUv)'
								/>
							</AreaChart>
						</ResponsiveContainer>
					</div>
					<div className='courses-right-diagram'>
						<p>O’quvchilar jinsi</p>
						<div
							className='progress-bar-svg'
							style={{ position: 'relative' }}
						>
							<Circle
								percent={jinsPercent}
								strokeWidth={6}
								strokeLinecap='round'
								strokeColor='#006AFF'
							/>
							<div className='' style={{ position: 'absolute' }}>
								<div
									style={{
										fontSize: '30px',
										fontWeight: 600,
									}}
								>
									{jinsPercent}%
								</div>
							</div>
							{/* <ProgressBar
                className="progress-bar"
                radius={100}
                progress={progress}
                strokeWidth={30}
                strokeColor="#006AFF"
                strokeLinecap="round"
                trackStrokeWidth={30}
              >
                <div className="indicator">
                  <div>
                    {genderShow === "female"
                      ? progressFemale
                      : genderShow
                      ? progress
                      : 0}
                    %
                  </div>
                </div>
              </ProgressBar> */}
						</div>
						<ul className='ul-gender'>
							<li
								className='gender-man'
								onClick={() =>
									setJinsPercent(Math.round(dataa?.yigitlar))
								}
							>
								Yigitlar
							</li>
							<li
								className='gender-women'
								onClick={() =>
									setJinsPercent(Math.round(dataa?.qizlar))
								}
							>
								Qizlar
							</li>
						</ul>
					</div>
				</div>
				<div className='course-student-locations'>
					<div className='course-student-locations-div'>
						<div className='course-student-locations-div-selection'>
							<div className='left-side-locations-div-selection'>
								Joylashuv
							</div>
							<div className='right-side-locations-div-selection'>
								<select
									name=''
									id='selection-of-locations'
									onChange={e => setCountryShow(e.target.value)}
								>
									<option value='davlat'>Davlat</option>
									<option value='shahar'>Shahar</option>
								</select>
							</div>
						</div>
						{countryShow === 'davlat' ? (
							<div className='course-location-div-countries'>
								{countriesArray.map(country => {
									return (
										<React.Fragment>
											<div key={country}>
												<div className='spanation-div'>
													<span className='spanation-div-first'>
														{
															country.country
														}
													</span>
													<span className='spanation-div-second'>
														{Math.round(
															country.percent
														)}
														%
													</span>
												</div>
												<div>
													<Line
														percent={
															country.percent
														}
														strokeWidth='5'
														trailWidth='5'
														strokeColor='#006AFF'
													></Line>
												</div>
											</div>
										</React.Fragment>
									);
								})}
							</div>
						) : (
							<div className='course-location-div-cities'>
								{citiesArray.map(city => {
									return (
										<React.Fragment>
											<div key={city}>
												<div className='spanation-div'>
													<span className='spanation-div-first'>
														{city.cities}
													</span>
													<span className='spanation-div-second'>
														{Math.round(
															city.percent
														)}
														%
													</span>
												</div>
												<div>
													<Line
														percent={
															city.percent
														}
														strokeWidth='5'
														trailWidth='5'
														strokeColor='#006AFF'
													></Line>
												</div>
											</div>
										</React.Fragment>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

import React from "react";
import {routes} from "./components/route/route";
import {Route, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import MainContainer from "./containers/MainContainer";
import 'react-toastify/dist/ReactToastify.css';

const Main = () => {
    const token = useSelector(state => state.main.token);

    return (
        <>
            <MainContainer isAuthed={token}>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        );
                    })}
                </Switch>
            </MainContainer>
            <ToastContainer
            position={"top-right"}
            />
        </>
    )
}
export default Main
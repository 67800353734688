import React from 'react';
import NavBar from '../Navbar';
import plusIcon from '../../images//plus.svg';
import './page5.css';
const Page5 = () => {
  return (
    <>
      <div className="add-variants">
        <div className="add-variants-wrapper">
          <img
            src={'https://static.abt.uz/crop/600__5c57f46387bc8.png'}
            alt=""
            className="add-variants-img"
          />
          <div className="add-variants-text">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className="add-variants-text__input"
              placeholder="Type your question ..."
            />
            <div className="add-variants-btn">
              <img src={plusIcon} alt="" />
            </div>
            <em>Add variants</em>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page5;

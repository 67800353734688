import React, { useEffect, useState } from 'react';
import { api } from '../../api/api';
import './MyGroups.css';
import SelectComponent from './components/Select';
import GroupsTable from './components/table/Table';

const MyGroups = () => {
	const [course, setCourse] = useState(1);
	const [group, setGroups] = useState(1);
	const [courseOptions, setCourseOptions] = useState([]);
	const [groupOptions, setgroupOptions] = useState([]);
	const [students, setStudents] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);

				const resCourses = await api.get(`https://inpuls-backend.backoffice.uz/quizzes/filter_for_course/?course_id=${course}`);
				const resGroups = await api.get(`https://inpuls-backend.backoffice.uz/quizzes/filter_for_course/?course_id=${course}&group_id=${group}`);

				setCourseOptions(resCourses?.course);
				setgroupOptions(resGroups?.group);
				setStudents(resGroups?.student);
				setLoading(false);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [course, group]);

	return (
		<div className='groups-page'>
			<div className='groups-page-filters'>
				<SelectComponent course={course} setCourse={setCourse} label={'Kurslar'} options={courseOptions?.map(o => ({ label: o?.name, value: o?.id }))} />
				{groupOptions.length !== 0 && (
					<SelectComponent
						course={group}
						setCourse={setGroups}
						label={'Guruhlar'}
						disabled={groupOptions?.length === 0}
						options={groupOptions?.map(o => ({ label: o?.name, value: o?.id }))}
					/>
				)}
			</div>

			<GroupsTable students={students} loading={loading} />
		</div>
	);
};

export default MyGroups;

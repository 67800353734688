import React, { Component } from "react";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
class Second extends Component {
  state = {};
  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default Second;
